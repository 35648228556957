import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { InfestacoesComponent } from './infestacoes/infestacoes.component';

const routes: Routes = [
  {path: '', component: InfestacoesComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes), 
    NgxDatatableModule
  ],
  exports: [
    RouterModule
  ]
})

export class InfestacoesRoutingModule { }