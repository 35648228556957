import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TopTrinta } from 'src/app/model/top-trinta';
import { ToptrintaStoreService } from 'src/app/store/top-trinta/top-trinta-store.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-top-trinta',
  templateUrl: './top-trinta.component.html',
  styleUrls: ['./top-trinta.component.scss']
})

export class TopTrintaComponent implements OnInit {

  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  reorderable: boolean = true;
  error$: Observable<any>;
  rows$: Observable<TopTrinta[]>;
  maxRows: number = 50;
  uploadForm: FormGroup;
  total$: Observable<number>;

  private api: string = `${environment.api}/topcliente/importacao`;

  constructor(
    private topTrintaStoreService: ToptrintaStoreService,
    private formBuilder: FormBuilder, 
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.isLoading$ = this.topTrintaStoreService.getIsLoading();
    this.dispatchLoadTopTrintaAction();
    this.rows$ = this.topTrintaStoreService.getTopTrintaList();
    this.total$ = this.topTrintaStoreService.getTotal();
    this.uploadForm = this.formBuilder.group({
      topTrinta: ['']
    });
  }

  private dispatchLoadTopTrintaAction(){
    this.topTrintaStoreService.dispatchListToptrintaAction(this.page, this.maxRows,this.buscaPor);
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('topTrinta').setValue(file);
    }

    const formData = new FormData();
    formData.append('file', this.uploadForm.get('topTrinta').value);

    this.httpClient.post<any>(this.api, formData).subscribe(
      (res) => swal('', res.message, 'info'),
      (err) => swal('', err.message, 'error')
    );
  }

  onChangePage(pageInfo) {
    this.page = pageInfo.offset;
    this.dispatchLoadTopTrintaAction();
  }

  onChangeMax(event){
    this.maxRows = parseInt(event.target.value);
    this.dispatchLoadTopTrintaAction();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.buscaPor = val;
    this.page = 0;
    this.dispatchLoadTopTrintaAction();
  }

  onClickDelete(topTrinta) {
    this.topTrintaStoreService.dispatchDeleteTopTrintaAction(topTrinta);
  }
}