import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_NAME } from './produtos.state';
import { produtoReducer } from './produtos.reducer';
import { ProdutoEffects } from './produtos.effects';
import { ProdutoStoreService } from './produtos-store.service';
import { ProdutosService } from './produtos.service';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature(STATE_NAME, produtoReducer),
        EffectsModule.forFeature([ProdutoEffects]),
    ],
    exports: [
        StoreModule,
        EffectsModule,
        HttpClientModule
    ],
    providers: [
        ProdutoStoreService,
        ProdutosService
    ]
})
export class ProdutosStoreModule { }