import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, filter, debounce, debounceTime, take, switchMap } from 'rxjs/operators';
import { of, empty } from 'rxjs';

import * as toptrintaActions from './top-trinta.actions';
import { ToptrintaService } from './top-trinta.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertObservable } from 'src/app/shared/SweetAlertObservable';

@Injectable()
export class ToptrintaEffects {

    constructor(
        private actions$: Actions,
        private toptrintaService: ToptrintaService,
        private sweetAlertObservable: SweetAlertObservable
    ) { }

    @Effect()
    loadToptrintaAction$ = this.actions$.pipe(
        ofType<toptrintaActions.ListToptrintaAction>(toptrintaActions.ToptrintaActions.LIST_TOPTRINTA),
        map(action => action.payload),
        debounceTime(800),
        mergeMap(payload => {
            return this.toptrintaService.list(payload)
            .pipe(
                map((response: any) => {
                    const {elements, total} = response;
                    return new toptrintaActions.ListToptrintaSuccessAction({
                        total : total * payload.maxRows,
                        topTrintaList : elements
                    });
                }),
                catchError((err: any) => {
                    return of(new toptrintaActions.ListToptrintaErrorAction(err.error.message));
                })
            );
        })
    );

    @Effect()
    deleteTopTrintaAction$ = this.actions$.pipe(
        ofType<toptrintaActions.DeleteToptrintaAction>(toptrintaActions.ToptrintaActions.DELETE_TOPTRINTA),
        map(action => action.payload),
        mergeMap(topTrinta => {
            const preConfirm = () => {
                return this.toptrintaService.delete(topTrinta.id).pipe(
                    take(1)
                ).toPromise();
            }
            return this.sweetAlertObservable.confirm({
                title: 'Atenção!',
                text: 'Você deseja mesmo deletar o usuário ' + topTrinta.nome + ' ?',
                preConfirm: preConfirm
            }).pipe(
                mergeMap(_ => {
                    return this.sweetAlertObservable.success({
                        type: 'success',
                        title: 'Sucesso!',
                        text: `Top Trinta ${topTrinta.nome} deletado com sucesso`,
                    });
                }),
                catchError((err: HttpErrorResponse) => {
                    if(err.status === 400) {
                        return this.sweetAlertObservable.error({
                            type: 'error',
                            title: 'Erro!',
                            text: err.error.message,
                        });
                    }
                    return empty();
                }),
                switchMap(_ => {
                    return [
                       new toptrintaActions.DeleteToptrintaSuccessAction(),
                       new toptrintaActions.ListToptrintaAction({
                           page: 0,
                           maxRows: 10,
                           buscaPor: ''
                       })
                    ]
                }),
            )
        })
    );
}