import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

export interface ProdutoInterface{
  id: number;
  link: string;
  nome: string;
  tipo: {
      id: number,
      nome: string
  };
}

@Injectable()
export class ProdutosService {

  private api: string = `${environment.api}/produtos`;

  constructor(
    private http: HttpClient
  ) { }

  list(payload:{
    page: number,
    maxRows: number,
    buscaPor: string
  }) {
    return this.http.get(`${this.api}/filter?page=${payload.page}&max=${payload.maxRows}&search=${payload.buscaPor}`);
  }

  get(id: number) {
    return this.http.get(`${this.api}/${id}`);
  }

  save(body: ProdutoInterface) {
     return this.http.post(`${this.api}`, {
      id: body.id,
      link: body.link,
      nome: body.nome,
     });
  }

  update(body: ProdutoInterface) {
    return this.http.put(`${this.api}/${body.id}`, {
     id: body.id,
     link: body.link,
     nome: body.nome,
    });
 }

  delete(id: number) {
    return this.http.delete(`${this.api}/${id}`);
  }

}