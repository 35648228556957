import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, filter, debounceTime, take, switchMap } from 'rxjs/operators';
import { of, empty } from 'rxjs';

import * as revendaActions from './revenda.actions';
import { RevendaService } from './revenda.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertObservable } from 'src/app/shared/SweetAlertObservable';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable()
export class RevendaEffects {

    constructor(
        private actions$: Actions,
        private router: Router,
        private revendaService: RevendaService,
        private sweetAlertObservable: SweetAlertObservable
    ) { }

    @Effect()
    listRevendaAction$ = this.actions$.pipe(
        ofType<revendaActions.ListRevendaAction>(revendaActions.RevendaActions.LIST_REVENDA),
        map(action => action.payload),
        debounceTime(800),
        mergeMap(payload => {
            return this.revendaService.list(payload)
            .pipe(
                map((response: any) => {
                    const {elements, total} = response;
                    return new revendaActions.ListRevendaSuccessAction({
                        total : total * payload.maxRows,
                        revendas : elements
                    });
                }),
                catchError((err: any) => {
                    return of(new revendaActions.ListRevendaErrorAction(err.error.message));
                })
            );
        })
    );

    @Effect()
    loadRevendaAction$ = this.actions$.pipe(
        ofType<revendaActions.LoadRevendaAction>(revendaActions.RevendaActions.LOAD_REVENDA),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.revendaService.get(payload)
                .pipe(
                    map((response: any) => response),
                    map(revenda =>{
                        return new revendaActions.LoadRevendaSuccessAction(revenda);
                    }),
                    catchError(err => {
                        return of(new revendaActions.LoadRevendaErrorAction(err.error.message));
                    })
                );
        })
    );

    @Effect()
    saveAction$ = this.actions$.pipe(
        ofType<revendaActions.SaveRevendaAction>(revendaActions.RevendaActions.SAVE_REVENDA),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.revendaService.save(payload).pipe(
                map( _ => {
                    return new revendaActions.SaveRevendaSuccessAction();
                }),
                catchError(err =>{
                    return of(new revendaActions.SaveRevendaErrorAction(err.error.message));
                })
            )
        })
    );

    @Effect()
    saveSuccessAction$ = this.actions$.pipe(
        ofType<revendaActions.SaveRevendaAction>(revendaActions.RevendaActions.SAVE_REVENDA_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ =>{
                this.router.navigate(['/revendas']);
            });
            return empty();
        })
    );

    @Effect()
    updateAction$ = this.actions$.pipe(
        ofType<revendaActions.UpdateRevendaAction>(revendaActions.RevendaActions.UPDATE_REVENDA),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.revendaService.update(payload).pipe(
                map( _ => {
                    return new revendaActions.UpdateRevendaSuccessAction();
                }),
                catchError(err =>{
                    return of(new revendaActions.UpdateRevendaErrorAction);
                })
            )
        })
    );

    @Effect()
    updateSuccessAction$ = this.actions$.pipe(
        ofType<revendaActions.UpdateRevendaAction>(revendaActions.RevendaActions.UPDATE_REVENDA_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ =>{
                this.router.navigate(['/revendas']);
            });
            return empty();
        })
    );

    @Effect()
    deleteRevendaAction$ = this.actions$.pipe(
        ofType<revendaActions.DeleteRevendaAction>(revendaActions.RevendaActions.DELETE_REVENDA),
        map(action => action.payload),
        mergeMap(revenda => {
            const preConfirm = () => {
                return this.revendaService.delete(revenda.id).pipe(
                    take(1)
                ).toPromise();
            }
            return this.sweetAlertObservable.confirm({
                title: 'Atenção!',
                text: 'Você deseja mesmo deletar a revenda ' + revenda.nome + ' ?',
                preConfirm: preConfirm
            }).pipe(
                mergeMap(_ => {
                    return this.sweetAlertObservable.success({
                        type: 'success',
                        title: 'Sucesso!',
                        text: `Revenda ${revenda.nome} deletado com sucesso`,
                    });
                }),
                catchError((err: HttpErrorResponse) => {
                    if(err.status === 400) {
                        return this.sweetAlertObservable.error({
                            type: 'error',
                            title: 'Erro!',
                            text: err.error.message,
                        });
                    }
                    return empty();
                }),
                switchMap(_ => {
                    return [
                       new revendaActions.DeleteRevendaSuccessAction(),
                       new revendaActions.ListRevendaAction({
                           page: 0,
                           maxRows: 10,
                           buscaPor: ''
                       })
                    ]
                }),
            )
        })
    );
}