import { Injectable } from "@angular/core";

import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../app.reducer';
import * as actions from './importacoes.actions';
import * as state from './importacoes.state';

@Injectable()
export class ImportacoesStoreService {

  constructor(private store: Store<AppState>) { }

  private importacoesState = createFeatureSelector<state.ImportacoesState>(state.STATE_NAME);
  private isLoading = createSelector(this.importacoesState, state.selectIsLoading);
  private importacoes = createSelector(this.importacoesState, state.selectListImportacoes);
  private total = createSelector(this.importacoesState, state.selectTotal);


  getIsLoading() {
    return this.store.select(this.isLoading);
  }

  getImportacoes() {
    return this.store.select(this.importacoes);
  }

  getTotal() {
    return this.store.select(this.total);
  }

  dispatchListImportacoesAction(page: number, maxRows: number, buscaPor: string) {
    this.store.dispatch(new actions.ListImportacoesAction({
      maxRows: maxRows,
      page: page,
      buscaPor: buscaPor
    }));
  }

}