import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Cidade } from 'src/app/model/cidade';
import { ActivatedRoute } from '@angular/router';
import { UsuarioStoreService } from 'src/app/store/usuario/usuario-store.service';
import { filter } from 'rxjs/operators';
import swal from 'sweetalert2';
import { CidadeStoreService } from 'src/app/store/cidade/cidade-store.service';
import { Perfil } from 'src/app/model/perfil';

declare var $: any;

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  form: FormGroup;
  isLoading$: Observable<boolean>;
  id = this.route.snapshot.params['id'];
  cidades$: Observable<Cidade[]>;
  maxRows: number = 10000;
  page: number = 0;
  buscaPor: string = '';
  perfis$: Observable<Perfil[]>;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private usuarioStoreService: UsuarioStoreService,
    private cidadeStoreService: CidadeStoreService
  ) { }

  ngOnInit() {
    this.isLoading$ = this.usuarioStoreService.getIsLoading();
    this.loadCidades();
    this.loadPerfis();

    this.form = this.fb.group({
      id: [null],
      nome: [''],
      username: [''],
      cpf: [''],
      cidade: [''],
      telefone: [''],
      perfis: [''],
      ondeCompraProdutos: [''],
      password: [''],
      perfil: [''],
      revenda: this.fb.group({
        bairro: [''],
        cep: [''],
        complemento: [''],
        endereco: [''],
        id: [''],
        latitude: [''],
        longitude: [''],
        nome: [''],
        numero: [''],
        proprietarioConta: [''],
        razaoSocial: ['']
      })
    });

    $('#form-usuario').validate();

    this.usuarioStoreService.getError()
      .pipe(
        filter(err => err.show && err.message != ''),
      ).subscribe(err => swal('Opss...', err.message, 'error'));

    if (this.id) {
      $(document).ready(function () {
        $("#password").prop('required', false);
        $("#campoSenha").css("display", "none");
      });
      this.usuarioStoreService.dispatchLoadUsuarioAction(this.id);
      this.usuarioStoreService.getUsuario()
        .pipe(
          filter(usuario => usuario !== null),
        )
        .subscribe((usuario) => {
          this.form.patchValue(usuario);
        });
    } else {
      $(document).ready(function () {
        $("#password").prop('required', true);
        $("#campoSenha").css("display", "block");
      });
    }
  }

  onClickSave() {
    if (this.form.valid) {  
      if(this.id == null)    
        this.usuarioStoreService.dispatchSaveAction(this.form.value)
      else
        this.usuarioStoreService.dispatchUpdateAction(this.form.value)
    }
  }

  loadCidades() {
    this.cidadeStoreService.dispatchListCidadeAction(this.page, this.maxRows, this.buscaPor);
    this.cidades$ = this.cidadeStoreService.getCidades();
  }

  loadPerfis(){
    this.usuarioStoreService.dispatchListPerfisAction(this.page, this.maxRows, this.buscaPor);
    this.perfis$ = this.usuarioStoreService.getPerfis();
  }
}
