import * as revendaState from './revenda.state';
import * as actions from './revenda.actions';

export function revendaReducer(
    state: revendaState.RevendaState = revendaState.initialState,
    action: actions.TypesActions
): revendaState.RevendaState {

    switch (action.type) {
        case actions.RevendaActions.LIST_REVENDA:
            return {
                ...state,
                isLoading: true,
                list: {
                    ...state.list,
                    revendas: []
                },
                error: revendaState.initialState.error
            }
        case actions.RevendaActions.LIST_REVENDA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    revendas: action.payload.revendas,
                    total: action.payload.total
                }
            }
        case actions.RevendaActions.LIST_REVENDA_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload,
                },
                list: {
                    ...state.list,
                    total: 0
                }
            }
        case actions.RevendaActions.LOAD_REVENDA:
            return {
                ...state,
                isLoading: true,
                revenda: null
            }
        case actions.RevendaActions.LOAD_REVENDA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                revenda: action.payload
            }
        case actions.RevendaActions.LOAD_REVENDA_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.RevendaActions.SAVE_REVENDA:
            return {
                ...state,
                isLoading: true,
                error: revendaState.initialState.error
            }
        case actions.RevendaActions.SAVE_REVENDA_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case actions.RevendaActions.SAVE_REVENDA_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.RevendaActions.UPDATE_REVENDA:
            return {
                ...state,
                isLoading: true,
                error: revendaState.initialState.error
            }
        case actions.RevendaActions.UPDATE_REVENDA_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case actions.RevendaActions.UPDATE_REVENDA_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.RevendaActions.DELETE_REVENDA:
            return {
                ...state,
                isLoading: false,
                revenda: null
            }

        default:
            return state;
    }

}