import { NgRxAction } from '../NgRxAction';

export enum RevendaActions {
    LIST_REVENDA = '[REVENDA] - List REVENDA - ',
    LIST_REVENDA_SUCCESS = '[REVENDA] - List REVENDA Success - ',
    LIST_REVENDA_ERROR = '[REVENDA] - Error List REVENDA - ',
    LOAD_REVENDA = '[REVENDA] - load REVENDA - ',
    LOAD_REVENDA_SUCCESS = '[REVENDA] - load REVENDA Success - ',
    LOAD_REVENDA_ERROR = '[REVENDA] - Error load REVENDA - ',
    SAVE_REVENDA = '[REVENDA] - Save REVENDA - ',
    SAVE_REVENDA_SUCCESS = '[REVENDA] - Save REVENDA Success - ',
    SAVE_REVENDA_ERROR = '[REVENDA] - Save List REVENDA - ',
    UPDATE_REVENDA = '[REVENDA] - Update REVENDA - ',
    UPDATE_REVENDA_SUCCESS = '[REVENDA] - Update REVENDA Success - ',
    UPDATE_REVENDA_ERROR = '[REVENDA] - Update List REVENDA - ',
    DELETE_REVENDA = '[REVENDA] - Delete REVENDA - ',
    DELETE_REVENDA_SUCCESS = '[REVENDA] - Delete REVENDA Success - ',
}
export class ListRevendaAction extends NgRxAction<any> {
    readonly type = RevendaActions.LIST_REVENDA;
}

export class ListRevendaSuccessAction extends NgRxAction<any> {
    readonly type = RevendaActions.LIST_REVENDA_SUCCESS;
}

export class ListRevendaErrorAction extends NgRxAction<any> {
    readonly type = RevendaActions.LIST_REVENDA_ERROR;
}

export class LoadRevendaAction extends NgRxAction<any>{
    readonly type = RevendaActions.LOAD_REVENDA;
}

export class LoadRevendaSuccessAction extends NgRxAction<any>{
    readonly type = RevendaActions.LOAD_REVENDA_SUCCESS;
}

export class LoadRevendaErrorAction extends NgRxAction<any>{
    readonly type = RevendaActions.LOAD_REVENDA_ERROR;
}

export class SaveRevendaAction extends NgRxAction<any>{
    readonly type = RevendaActions.SAVE_REVENDA;
}

export class SaveRevendaSuccessAction extends NgRxAction<any>{
    readonly type = RevendaActions.SAVE_REVENDA_SUCCESS;
}
export class SaveRevendaErrorAction extends NgRxAction<any>{
    readonly type = RevendaActions.SAVE_REVENDA_ERROR;
}
export class UpdateRevendaAction extends NgRxAction<any>{
    readonly type = RevendaActions.UPDATE_REVENDA;
}

export class UpdateRevendaSuccessAction extends NgRxAction<any>{
    readonly type = RevendaActions.UPDATE_REVENDA_SUCCESS;
}
export class UpdateRevendaErrorAction extends NgRxAction<any>{
    readonly type = RevendaActions.UPDATE_REVENDA_ERROR;
}

export class DeleteRevendaAction extends NgRxAction<any>{
    readonly type = RevendaActions.DELETE_REVENDA;
}

export class DeleteRevendaSuccessAction extends NgRxAction<any>{
    readonly type = RevendaActions.DELETE_REVENDA_SUCCESS;
}


export type TypesActions =
    ListRevendaAction
    | ListRevendaSuccessAction
    | ListRevendaErrorAction
    | LoadRevendaAction
    | LoadRevendaSuccessAction
    | LoadRevendaErrorAction
    | SaveRevendaAction
    | SaveRevendaSuccessAction
    | SaveRevendaErrorAction
    | UpdateRevendaAction
    | UpdateRevendaSuccessAction
    | UpdateRevendaErrorAction
    | DeleteRevendaAction
    | DeleteRevendaSuccessAction
