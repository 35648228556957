import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_NAME } from './cidade.state';
import { cidadeReducer } from './cidade.reducer';
import { CidadeEffects } from './cidade.effects';
import { CidadeStoreService } from './cidade-store.service';
import { CidadeService } from './cidade.service';

@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(STATE_NAME, cidadeReducer),
      EffectsModule.forFeature([CidadeEffects]),
  ],
  exports: [
      StoreModule,
      EffectsModule,
      HttpClientModule
  ],
  providers: [
      CidadeStoreService,
      CidadeService
  ]
})
export class CidadeStoreModule { }