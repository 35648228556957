import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Revendas } from 'src/app/model/revendas';
import { RevendaStoreService } from 'src/app/store/revenda/revenda-store.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';

@Component({
  selector: 'app-revendas',
  templateUrl: './revendas.component.html',
  styleUrls: ['./revendas.component.scss']
})
export class RevendasComponent implements OnInit {
  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  error$: Observable<any>;
  rows$: Observable<Revendas[]>;
  maxRows: number = 50;
  uploadForm: FormGroup;
  total$: Observable<number>; 

  private api: string = `${environment.api}/revendas/importacao`;

  columns = [
    { prop: 'nomeRevenda', name: 'Razão Social' },
    { prop: 'proprietarioDaConta', name: 'Proprietário da Conta' },
    { prop: 'usuario', name: 'Nome' },
    { prop: 'detalhes', name: 'Detalhes' }
  ];

  constructor(
    private revendaStoreService: RevendaStoreService,
    private formBuilder: FormBuilder, 
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.isLoading$ = this.revendaStoreService.getIsLoading();
    this.dispatchLoadRevendaAction();
    this.rows$ = this.revendaStoreService.getRevendas();
    this.total$ = this.revendaStoreService.getTotal();

    this.uploadForm = this.formBuilder.group({
      revendas: ['']
    });
  }

  dispatchLoadRevendaAction(){
    this.revendaStoreService.dispatchListRevendaAction(this.page, this.maxRows, this.buscaPor);
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('revendas').setValue(file);
    }

    const formData = new FormData();
    formData.append('file', this.uploadForm.get('revendas').value);

    this.httpClient.post<any>(this.api, formData).subscribe(
      (res) => swal('', res.message, 'info'),
      (err) => swal('', err.message, 'error')
    );
  }

  onChangePage(pageInfo) {
    this.page = pageInfo.offset;
    this.dispatchLoadRevendaAction();
  }

  onChangeMax(event){
    this.maxRows = parseInt(event.target.value);
    this.dispatchLoadRevendaAction();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.buscaPor = val;
    this.page = 0;
    this.dispatchLoadRevendaAction();
  }

  onClickDelete(revenda) {
    this.revendaStoreService.dispatchDeleteRevendaAction(revenda);
  }

  montaLinkGoogleMaps(valor: any): string {
    var latitude = valor.latitude;
    var longitude = valor.longitude;
    var link = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
 
    return link;
  } 
}