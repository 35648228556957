import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RedefinirSenhaRoutingModule } from './redefinirSenha-routing.module';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { SharedModule } from 'src/app/shared/shared.module';
import { RedefinirSenhaComponent } from './redefinirSenha.component';
import { AdminStoreModule } from 'src/app/store/admin/admin-store.module';

@NgModule({
  declarations: [RedefinirSenhaComponent],
  imports: [
    SweetAlert2Module,
    CommonModule,
    SharedModule,
    RedefinirSenhaRoutingModule,
    AdminStoreModule
  ],
  providers: [],
  bootstrap: [RedefinirSenhaComponent]
})
export class RedefinirSenhaModule { }
