import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlterarDadosRoutingModule } from './alterar-dados-routing.module';
import { AlterarDadosComponent } from './alterar-dados.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AdminStoreModule } from 'src/app/store/admin/admin-store.module';

@NgModule({
  declarations: [AlterarDadosComponent],
  imports: [
    AlterarDadosRoutingModule,
    SweetAlert2Module,
    CommonModule,
    SharedModule,
    AdminStoreModule
  ],
  providers: [],
  bootstrap: [ AlterarDadosComponent ]
})

export class AlterarDadosModule {}
