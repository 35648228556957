import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AuthService {

  private api: string = `${environment.api}/login`;
  USER_INFO_KEY = 'USER_INFO_KEY';

  constructor(
    private http: HttpClient
  ) { }

  cachedRequests: Array<HttpRequest<any>> = [];

  public collectFailedRequest(request) : void{
    this.cachedRequests.push(request);
  }

  getToken(): string{
    return localStorage.getItem('token');
  }

  getUserInfo(){
    try{   
      return JSON.parse(localStorage.getItem(this.USER_INFO_KEY));
    }catch(err){
      return null;
    }
  }
  
  setUserInfo(userInfo){    
    localStorage.setItem(this.USER_INFO_KEY, JSON.stringify(userInfo));
  }

  login(username: string, password: string) : Observable<Object>{    
    return this.http.post(`${this.api}`,{ username, password }, { observe: 'response' });
  }

  esqueciSenha(email:string){
    return this.http.post(`${environment.api}/usuarios/senha/esqueci/${email}`, { observe: 'response' });
  }

  redefiniSenha(payload: {senha: string, novaSenha: string, token: string}){
    return this.http.patch(`${environment.api}/usuarios/senha/redefinir`,{
      senha: payload.senha,
      novaSenha: payload.novaSenha,
      token: payload.token
    })
  }
}