import { Component, OnInit } from '@angular/core';
import { Cidade } from 'src/app/model/cidade';
import { Observable } from 'rxjs';
import { CidadeStoreService } from 'src/app/store/cidade/cidade-store.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';

@Component({
  selector: 'app-cidades',
  templateUrl: './cidades.component.html',
  styleUrls: ['./cidades.component.scss']
})
export class CidadesComponent implements OnInit {

  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  reorderable: boolean = true;
  error$: Observable<any>;
  rows$: Observable<Cidade[]>
  maxRows: number = 50;
  total$: Observable<number>;
  uploadForm: FormGroup;

  private api: string = `${environment.api}/cidades/importacao`;

  constructor(
    private cidadeStoreService: CidadeStoreService,
    private formBuilder: FormBuilder, 
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    this.isLoading$ = this.cidadeStoreService.getIsLoading();
    this.dispatchLoadCidadeAction();
    this.rows$ = this.cidadeStoreService.getCidades();
    this.total$ = this.cidadeStoreService.getTotal();

    this.uploadForm = this.formBuilder.group({
      cidades: ['']
    });
  }

  private dispatchLoadCidadeAction(){
    this.cidadeStoreService.dispatchListCidadeAction(this.page, this.maxRows, this.buscaPor);
  }

  onChangeMax(event){
    this.maxRows = parseInt(event.target.value);
    this.dispatchLoadCidadeAction();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.buscaPor = val;
    this.page = 0;
    this.dispatchLoadCidadeAction();
  }

  onChangePage(pageInfo) {
    this.page = pageInfo.offset;
    this.dispatchLoadCidadeAction();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('cidades').setValue(file);
    }

    const formData = new FormData();
    formData.append('file', this.uploadForm.get('cidades').value);

    this.httpClient.post<any>(this.api, formData).subscribe(
      (res) => swal('', res.message, 'info'),
      (err) => swal('', err.message, 'error')
    );
  }
}
