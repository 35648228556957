import { Injectable } from "@angular/core";

import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../app.reducer';
import * as actions from './regioesalvo.actions';
import * as state from './regioesalvo.state';
import { RegioesAlvoInterface } from './regioesalvo.service';
import { RegioesAlvo } from 'src/app/model/regioesAlvos';

@Injectable()
export class RegioesalvoStoreService {

  constructor(private store: Store<AppState>) { }

  private regioesalvoState = createFeatureSelector<state.RegioesalvoState>(state.STATE_NAME);
  private isLoading = createSelector(this.regioesalvoState, state.selectIsLoading);
  private regioesalvo = createSelector(this.regioesalvoState, state.selectRegioesalvo);
  private regioesAlvoList = createSelector(this.regioesalvoState, state.selectListRegioesalvo);
  private error = createSelector(this.regioesalvoState, state.selectError);
  private total = createSelector(this.regioesalvoState, state.selectTotal);


  getIsLoading() {
    return this.store.select(this.isLoading);
  }

  getRegioesalvo() {
    return this.store.select(this.regioesalvo);
  }

  getRegioesAlvoList(){
    return this.store.select(this.regioesAlvoList);
  }

  getError(){
    return this.store.select(this.error);
  }

  getTotal() {
    return this.store.select(this.total);
  }

  dispatchListRegioesalvoAction(page: number, maxRows: number, buscaPor: string) {
    this.store.dispatch(new actions.ListRegioesAlvoAction({
      page: page,
      maxRows: maxRows,
      buscaPor: buscaPor
    }));
  }

  dispatchLoadRegioesalvoAction(id: number){
    this.store.dispatch(new actions.LoadRegioesAlvoAction(id));
  }

  dispatchSaveAction(payload: RegioesAlvoInterface){
    this.store.dispatch(new actions.SaveRegioesAlvoAction(payload));
  }

  dispatchUpdateAction(payload: RegioesAlvoInterface){
    this.store.dispatch(new actions.UpdateRegioesAlvoAction(payload));
  }

  dispatchDeleteRegioesAlvoAction(regioes_alvo: RegioesAlvo){
    this.store.dispatch(new actions.DeleteRegioesAlvoAction(regioes_alvo));
  }

}