import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Cidade } from 'src/app/model/cidade';
import { Revenda } from 'src/app/model/revenda';


export interface UsuarioInterface {
  id: number;
  cidade: Cidade;
  cpf: string;
  latitude: string;
  longitude: string;
  nome: string;
  ondeCompraProdutos: string;
  password: string;
  perfil: {
    codigo: string;
    id: number;
    nome: string;
  }
  username: string;
  revenda: Revenda;
  telefone: string;
}

@Injectable()
export class UsuarioService {

  private api: string = `${environment.api}/usuarios`;

  constructor(
    private http: HttpClient
  ) { }

  list(payload: {
    page: number,
    maxRows: number,
    buscaPor: string
  }) {
    return this.http.get(`${this.api}/filter?page=${payload.page}&max=${payload.maxRows}&search=${payload.buscaPor}`);
  }

  get(id: number) {
    return this.http.get(`${this.api}/${id}`);
  }

  save(body: UsuarioInterface) {
    return this.http.post(`${this.api}`, {
      id: body.id,
      cidade: body.cidade,
      cpf: body.cpf,
      nome: body.nome,
      ondeCompraProdutos: body.ondeCompraProdutos,
      password: body.password,
      perfil: body.perfil,
      username: body.username,
      revenda: body.revenda,
      telefone: body.telefone
    });
  }

  update(body: UsuarioInterface) {
    return this.http.put(`${this.api}/${body.id}`, {
      cidade: body.cidade,
      cpf: body.cpf,
      nome: body.nome,
      ondeCompraProdutos: body.ondeCompraProdutos,
      password: body.password,
      perfil: body.perfil,
      username: body.username,
      revenda: body.revenda,
      telefone: body.telefone
    });
  }

  delete(id: number) {
    return this.http.delete(`${this.api}/${id}`);
  }

  getPerfis(payload: {
    page: number,
    maxRows: number,
    buscaPor: string
  }){
    return this.http.get(`${environment.api}/perfis/filter?page=${payload.page}&max=${payload.maxRows}&search=${payload.buscaPor}`);
  }

}