import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { RevendaStoreService } from 'src/app/store/revenda/revenda-store.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  form: FormGroup;
  isLoading$: Observable<boolean>;
  textbreadcrumbs: string = "Nova Revenda";
  id = this.route.snapshot.params['id'];
  maxRows: number = 10000;
  page: number = 0;
  buscaPor: string = '';

  constructor(
    private revendaStoreService: RevendaStoreService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isLoading$ = this.revendaStoreService.getIsLoading();

    this.form = this.fb.group({
      id: [null],
      nome: ['', Validators.required],
      razaoSocial: ['', Validators.required],
      proprietarioConta: [''],
      latitude: [''],
      longitude: [''],
      bairro: [''],
      cep: [''],
      complemento: [],
      endereco: [''],
      numero: [''],
      municipio: [''],
      uf: [''],
      telefone: ['']
    });

    $('#form-revenda').validate();

    this.revendaStoreService.getError()
      .pipe(
        filter(err => err.show && err.message != ''),
      ).subscribe(err => swal('Opss...', err.message, 'error'));

    if (this.id) {
      $(document).ready(function () {
        $("#password").prop('required', false);
        $("#campoSenha").css("display", "none");
      });
      this.textbreadcrumbs = "Editar Revenda"
      this.revendaStoreService.dispatchLoadRevendaAction(this.id);
      this.revendaStoreService.getRevenda()
        .pipe(
          filter(revenda => revenda !== null),
        )
        .subscribe((revenda) => {
          this.form.patchValue(revenda);
        });
    }
  }

  onClickSave() {
    if (this.form.valid) {
      if (this.id == null)
        this.revendaStoreService.dispatchSaveAction(this.form.value);
      else
        this.revendaStoreService.dispatchUpdateAction(this.form.value)
    }
  }
}
