import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Importacao } from 'src/app/model/importacao';
import { ImportacoesStoreService } from 'src/app/store/importacoes/importacoes-store.service';

@Component({
  selector: 'app-importacoes',
  templateUrl: './importacoes.component.html',
  styleUrls: ['./importacoes.component.scss']
})
export class ImportacoesComponent implements OnInit {

  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  reorderable: boolean = true;
  error$: Observable<any>;
  rows$: Observable<Importacao[]>
  maxRows: number = 50;
  total$: Observable<number>;
  testing: string = '';

  constructor(
    private relatioStoreService: ImportacoesStoreService
  ) {}

  ngOnInit() {
    this.isLoading$ = this.relatioStoreService.getIsLoading();
    this.dispatchLoadImportacoessAction();
    this.rows$ = this.relatioStoreService.getImportacoes();
    this.total$ = this.relatioStoreService.getTotal();  
  }

  onChangeMax(event){
    this.maxRows = parseInt(event.target.value);
    this.dispatchLoadImportacoessAction();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.buscaPor = val;
    this.page = 0;
    this.dispatchLoadImportacoessAction();
  }

  onChangePage(pageInfo) {
    this.page = pageInfo.offset;
    this.dispatchLoadImportacoessAction();
  }

  private dispatchLoadImportacoessAction(){
    this.relatioStoreService.dispatchListImportacoesAction(this.page, this.maxRows, this.buscaPor);
  }

  getLink(value){
    return value;
  }
}
