import { UserInfo } from 'src/app/model/user-info';

export const STATE_NAME = 'auth';

export interface AuthState {
    userInfo: UserInfo;
    isLoading: boolean;
    error: {
        show: boolean,
        message: string
    }

}

export const initialState: AuthState = {
    userInfo: null,
    isLoading: false,
    error: {
        show: false,
        message: null
    }
}

export const selectIsLoading = (state: AuthState) => state.isLoading;
export const selectUserInfo = (state: AuthState) => state.userInfo;
export const selectError = (state: AuthState) => state.error;
