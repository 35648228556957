import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, filter, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';

import * as exportacoesActions from './exportacoes.actions';
import { ExportacoesService } from './exportacoes.service';

@Injectable()
export class ExportacoesEffects {

    constructor(
        private actions$: Actions,
        private exportacoesService: ExportacoesService,
    ) { }

    @Effect()
    loadExportacoesAction$ = this.actions$.pipe(
        ofType<exportacoesActions.ListExportacoesAction>(exportacoesActions.ExportacoesActions.LIST_EXPORTACOES),
        map(action => action.payload),
        debounceTime(800),
        mergeMap(payload => {
            return this.exportacoesService.list(payload).pipe(
                map((response: any) => {
                    const {elements, total} = response;                    
                    return new exportacoesActions.ListExportacoesSuccessAction({
                        total : total * payload.maxRows,
                        exportacoes: elements
                    });
                }),
                catchError((err: any) => {
                    return of(new exportacoesActions.ListExportacoesErrorAction(err.error.message));
                })
            );
        })
    );
}