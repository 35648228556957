import { Component, OnInit } from '@angular/core';
import { TipoalvoStoreService } from 'src/app/store/tipo-alvo/tipo-alvo-store.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  form: FormGroup;
  isLoading$: Observable<boolean>;
  textbreadcrumbs: string = "Novo Tipo de Alvo";
  id = this.route.snapshot.params['id'];
  constructor(
    private tipoAlvoStoreService: TipoalvoStoreService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isLoading$ = this.tipoAlvoStoreService.getIsLoading();

    this.form = this.fb.group({
      id: [null],
      nome: ['', Validators.required]
    });

    $('#form-tipo-alvo').validate();

    this.tipoAlvoStoreService.getError()
      .pipe(
        filter(err => err.show && err.message != '')
      );
    if (this.id) {
      this.textbreadcrumbs = "Editar Tipo de Alvo"
      this.tipoAlvoStoreService.dispatchLoadTipoalvoAction(this.id);
    }
    this.tipoAlvoStoreService.getTipoalvo()
      .pipe(
        filter(tipo_alvo => tipo_alvo !== null),
      )
      .subscribe((tipo_alvo) => {
        this.form.patchValue(tipo_alvo);
      });
  }

  onClickSave(){
    if(this.form.valid && this.id == null){
      this.tipoAlvoStoreService.dispatchSaveAction(this.form.value);
    }else{
      this.tipoAlvoStoreService.dispatchUpdateAction(this.form.value);
    }
  }

}
