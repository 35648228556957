import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseCelularRoutingModule } from './base-celular-routing.module';
import { BaseCelularComponent } from './base-celular/base-celular.component';

@NgModule({
  declarations: [BaseCelularComponent],
  imports: [
    CommonModule,
    BaseCelularRoutingModule
  ],
  bootstrap: [BaseCelularComponent]
})
export class BaseCelularModule { }
