import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';

import { TopTrintaRoutingModule } from './top-trinta-routing.module';
import { TopTrintaComponent } from './top-trinta/top-trinta.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ToptrintaStoreModule } from 'src/app/store/top-trinta/top-trinta-store.module';

@NgModule({
  declarations: [TopTrintaComponent],
  imports: [
    TopTrintaRoutingModule,
    NgxDatatableModule,
    NgSelectModule,
    SharedModule,
    ToptrintaStoreModule
  ],
  providers:[],
  bootstrap: [TopTrintaComponent]
})

export class TopTrintaModule { }