import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TipoAlvo } from 'src/app/model/tipoAlvo';
import { TipoalvoStoreService } from 'src/app/store/tipo-alvo/tipo-alvo-store.service';
import { filter } from 'rxjs/operators';
import swal from 'sweetalert2';

@Component({
  selector: 'app-tipos-alvos',
  templateUrl: './tipos-alvos.component.html',
  styleUrls: ['./tipos-alvos.component.scss']
})

export class TiposAlvosComponent implements OnInit {

  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  reorderable: boolean = true;
  error$: Observable<any>;
  rows$: Observable<TipoAlvo[]>;
  maxRows: number = 10;
  total$: Observable<number>;

  constructor(
    private tipoAlvoStoreService: TipoalvoStoreService
  ) {}

  ngOnInit() {
    this.isLoading$ = this.tipoAlvoStoreService.getIsLoading();
    this.dispatchLoadTipoAlvosAction();
    this.rows$ = this.tipoAlvoStoreService.getTipoAlvoList();
    this.total$ = this.tipoAlvoStoreService.getTotal();

    this.tipoAlvoStoreService.getError()
      .pipe(
        filter(err => err.show && err.message != '')
      ).subscribe(err =>{
        swal('Opss...', 'Este tipo de alvo já existe', 'error');
      });      
  }

  onChangeMax(event){
    this.maxRows = parseInt(event.target.value);
    this.dispatchLoadTipoAlvosAction();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.buscaPor = val;
    this.page = 0;
    this.dispatchLoadTipoAlvosAction();
  }

  dispatchLoadTipoAlvosAction(){
    this.tipoAlvoStoreService.dispatchListTipoalvoAction(this.page, this.maxRows, this.buscaPor);
  }

  onClickDelete(tipo_alvo) {
    this.tipoAlvoStoreService.dispatchDeleteTipoAlvoAction(tipo_alvo);
  }

  onChangePage(pageInfo) {
    this.page = pageInfo.offset;
    this.dispatchLoadTipoAlvosAction();
  }

}