import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Admin } from 'src/app/model/admin';
import { AdminStoreService } from 'src/app/store/admin/admin-store.service';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})

export class AdminsComponent implements OnInit {
  
  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  reorderable: boolean = true;
  error$: Observable<any>;
  rows$: Observable<Admin[]>
  maxRows: number = 50;
  total$: Observable<number>;

  constructor(
    private adminStoreService: AdminStoreService
  ) {}

  ngOnInit() {
    this.isLoading$ = this.adminStoreService.getIsLoading();
    this.dispatchLoadAdminAction();
    this.rows$ = this.adminStoreService.getAdmins();
    this.total$ = this.adminStoreService.getTotal();
  }

  private dispatchLoadAdminAction(){
    this.adminStoreService.dispatchListAdminAction(this.page, this.maxRows, this.buscaPor);
  }

  onClickDelete(admin) {
    this.adminStoreService.dispatchDeleteAdminAction(admin);
  }

  onChangeMax(event){
    this.maxRows = parseInt(event.target.value);
    this.dispatchLoadAdminAction();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.buscaPor = val;
    this.page = 0;
    this.dispatchLoadAdminAction();
  }

  onChangePage(pageInfo) {
    this.page = pageInfo.offset;
    this.dispatchLoadAdminAction();
  }

}