import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';

import { CadastroComponent } from './cadastro/cadastro.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
import { FaqRoutingModule } from './faq-routing.module';
import { FaqComponent } from './faq/faq.component';

@NgModule({
  declarations: [FaqComponent, CadastroComponent],
  imports: [
    NgxDatatableModule,
    FaqRoutingModule,
    NgSelectModule,
    SharedModule,
  ],
  providers: [],
  bootstrap: [FaqComponent]
})

export class FaqModule { }