import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Alvo } from 'src/app/model/alvo';
import { TipoAlvo } from 'src/app/model/tipoAlvo';
import { Produtos } from 'src/app/model/produtos';


export interface InfestacaoInterface{
  id: number;
  alvo: Alvo;
  tipoAlvo: TipoAlvo;
  produtos: Produtos;
  comentario: string;
  latitude: number;
  longitude: number;
  porcentagem: number;
}


@Injectable()
export class InfestacoesService {

  private api: string = `${environment.api}/relatorios`;
  constructor(
    private http: HttpClient
  ) { }



  list(payload: {
    page: number, 
    maxRows: number,
    buscaPor: string
  }) {
    return this.http.get(`${this.api}/filter?page=${payload.page}&max=${payload.maxRows}&search=${payload.buscaPor}`);
  }

  get(id: number) {
    return this.http.get(`${this.api}/${id}`);
  }

  saveOrUpdate(payload) {
    return this.http.post(this.api, payload);
  }

  delete(id: number) {
    return this.http.delete(`${this.api}/${id}`);
  }

}