import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExportacoesComponent } from './exportacoes/exportacoes.component';
import { CadastroComponent } from './cadastro/cadastro.component';

const routes: Routes = [
  {path: '', component: ExportacoesComponent},
  {path: 'exportacoes/:id', component: CadastroComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExportacoesRoutingModule { }
