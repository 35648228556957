import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { AdminStoreService } from 'src/app/store/admin/admin-store.service';
import { AuthStoreService } from 'src/app/store/auth/auth-store.service';
import { filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/store/auth/auth.service';

declare var $: any;

@Component({
  selector: 'app-alterar-dados-senha',
  templateUrl: './alterar-dados.component.html',
  styleUrls: ['./alterar-dados.component.scss']
})
export class AlterarDadosComponent implements OnInit {

  form: FormGroup;
  route: any;

  constructor(
    private fb: FormBuilder,
    private adminStoreService: AdminStoreService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.createForm();
    const userInfo = this.authService.getUserInfo();

    this.adminStoreService.dispatchLoadAdminAction(userInfo.id);
    
    this.adminStoreService.getAdmin()
    .pipe(
      filter(usuario => usuario !== null),
    )
    .subscribe((usuario) => {
      this.form.patchValue(usuario);       
    });
    
  }

  private createForm() {
    this.form = this.fb.group({
      'id': [null],
      'nome': ['',Validators.required],
      'username': ['']
    });

    $('#form-alterarDados').validate({
      submitHandler: () => {
        this.onSubmit();
      }      
    });
  }

  onSubmit(){
    this.adminStoreService.dispatchUpdateAction(this.form.value);    
  }
}