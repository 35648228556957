import { Injectable } from "@angular/core";

import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../app.reducer';
import * as actions from './tipo-alvo.actions';
import * as state from './tipo-alvo.state';
import { TipoAlvoInterface } from './tipo-alvo.service';
import { TipoAlvo } from 'src/app/model/tipoAlvo';

@Injectable()
export class TipoalvoStoreService {

  constructor(private store: Store<AppState>) { }

  private tipoalvoState = createFeatureSelector<state.TipoalvoState>(state.STATE_NAME);
  private isLoading = createSelector(this.tipoalvoState, state.selectIsLoading);
  private tipoalvo = createSelector(this.tipoalvoState, state.selectTipoalvo);
  private tipoAlvoList = createSelector(this.tipoalvoState, state.selectListTipoalvo);
  private error = createSelector(this.tipoalvoState, state.selectError);
  private total = createSelector(this.tipoalvoState, state.selectTotal);


  getIsLoading() {
    return this.store.select(this.isLoading);
  }

  getTipoalvo() {
    return this.store.select(this.tipoalvo);
  }

  getTipoAlvoList(){
    return this.store.select(this.tipoAlvoList);
  }

  getError(){
    return this.store.select(this.error);
  }

  getTotal() {
    return this.store.select(this.total);
  }

  dispatchListTipoalvoAction(page: number, maxRows: number, buscaPor: string) {
    this.store.dispatch(new actions.ListTipoalvoAction({
      page: page,
      maxRows: maxRows,
      buscaPor: buscaPor
    }));
  }

  dispatchLoadTipoalvoAction(id: number){
    this.store.dispatch(new actions.LoadTipoAlvoAction(id));
  }

  dispatchSaveAction(payload: TipoAlvoInterface){
    this.store.dispatch(new actions.SaveTipoAlvoAction(payload));
  }

  dispatchUpdateAction(payload: TipoAlvoInterface){
    this.store.dispatch(new actions.UpdateTipoAlvoAction(payload));
  }

  dispatchDeleteTipoAlvoAction(tipo_alvo: TipoAlvo){
    this.store.dispatch(new actions.DeleteTipoAlvoAction(tipo_alvo));
  }

}