import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

export interface TipoAlvoInterface{
  id: number;
  nome: string;
}

@Injectable()
export class TipoalvoService {

  private api: string = `${environment.api}/alvo/tipos`;

  constructor(
    private http: HttpClient
  ) { }

  list(payload: {
    page: number,
    maxRows: number,
    buscaPor: string
  }) {
    return this.http.get(`${this.api}/filter?page=${payload.page}&max=${payload.maxRows}&search=${payload.buscaPor}`);
  }

  get(id: number) {
    return this.http.get(`${this.api}/${id}`);
  }

  save(body: TipoAlvoInterface) {
    return this.http.post(`${this.api}`, {
      id: body.id,
      nome: body.nome
    });
  }

  update(body: TipoAlvoInterface){
    return this.http.put(`${this.api}/${body.id}`,{
      nome: body.nome
    })
  }

  delete(id: number) {
    return this.http.delete(`${this.api}/${id}`);
  }

}