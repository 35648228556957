import { NgRxAction } from '../NgRxAction';
import { TipoAlvoInterface } from './tipo-alvo.service';

export enum TipoalvoActions {
    LIST_TIPOALVO = '[TIPOALVO] - List TIPOALVO - ',
    LIST_TIPOALVO_SUCCESS = '[TIPOALVO] - List TIPOALVO Success - ',
    LIST_TIPOALVO_ERROR = '[TIPOALVO] - Error List TIPOALVO - ',
    LOAD_TIPOALVO = '[TIPOALVO] - Load Tipo Alvo',
    LOAD_TIPOALVO_SUCCESS = '[TIPOALVO] - Load Tipo Alvo Success',
    LOAD_TIPOALVO_ERROR = '[TIPOALVO] - Load Tipo Alvo Error',
    SAVE_TIPOALVO = '[TIPOALVO] - Save Tipo Alvo',
    SAVE_TIPOALVO_SUCCESS = '[TIPOALVO] - Save Tipo Alvo Success',
    SAVE_TIPOALVO_ERROR = '[TIPOALVO] - Save Tipo Alvo Error',
    UPDATE_TIPOALVO = '[TIPOALVO] - Update Tipo Alvo',
    UPDATE_TIPOALVO_SUCCESS = '[TIPOALVO] - Update Tipo Alvo Success',
    UPDATE_TIPOALVO_ERROR = '[TIPOALVO] - Update Tipo Alvo Error',
    DELETE_TIPOALVO = '[TIPOALVO] Delete Tipo Alvo',
    DELETE_TIPOALVO_ERROR = '[TIPOALVO] Delete Tipo Alvo Error',
    DELETE_TIPOALVO_SUCCESS = '[TIPOALVO] Delete Tipo Alvo Success',
    TIPOALVO_ERROR = '[TIPOALVO] Error Tipo Alvo'
}

export class ListTipoalvoAction extends NgRxAction<any> {
    readonly type = TipoalvoActions.LIST_TIPOALVO;
}

export class ListTipoalvoSuccessAction extends NgRxAction<any> {
    readonly type = TipoalvoActions.LIST_TIPOALVO_SUCCESS;
}

export class ListTipoalvoErrorAction extends NgRxAction<any> {
    readonly type = TipoalvoActions.LIST_TIPOALVO_ERROR;
}

export class LoadTipoAlvoAction extends NgRxAction<any>{
    readonly type = TipoalvoActions.LOAD_TIPOALVO;
}

export class LoadTipoAlvoSuccessAction extends NgRxAction<any>{
    readonly type = TipoalvoActions.LOAD_TIPOALVO_SUCCESS;
}

export class LoadTipoAlvoErrorAction extends NgRxAction<any>{
    readonly type = TipoalvoActions.LOAD_TIPOALVO_ERROR;
}

export class SaveTipoAlvoAction extends NgRxAction<TipoAlvoInterface>{
    readonly type = TipoalvoActions.SAVE_TIPOALVO;
}

export class SaveTipoAlvoSuccessAction extends NgRxAction<any>{
    readonly type = TipoalvoActions.SAVE_TIPOALVO_SUCCESS;
}

export class SaveTipoAlvoErrorAction extends NgRxAction<any>{
    readonly type = TipoalvoActions.SAVE_TIPOALVO_ERROR;
}

export class UpdateTipoAlvoAction extends NgRxAction<TipoAlvoInterface>{
    readonly type = TipoalvoActions.UPDATE_TIPOALVO;
}

export class UpdateTipoAlvoSuccessAction extends NgRxAction<any>{
    readonly type = TipoalvoActions.UPDATE_TIPOALVO_SUCCESS;
}

export class UpdateTipoAlvoErrorAction extends NgRxAction<any>{
    readonly type = TipoalvoActions.UPDATE_TIPOALVO_ERROR;
}

export class ErrorTipoAlvoActions extends NgRxAction<any>{
    readonly type = TipoalvoActions.TIPOALVO_ERROR;
}

export class DeleteTipoAlvoAction extends NgRxAction<any>{
    readonly type = TipoalvoActions.DELETE_TIPOALVO;
}

export class DeleteTipoAlvoSuccessAction extends NgRxAction<any>{
    readonly type = TipoalvoActions.DELETE_TIPOALVO_SUCCESS;
}

export class DeleteTipoAlvoErrorAction extends NgRxAction<any>{
    readonly type = TipoalvoActions.DELETE_TIPOALVO_ERROR;
}


export type TypesActions =
    ListTipoalvoAction
    | ListTipoalvoSuccessAction
    | ListTipoalvoErrorAction
    | LoadTipoAlvoAction
    | LoadTipoAlvoSuccessAction
    | LoadTipoAlvoErrorAction
    | SaveTipoAlvoAction
    | SaveTipoAlvoSuccessAction
    | SaveTipoAlvoErrorAction
    | UpdateTipoAlvoAction
    | UpdateTipoAlvoSuccessAction
    | UpdateTipoAlvoErrorAction
    | ErrorTipoAlvoActions
    | DeleteTipoAlvoAction
    | DeleteTipoAlvoSuccessAction
    | DeleteTipoAlvoErrorAction