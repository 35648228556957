import { Produtos } from 'src/app/model/produtos';

export const STATE_NAME = 'produtos';

export interface ProdutosState {
    isLoading: boolean;
    list: {
        produtos: Produtos[],
        total: number
    };
    produto: Produtos;
    error: {
        show: boolean,
        message: string
    }
}

export const initialState: ProdutosState = {
    isLoading: false,
    list: {
        produtos: [],
        total: 0
    },
    produto: null,
    error: {
        show: false,
        message: null
    }
}

export const selectIsLoading = (state: ProdutosState) => state.isLoading;
export const selectProduto = (state: ProdutosState) => state.produto;
export const selectListProdutos = (state: ProdutosState) => state.list.produtos;
export const selectError = (state: ProdutosState) => state.error;
export const selectTotal = (state: ProdutosState) => state.list.total;