import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';

import { RegioesRoutingModule } from './regioes-alvos-routing.module';
import { RegioesAlvosComponent } from './regioes-alvos/regioes-alvos.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RegioesalvoStoreModule } from 'src/app/store/regioesalvo/regioesalvo-store.module';
import { CadastroComponent } from './cadastro/cadastro.component';

@NgModule({
  declarations: [RegioesAlvosComponent, CadastroComponent],
  imports: [
    RegioesRoutingModule,
    NgxDatatableModule,
    NgSelectModule,
    SharedModule,
    RegioesalvoStoreModule
  ],
  providers:[],
  bootstrap: [RegioesAlvosComponent]
})

export class RegioesAlvosModule { }