import * as usuarioState from './usuario.state';
import * as actions from './usuario.actions';

export function usuarioReducer(
    state: usuarioState.UsuarioState = usuarioState.initialState,
    action: actions.TypesActions
): usuarioState.UsuarioState {

    switch (action.type) {
        case actions.UsuarioActions.LIST_USUARIO:
            return {
                ...state,
                isLoading: true,
                list: {
                    ...state.list,
                    usuarios: []
                },
                error: usuarioState.initialState.error
            }
        case actions.UsuarioActions.LIST_USUARIO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    usuarios: action.payload.usuarios,
                    total: action.payload.total
                }
            }
        case actions.UsuarioActions.LIST_USUARIO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload,
                },
                list: {
                    ...state.list,
                    total: 0
                }
            }
        case actions.UsuarioActions.LOAD_USUARIO:
            return {
                ...state,
                isLoading: true,
                usuario: null
            }
        case actions.UsuarioActions.LOAD_USUARIO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                usuario: action.payload
            }
        case actions.UsuarioActions.LOAD_USUARIO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.UsuarioActions.SAVE_USUARIO:
            return {
                ...state,
                isLoading: true,
                error: usuarioState.initialState.error
            }
        case actions.UsuarioActions.SAVE_USUARIO_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case actions.UsuarioActions.SAVE_USUARIO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.UsuarioActions.UPDATE_USUARIO:
            return {
                ...state,
                isLoading: true,
                error: usuarioState.initialState.error
            }
        case actions.UsuarioActions.UPDATE_USUARIO_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case actions.UsuarioActions.UPDATE_USUARIO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }

        case actions.UsuarioActions.DELETE_USUARIO:
            return {
                ...state,
                isLoading: false,
                usuario: null
            }
        case actions.UsuarioActions.LIST_PERFIS:
            return {
                ...state,
                isLoading: true,
                perfis: []
            }
        case actions.UsuarioActions.LIST_PERFIS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                perfis: action.payload.elements
            }
        case actions.UsuarioActions.LIST_PERFIS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload,
                },
                perfis: []
            }

        default:
            return state;
    }

}