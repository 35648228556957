import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_NAME } from './top-trinta.state';
import { toptrintaReducer } from './top-trinta.reducer';
import { ToptrintaEffects } from './top-trinta.effects';
import { ToptrintaStoreService } from './top-trinta-store.service';
import { ToptrintaService } from './top-trinta.service';

@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(STATE_NAME, toptrintaReducer),
      EffectsModule.forFeature([ToptrintaEffects]),
  ],
  exports: [
      StoreModule,
      EffectsModule,
      HttpClientModule
  ],
  providers: [
      ToptrintaStoreService,
      ToptrintaService
  ]
})
export class ToptrintaStoreModule { }