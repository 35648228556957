import * as exportacoesState from './exportacoes.state';
import * as actions from './exportacoes.actions';

export function exportacoesReducer(
    state: exportacoesState.ExportacoesState = exportacoesState.initialState,
    action: actions.TypesActions
): exportacoesState.ExportacoesState {

    switch (action.type) {
        case actions.ExportacoesActions.LIST_EXPORTACOES:
            return {
                ...state,
                isLoading: true,
                list: {
                    ...state.list,
                    exportacoes: []
                },
                error: exportacoesState.initialState.error
            }
        case actions.ExportacoesActions.LIST_EXPORTACOES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    exportacoes: action.payload.exportacoes,
                    total: action.payload.total
                }
            }
        case actions.ExportacoesActions.LIST_EXPORTACOES_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                },
                list: {
                    ...state.list,
                    total: 0
                }
            }
        default:
            return state;
    }

}