import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgModule } from '@angular/core';

import { RevendasRoutingModule } from './revendas-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RevendasComponent } from './revendas/revendas.component';
import { RevendaStoreModule } from 'src/app/store/revenda/revenda-store.module';
import { CadastroComponent } from './cadastro/cadastro.component';

@NgModule({
  declarations: [RevendasComponent, CadastroComponent],
  imports: [
    RevendasRoutingModule,
    SharedModule,
    NgxDatatableModule,
    RevendaStoreModule
  ],
  providers: [],
  bootstrap: [ RevendasComponent ]
})

export class RevendasModule {}