import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Produtos } from 'src/app/model/produtos';
import { ProdutoStoreService } from 'src/app/store/produtos/produtos-store.service';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss']
})

export class ProdutosComponent implements OnInit {

  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  reorderable: boolean = true;
  error$: Observable<any>;
  rows$: Observable<Produtos[]>
  maxRows: number = 50;
  total$: Observable<number>;

  constructor(
    private produtoStoreService: ProdutoStoreService
  ) {}

  ngOnInit() {
    this.isLoading$ = this.produtoStoreService.getIsLoading();
    this.dispatchLoadProdutosAction();
    this.rows$ = this.produtoStoreService.getProdutoList();
    this.total$ = this.produtoStoreService.getTotal();
  }

  onChangeMax(event){
    this.maxRows = parseInt(event.target.value);
    this.dispatchLoadProdutosAction();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.buscaPor = val;
    this.page = 0;
    this.dispatchLoadProdutosAction();
  }

  private dispatchLoadProdutosAction(){
    this.produtoStoreService.dispatchListProdutoAction(this.page, this.maxRows, this.buscaPor);
  }

  onClickDelete(produto) {
    this.produtoStoreService.dispatchDeleteProdutoAction(produto);
  }

  onChangePage(pageInfo) {
    this.page = pageInfo.offset;
    this.dispatchLoadProdutosAction();
  }

}