import { NgRxAction } from '../NgRxAction';
import { RegioesAlvoInterface } from './regioesalvo.service';

export enum RegioesAlvoActions {
    LIST_REGIOESALVO = '[REGIOESALVO] - List REGIOESALVO - ',
    LIST_REGIOESALVO_SUCCESS = '[REGIOESALVO] - List REGIOESALVO Success - ',
    LIST_REGIOESALVO_ERROR = '[REGIOESALVO] - Error List REGIOESALVO - ',
    LOAD_REGIOESALVO = '[REGIOESALVO] - Load RegioesAlvo',
    LOAD_REGIOESALVO_SUCCESS = '[REGIOESALVO] - Load RegioesAlvo Success',
    LOAD_REGIOESALVO_ERROR = '[REGIOESALVO] - Load RegioesAlvo Error',
    SAVE_REGIOESALVO = '[REGIOESALVO] - Save RegioesAlvo',
    SAVE_REGIOESALVO_SUCCESS = '[REGIOESALVO] - Save RegioesAlvo Success',
    SAVE_REGIOESALVO_ERROR = '[REGIOESALVO] - Save RegioesAlvo Error',
    UPDATE_REGIOESALVO = '[REGIOESALVO] - Update RegioesAlvo',
    UPDATE_REGIOESALVO_SUCCESS = '[REGIOESALVO] - Update RegioesAlvo Success',
    UPDATE_REGIOESALVO_ERROR = '[REGIOESALVO] - Update RegioesAlvo Error',
    DELETE_REGIOESALVO = '[REGIOESALVO] Delete RegioesAlvo',
    DELETE_REGIOESALVO_ERROR = '[REGIOESALVO] Delete RegioesAlvo Error',
    DELETE_REGIOESALVO_SUCCESS = '[REGIOESALVO] Delete RegioesAlvo Success',
    REGIOESALVO_ERROR = '[REGIOESALVO] Error RegioesAlvo'
}

export class ListRegioesAlvoAction extends NgRxAction<any> {
    readonly type = RegioesAlvoActions.LIST_REGIOESALVO;
}

export class ListRegioesAlvoSuccessAction extends NgRxAction<any> {
    readonly type = RegioesAlvoActions.LIST_REGIOESALVO_SUCCESS;
}

export class ListRegioesAlvoErrorAction extends NgRxAction<any> {
    readonly type = RegioesAlvoActions.LIST_REGIOESALVO_ERROR;
}

export class LoadRegioesAlvoAction extends NgRxAction<any>{
    readonly type = RegioesAlvoActions.LOAD_REGIOESALVO;
}

export class LoadRegioesAlvoSuccessAction extends NgRxAction<any>{
    readonly type = RegioesAlvoActions.LOAD_REGIOESALVO_SUCCESS;
}

export class LoadRegioesAlvoErrorAction extends NgRxAction<any>{
    readonly type = RegioesAlvoActions.LOAD_REGIOESALVO_ERROR;
}

export class SaveRegioesAlvoAction extends NgRxAction<RegioesAlvoInterface>{
    readonly type = RegioesAlvoActions.SAVE_REGIOESALVO;
}

export class SaveRegioesAlvoSuccessAction extends NgRxAction<any>{
    readonly type = RegioesAlvoActions.SAVE_REGIOESALVO_SUCCESS;
}

export class SaveRegioesAlvoErrorAction extends NgRxAction<any>{
    readonly type = RegioesAlvoActions.SAVE_REGIOESALVO_ERROR;
}

export class UpdateRegioesAlvoAction extends NgRxAction<RegioesAlvoInterface>{
    readonly type = RegioesAlvoActions.UPDATE_REGIOESALVO;
}

export class UpdateRegioesAlvoSuccessAction extends NgRxAction<any>{
    readonly type = RegioesAlvoActions.UPDATE_REGIOESALVO_SUCCESS;
}

export class UpdateRegioesAlvoErrorAction extends NgRxAction<any>{
    readonly type = RegioesAlvoActions.UPDATE_REGIOESALVO_ERROR;
}

export class ErrorRegioesAlvoActions extends NgRxAction<any>{
    readonly type = RegioesAlvoActions.REGIOESALVO_ERROR;
}

export class DeleteRegioesAlvoAction extends NgRxAction<any>{
    readonly type = RegioesAlvoActions.DELETE_REGIOESALVO;
}

export class DeleteRegioesAlvoSuccessAction extends NgRxAction<any>{
    readonly type = RegioesAlvoActions.DELETE_REGIOESALVO_SUCCESS;
}

export class DeleteRegioesAlvoErrorAction extends NgRxAction<any>{
    readonly type = RegioesAlvoActions.DELETE_REGIOESALVO_ERROR;
}


export type TypesActions =
    ListRegioesAlvoAction
    | ListRegioesAlvoSuccessAction
    | ListRegioesAlvoErrorAction
    | LoadRegioesAlvoAction
    | LoadRegioesAlvoSuccessAction
    | LoadRegioesAlvoErrorAction
    | SaveRegioesAlvoAction
    | SaveRegioesAlvoSuccessAction
    | SaveRegioesAlvoErrorAction
    | UpdateRegioesAlvoAction
    | UpdateRegioesAlvoSuccessAction
    | UpdateRegioesAlvoErrorAction
    | ErrorRegioesAlvoActions
    | DeleteRegioesAlvoAction
    | DeleteRegioesAlvoSuccessAction
    | DeleteRegioesAlvoErrorAction