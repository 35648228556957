import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, filter, take, switchMap, debounceTime } from 'rxjs/operators';
import { of, empty } from 'rxjs';

import * as adminActions from './admin.actions';
import { AdminService } from './admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertObservable } from 'src/app/shared/SweetAlertObservable';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Injectable()
export class AdminEffects {

    constructor(
        private actions$: Actions,
        private adminService: AdminService,
        private router: Router,
        private sweetAlertObservable: SweetAlertObservable
    ) { }

    @Effect()
    loadAdminListAction$ = this.actions$.pipe(
        ofType<adminActions.ListAdminAction>(adminActions.AdminActions.LIST_ADMIN),
        map(action => action.payload),
        debounceTime(800),
        mergeMap(payload => {
            return this.adminService.list(payload)
            .pipe(
                map((response: any) => {
                    const {elements, total} = response;
                    return new adminActions.ListAdminSuccessAction({
                        total : total * payload.maxRows,
                        admins: elements
                    });
                }),
                catchError((err: any) => {
                    return of(new adminActions.ListAdminErrorAction(err.error.message));
                })
            );
        })
    );

    @Effect()
    loadAdminAction$ = this.actions$.pipe(
        ofType<adminActions.LoadAdminAction>(adminActions.AdminActions.LOAD_ADMIN),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.adminService.get(payload)
                .pipe(
                    map((response: any) => response),
                    map(admin =>{
                        return new adminActions.LoadAdminSuccessAction(admin);
                    }),
                    catchError(err => {
                        return of(new adminActions.LoadAdminErrorAction(err.error.message));
                    })
                );
        })
    );

    @Effect()
    saveAction$ = this.actions$.pipe(
        ofType<adminActions.SaveAdminAction>(adminActions.AdminActions.SAVE_ADMIN),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.adminService.save(payload).pipe(
                map( _ => {
                    return new adminActions.SaveAdminSuccessAction();
                }),
                catchError(err =>{
                    return of(new adminActions.SaveAdminErrorAction(err.error.message));
                })
            )
        })
    );

    @Effect()
    saveSuccessAction$ = this.actions$.pipe(
        ofType<adminActions.SaveAdminSuccessAction>(adminActions.AdminActions.SAVE_ADMIN_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ =>{
                this.router.navigate(['/admins']);
            });
            return empty();
        })
    );

    @Effect()
    updateAction$ = this.actions$.pipe(
        ofType<adminActions.UpdateAdminAction>(adminActions.AdminActions.UPDATE_ADMIN),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.adminService.update(payload).pipe(
                map( _ => {
                    return new adminActions.UpdateAdminSuccessAction();
                }),
                catchError(err =>{
                    return of(new adminActions.UpdateAdminErrorAction);
                })
            )
        })
    );

    @Effect()
    updateSuccessAction$ = this.actions$.pipe(
        ofType<adminActions.UpdateAdminSuccessAction>(adminActions.AdminActions.UPDATE_ADMIN_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ =>{
                this.router.navigate(['/admins']);
            });
            return empty();
        })
    );

    @Effect()
    deleteAdminAction$ = this.actions$.pipe(
        ofType<adminActions.DeleteAdminAction>(adminActions.AdminActions.DELETE_ADMIN),
        map(action => action.payload),
        mergeMap(admin => {
            const preConfirm = () => {
                return this.adminService.delete(admin.id).pipe(
                    take(1)
                ).toPromise();
            }
            return this.sweetAlertObservable.confirm({
                title: 'Atenção!',
                text: 'Você deseja mesmo deletar o administrador ' + admin.nome + ' ?',
                preConfirm: preConfirm
            }).pipe(
                mergeMap(_ => {
                    return this.sweetAlertObservable.success({
                        type: 'success',
                        title: 'Sucesso!',
                        text: `Administrador ${admin.nome} deletado com sucesso`,
                    });
                }),
                catchError((err: HttpErrorResponse) => {
                    if(err.status === 400) {
                        return this.sweetAlertObservable.error({
                            type: 'error',
                            title: 'Erro!',
                            text: err.error.message,
                        });
                    }
                    return empty();
                }),
                switchMap(_ => {
                    return [
                       new adminActions.DeleteAdminSuccessAction(),
                       new adminActions.ListAdminAction({
                           page: 0,
                           maxRows: 10,
                           buscaPor: ''
                       })
                    ]
                }),
            )
        })
    );

    @Effect()
    loadActionAlterarSenha$ = this.actions$.pipe(
        ofType<adminActions.LoadAlterarSenhaAction>(adminActions.AdminActions.LOAD_ALTERAR_SENHA),
        map(action => action.payload),
        mergeMap(payload => {
            return this.adminService.alterarSenha(payload.senha, payload.novaSenha).pipe(
                map((response: any) => {
                    return new adminActions.LoadAlterarSenhaSuccessAction(response)
                }),
                catchError((err: HttpErrorResponse) => {
                    return  of(new adminActions.LoadAlterarSenhaErrorAction(err.error.message));
                })
            );
        })
    )

    @Effect()
    loadActionAlterarSenhaSucces$ = this.actions$.pipe(
        ofType<adminActions.LoadAlterarSenhaSuccessAction>(adminActions.AdminActions.LOAD_ALTERAR_SENHA_SUCCESS),
        mergeMap(_ =>{
            swal('Sua senha foi alterada com sucesso!', '', 'success')
            .then((result) => {
                if (result.value) {
                    this.router.navigate(['/admins']);
                }
              });
            return empty();
        })
    )
}