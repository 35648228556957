import { NgRxAction } from '../NgRxAction';

export enum ToptrintaActions {
    LIST_TOPTRINTA = '[TOPTRINTA] - List TOPTRINTA - ',
    LIST_TOPTRINTA_SUCCESS = '[TOPTRINTA] - List TOPTRINTA Success - ',
    LIST_TOPTRINTA_ERROR = '[TOPTRINTA] - Error List TOPTRINTA - ',
    DELETE_TOPTRINTA = '[PRODUTO] Delete Produto',
    DELETE_TOPTRINTA_ERROR = '[PRODUTO] Delete Produto Error',
    DELETE_TOPTRINTA_SUCCESS = '[PRODUTO] Delete Produto Success',
}

export class ListToptrintaAction extends NgRxAction<any> {
    readonly type = ToptrintaActions.LIST_TOPTRINTA;
}

export class ListToptrintaSuccessAction extends NgRxAction<any> {
    readonly type = ToptrintaActions.LIST_TOPTRINTA_SUCCESS;
}

export class ListToptrintaErrorAction extends NgRxAction<any> {
    readonly type = ToptrintaActions.LIST_TOPTRINTA_ERROR;
}

export class DeleteToptrintaAction extends NgRxAction<any> {
    readonly type = ToptrintaActions.DELETE_TOPTRINTA;
}

export class DeleteToptrintaSuccessAction extends NgRxAction<any> {
    readonly type = ToptrintaActions.DELETE_TOPTRINTA_SUCCESS;
}

export class DeleteToptrintaErrorAction extends NgRxAction<any> {
    readonly type = ToptrintaActions.DELETE_TOPTRINTA_ERROR;
}


export type TypesActions =
    ListToptrintaAction
    | ListToptrintaSuccessAction
    | ListToptrintaErrorAction
    | DeleteToptrintaAction
    | DeleteToptrintaSuccessAction
    | DeleteToptrintaErrorAction