import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ProdutoStoreService } from 'src/app/store/produtos/produtos-store.service';
import { filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  form: FormGroup;
  isLoading$: Observable<boolean>;
  textbreadcrumbs: string = "Novo Produto";
  id = this.route.snapshot.params['id'];
  constructor(
    private produtoStoreService: ProdutoStoreService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isLoading$ = this.produtoStoreService.getIsLoading();

    this.form = this.fb.group({
      id: [null],
      nome: ['', Validators.required],
      link: ['']
    });

    $('#form-produto').validate();

    this.produtoStoreService.getError()
      .pipe(
        filter(err => err.show && err.message != '')
      ).subscribe(err => swal('Opss...', err.message, 'error'));
    if (this.id) {
      this.textbreadcrumbs = "Editar Produto"
      this.produtoStoreService.dispatchLoadProdutoAction(this.id);
      this.produtoStoreService.getProduto()
        .pipe(
          filter(produto => produto !== null),
        )
        .subscribe((produto) => {
          this.form.patchValue(produto);
        });
    }
  }

  onClickSave() {
    if (this.form.valid) {
      if (this.id == null)
        this.produtoStoreService.dispatchSaveAction(this.form.value);
      else
        this.produtoStoreService.dispatchUpdateAction(this.form.value);
    }
  }

}
