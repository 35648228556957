import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExportacoesRoutingModule } from './exportacoes-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
import { CadastroComponent } from './cadastro/cadastro.component';
import { ExportacoesComponent } from './exportacoes/exportacoes.component';
import { ExportacoesStoreModule } from 'src/app/store/exportacoes/exportacoes-store.module';

@NgModule({
  declarations: [CadastroComponent, ExportacoesComponent],
  imports: [
    CommonModule,
    ExportacoesRoutingModule,
    NgxDatatableModule,
    SharedModule,
    ExportacoesStoreModule
  ],
  bootstrap: [ExportacoesComponent]
})
export class ExportacoesModule { }
