import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, filter } from 'rxjs/operators';
import { of, empty } from 'rxjs';

import * as authActions from './auth.actions';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import swal from 'sweetalert2';

@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router
    ) { }

    @Effect()
    loadAuthAction$ = this.actions$.pipe(
        ofType<authActions.LoadLoginAction>(authActions.AuthActions.LOAD_LOGIN),
        map(action => action.payload),
        mergeMap(payload => {
            return this.authService.login(payload.login, payload.password).pipe(
                map((response: HttpResponse<any>) => {
                    var userInfo = response.body;
                    userInfo.token = response.headers.get("Authorization");
                    this.authService.setUserInfo(userInfo);
                    return userInfo;
                }),
                map((userInfo: any) => {
                    this.router.navigate(['/top-trinta']);
                    return new authActions.LoadLoginSuccessAction(userInfo);
                }),
                catchError((err: any) => {
                    return of(new authActions.LoadLoginErrorAction(err.error.message));
                })
            );
        })
    );

    @Effect()
    checkUserIsLogged$  = this.actions$.pipe(
        ofType<authActions.CheckUserIsLogged>(authActions.AuthActions.CHECK_USER_ISLOGGED),
        map(_ => {
            const userInfo = this.authService.getUserInfo();         
            return new authActions.LoadLoginSuccessAction(userInfo);
        })
    )

    @Effect()
    logout$ = this.actions$.pipe(
        ofType<authActions.LogoutAction>(authActions.AuthActions.LOGOUT),
        mergeMap(_ => {
            localStorage.clear();
            return empty();
        })
    )

    @Effect()
    recuperarSenha$ = this.actions$.pipe(
        ofType<authActions.EsqueciSenhaAction>(authActions.AuthActions.ESQUECI_SENHA),
        map(action => action.payload),
        mergeMap(payload => {
            return this.authService.esqueciSenha(payload).pipe(
                map((response: any) =>{
                    return new authActions.EsqueciSenhaSuccessAction(response);
                }),
                catchError((err: any) => {
                    return  of(new authActions.EsqueciSenhaErrorAction(err.error.message));
                })
            );
        }),
    )

    @Effect()
    loadActionRecuperarSenhaSuccess$ = this.actions$.pipe(
        ofType<authActions.EsqueciSenhaSuccessAction>(authActions.AuthActions.ESQUECI_SENHA_SUCCESS),
        mergeMap(_ => {
            swal('Verifique seu email!', 'Um link para criar uma nova senha foi enviado para seu email', 'info')
            .then((result) => {
                if (result.value) {
                    this.router.navigate(['/login']);
                }
              });
            return empty();
        })
    )


    @Effect()
    redefinirSenha$ = this.actions$.pipe(
        ofType<authActions.RedefinirSenhaAction>(authActions.AuthActions.REDEFINIR_SENHA),
        map(action => action.payload),
        mergeMap(payload => {
            return this.authService.redefiniSenha(payload).pipe(
                map((response: any) =>{
                    return new authActions.RedefinirSenhaSuccessAction(response);
                }),
                catchError((err: any) => {
                    return  of(new authActions.RedefinirSenhaErrorAction(err.error.message));
                })
            );
        }),
    )

    @Effect()
    redefinirSenhaSuccess$ = this.actions$.pipe(
        ofType<authActions.RedefinirSenhaSuccessAction>(authActions.AuthActions.REDEFINIR_SENHA_SUCCESS),
        mergeMap(_ => {
            swal('Sucesso!', 'Senha alterada com sucesso.', 'success')
            .then((result) => {
                if (result.value) {
                    this.router.navigate(['/login']);
                }
              });
            return empty();
        })
    )
}