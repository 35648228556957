import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Observable, of, forkJoin } from 'rxjs';
import { AlvosStoreService } from 'src/app/store/alvos/alvos-store.service';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import swal from 'sweetalert2';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { RegioesAlvo } from 'src/app/model/regioesAlvos';
import { RegioesalvoStoreService } from 'src/app/store/regioesalvo/regioesalvo-store.service';
import { environment } from 'src/environments/environment';
import { Produtos } from 'src/app/model/produtos';
import { ProdutoStoreService } from 'src/app/store/produtos/produtos-store.service';
import { TipoAlvo } from 'src/app/model/tipoAlvo';
import { TipoalvoStoreService } from 'src/app/store/tipo-alvo/tipo-alvo-store.service';

declare var $: any;
@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  private imagens: FormArray;
  private regioes: FormArray;
  form: FormGroup;
  isLoadingImagens: boolean;
  isLoading$: Observable<boolean>;
  textbreadcrumbs = 'Novo Alvo';
  id = this.route.snapshot.params['id'];
  maxRows = 50;
  page = 0;
  buscaPor = '';
  regioes$: Observable<RegioesAlvo[]>;
  produtos$: Observable<Produtos[]>;
  tipoAlvo$: Observable<TipoAlvo[]>;
  items: Array<string> = ['Baixa', 'Média', 'Alta'];
  regioesSelecionadas = [];

  constructor(
    private alvosStoreService: AlvosStoreService,
    private regioesStoreService: RegioesalvoStoreService,
    private produtosStoreService: ProdutoStoreService,
    private tipoAlvoStoreService: TipoalvoStoreService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.isLoading$ = this.alvosStoreService.getIsLoading();
    this.loadRegioes();
    this.loadProdutos();
    this.loadTipoAlvo();

    this.form = this.fb.group({
      id: [null],
      nomePopular: ['', Validators.required],
      nomeCientifico: ['', Validators.required],
      tipo: ['', Validators.required],
      identificacao: ['', Validators.required],
      regioes: this.fb.array(this.id != null ? [] : [this.buildRegioes()]),
      produtos: [],
      descricao: [''],
      imagens: this.fb.array([])
    });
    this.imagens = this.form.get('imagens') as FormArray;
    this.regioes = this.form.get('regioes') as FormArray;
    // $('#form-alvos').validate();

    this.alvosStoreService.getError()
      .pipe(
        filter(err => err.show && err.message !== '')
      ).subscribe(err => swal('Opss...', err.message, 'error'));

    if (this.id) {
      this.textbreadcrumbs = 'Editar Alvo'
      this.alvosStoreService.dispatchLoadAlvoAction(this.id);
      this.alvosStoreService.getAlvo()
        .pipe(
          filter(alvo => alvo !== null),
          map(alvo => alvo)
        )
        .subscribe((alvo) => {
          this.form.patchValue(alvo);
          const imagensAlvo = alvo.imagens;
          const regioesAlvo = alvo.regioes;
          const imagens = this.form.get('imagens') as FormArray;
          const regioes = this.form.get('regioes') as FormArray;
          if (Array.isArray(imagensAlvo)) {
            imagensAlvo.forEach(f => {
              const field = this.fb.group({
                id: f.id,
                url: f.url
              });
              imagens.push(field);
            });
          }
          if (Array.isArray(regioesAlvo)){
            regioesAlvo.forEach(f => {
              const field = this.fb.group({
                regiao: this.fb.group({
                  id: f.regiao.id
                }),
                importancia: f.importancia,
              });
              regioes.push(field);
            });
          }
        });
    }
  }

  onClickSave() {
    if (this.form.valid) {
      if (this.id == null) {
        this.alvosStoreService.dispatchSaveAction(this.form.value);
      } else {
        this.alvosStoreService.dispatchUpdateAction(this.form.value);
      }
    }
  }

  loadRegioes() {
    this.regioesStoreService.dispatchListRegioesalvoAction(this.page, this.maxRows, this.buscaPor);
    this.regioes$ = this.regioesStoreService.getRegioesAlvoList();
  }

  loadProdutos() {
    this.produtosStoreService.dispatchListProdutoAction(this.page, this.maxRows, this.buscaPor);
    this.produtos$ = this.produtosStoreService.getProdutoList();
  }

  loadTipoAlvo() {
    this.tipoAlvoStoreService.dispatchListTipoalvoAction(this.page, this.maxRows, this.buscaPor);
    this.tipoAlvo$ = this.tipoAlvoStoreService.getTipoAlvoList();
  }



  onFileChanged(event) {
    const file = event.target.files;
    const tamanhoMaxArquivo = (file[file.length - 1].size/10000);

    if (tamanhoMaxArquivo > 100) {
      swal('Opss...', 'Tamanho da imagem excede o tamanho permitido pela aplicação, por favor verifique e tente novamente. Max(1MB)', 'info').then(_=>{
        $("#arquivo").val('');
      });
    } else{
      if (file != null && file.length > 0) {
        const uploadData = new FormData();
        for (let i = 0; file.length > i; i++) {
          uploadData.append('file', file[i]);
        }
        this.isLoadingImagens = true;
        this.http.post(`${environment.api}/imagens/alvo`, uploadData).subscribe(
          (res) => this.response(res),
          (_) => swal('Opss..', 'Erro ao fazer upload', 'error')
        );
      }
    }
  }

  private response(url) {
    const item = this.createItemFoto();
    item.patchValue(url);
    this.isLoadingImagens = false;
    this.imagens.push(item);
  }

  createItemFoto(): FormGroup {
    return this.fb.group({
      id: null,
      url: ''
    });
  }

  onClickDelet(index) {
    this.imagens.removeAt(index);
  }

  onClickAddCampos(): void{
    this.regioes = this.form.get('regioes') as FormArray;
    this.regioes.push(this.buildRegioes());
  }

  buildRegioes(): FormGroup{
    return this.fb.group({
      regiao: this.fb.group({
        id: null
      }),
      importancia: '',
    });
  }

  onClickDelete(index){
    this.regioes.removeAt(index);
  }

}
