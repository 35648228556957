import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, filter, take, switchMap, debounceTime } from 'rxjs/operators';
import { of, empty } from 'rxjs';

import * as produtoActions from './produtos.actions';
import { ProdutosService } from './produtos.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SweetAlertObservable } from 'src/app/shared/SweetAlertObservable';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ProdutoEffects {

    constructor(
        private actions$: Actions,
        private router: Router,
        private produtoService: ProdutosService,
        private sweetAlertObservable: SweetAlertObservable
    ) { }

    @Effect()
    loadProdutoListAction$ = this.actions$.pipe(
        ofType<produtoActions.ListProdutoAction>(produtoActions.ProdutoActions.LIST_PRODUTO),
        map(action => action.payload),
        debounceTime(800),
        mergeMap(payload => {
            return this.produtoService.list(payload)
            .pipe(
                map((response: any) => {
                    const {elements, total} = response;
                    return new produtoActions.ListProdutoSuccessAction({
                        total: total * payload.maxRows,
                        produtos: elements
                    });
                }),
                catchError((err: any) => {
                    return of(new produtoActions.ListProdutoErrorAction(err.error.message));
                })
            );
        })
    );

    @Effect()
    loadProdutoAction$ = this.actions$.pipe(
        ofType<produtoActions.LoadProdutoAction>(produtoActions.ProdutoActions.LOAD_PRODUTO),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.produtoService.get(payload)
                .pipe(
                    map((response: any) => response),
                    map(produto =>{
                        return new produtoActions.LoadProdutoSuccessAction(produto);
                    }),
                    catchError(err => {
                        return of(new produtoActions.LoadProdutoErrorAction(err.error.message));
                    })
                );
        })
    );

    @Effect()
    saveOrUpdateAction$ = this.actions$.pipe(
        ofType<produtoActions.SaveProdutoAction>(produtoActions.ProdutoActions.SAVE_PRODUTO),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.produtoService.save(payload).pipe(
                map( _ => {
                    return new produtoActions.SaveProdutoSuccessAction();
                }),
                catchError(err =>{
                    return of(new produtoActions.SaveProdutoErrorAction(err.error.message));
                })
            )
        })
    );

    @Effect()
    saveOrUpdateSuccessAction$ = this.actions$.pipe(
        ofType<produtoActions.SaveProdutoAction>(produtoActions.ProdutoActions.SAVE_PRODUTO_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ =>{
                this.router.navigate(['/produtos']);
            });
            return empty();
        })
    );

    @Effect()
    updateAction$ = this.actions$.pipe(
        ofType<produtoActions.UpdateProdutoAction>(produtoActions.ProdutoActions.UPDATE_PRODUTO),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.produtoService.update(payload).pipe(
                map( _ => {
                    return new produtoActions.SaveProdutoSuccessAction();
                }),
                catchError(err =>{
                    return of(new produtoActions.ErrorProdutoActions);
                })
            )
        })
    );

    @Effect()
    updateSuccessAction$ = this.actions$.pipe(
        ofType<produtoActions.UpdateProdutoAction>(produtoActions.ProdutoActions.UPDATE_PRODUTO_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ =>{
                this.router.navigate(['/produtos']);
            });
            return empty();
        })
    );

    @Effect()
    deleteProdutoAction$ = this.actions$.pipe(
        ofType<produtoActions.DeleteProdutoAction>(produtoActions.ProdutoActions.DELETE_PRODUTO),
        map(action => action.payload),
        mergeMap(produto => {
            const preConfirm = () => {
                return this.produtoService.delete(produto.id).pipe(
                    take(1)
                ).toPromise();
            }
            return this.sweetAlertObservable.confirm({
                title: 'Atenção!',
                text: 'Você deseja mesmo deletar o produto ' + produto.nome + ' ?',
                preConfirm: preConfirm
            }).pipe(
                mergeMap(_ => {
                    return this.sweetAlertObservable.success({
                        type: 'success',
                        title: 'Sucesso!',
                        text: `Produto ${produto.nome} deletado com sucesso`,
                    });
                }),
                catchError((err: HttpErrorResponse) => {
                    if(err.status === 400) {                        
                        return this.sweetAlertObservable.error({
                            type: 'error',
                            title: 'Erro!',
                            text: err.error.message,
                        });
                    }
                    return empty();
                }),
                switchMap(_ => {
                    return [
                       new produtoActions.DeleteProdutoSuccessAction(),
                       new produtoActions.ListProdutoAction({
                           page: 0,
                           maxRows: 10,
                           buscaPor: ''
                       })
                    ]
                }),
            )
        })
    );

}