import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RedefinirSenhaComponent } from './redefinirSenha.component';

const routes: Routes = [
  {path: '', component: RedefinirSenhaComponent},
  {path: ':token', component: RedefinirSenhaComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class RedefinirSenhaRoutingModule { }
