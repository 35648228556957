import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, filter, take, switchMap, debounceTime } from 'rxjs/operators';
import { of, empty } from 'rxjs';

import * as usuarioActions from './usuario.actions';
import { UsuarioService } from './usuario.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertObservable } from 'src/app/shared/SweetAlertObservable';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Injectable()
export class UsuarioEffects {

    constructor(
        private actions$: Actions,
        private usuarioService: UsuarioService,
        private router: Router,
        private sweetAlertObservable: SweetAlertObservable
    ) { }

    @Effect()
    loadUsuarioListAction$ = this.actions$.pipe(
        ofType<usuarioActions.ListUsuarioAction>(usuarioActions.UsuarioActions.LIST_USUARIO),
        map(action => action.payload),
        debounceTime(800),
        mergeMap(payload => {
            return this.usuarioService.list(payload)
            .pipe(
                map((response: any) => {
                    const {elements, total} = response;
                    return new usuarioActions.ListUsuarioSuccessAction({
                        total : total * payload.maxRows,
                        usuarios: elements
                    });
                }),
                catchError((err: any) => {
                    return of(new usuarioActions.ListUsuarioErrorAction(err.error.message));
                })
            );
        })
    );

    @Effect()
    loadUsuarioAction$ = this.actions$.pipe(
        ofType<usuarioActions.LoadUsuarioAction>(usuarioActions.UsuarioActions.LOAD_USUARIO),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.usuarioService.get(payload)
                .pipe(
                    map((response: any) => response),
                    map(usuario =>{
                        return new usuarioActions.LoadUsuarioSuccessAction(usuario);
                    }),
                    catchError(err => {
                        return of(new usuarioActions.LoadUsuarioErrorAction(err.error.message));
                    })
                );
        })
    );

    @Effect()
    saveAction$ = this.actions$.pipe(
        ofType<usuarioActions.SaveUsuarioAction>(usuarioActions.UsuarioActions.SAVE_USUARIO),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.usuarioService.save(payload).pipe(
                map( _ => {
                    return new usuarioActions.SaveUsuarioSuccessAction();
                }),
                catchError(err =>{
                    return of(new usuarioActions.SaveUsuarioErrorAction(err.error.message));
                })
            )
        })
    );

    @Effect()
    saveSuccessAction$ = this.actions$.pipe(
        ofType<usuarioActions.SaveUsuarioSuccessAction>(usuarioActions.UsuarioActions.SAVE_USUARIO_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ =>{
                this.router.navigate(['/usuarios']);
            });
            return empty();
        })
    );

    @Effect()
    updateAction$ = this.actions$.pipe(
        ofType<usuarioActions.UpdateUsuarioAction>(usuarioActions.UsuarioActions.UPDATE_USUARIO),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.usuarioService.update(payload).pipe(
                map( _ => {
                    return new usuarioActions.UpdateUsuarioSuccessAction();
                }),
                catchError(err =>{
                    return of(new usuarioActions.UpdateUsuarioErrorAction);
                })
            )
        })
    );

    @Effect()
    updateSuccessAction$ = this.actions$.pipe(
        ofType<usuarioActions.UpdateUsuarioSuccessAction>(usuarioActions.UsuarioActions.UPDATE_USUARIO_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ =>{
                this.router.navigate(['/usuarios']);
            });
            return empty();
        })
    );

    @Effect()
    deleteUsuarioAction$ = this.actions$.pipe(
        ofType<usuarioActions.DeleteUsuarioAction>(usuarioActions.UsuarioActions.DELETE_USUARIO),
        map(action => action.payload),
        mergeMap(usuario => {
            const preConfirm = () => {
                return this.usuarioService.delete(usuario.id).pipe(
                    take(1)
                ).toPromise();
            }
            return this.sweetAlertObservable.confirm({
                title: 'Atenção!',
                text: 'Você deseja mesmo deletar o usuário ' + usuario.nome + ' ?',
                preConfirm: preConfirm
            }).pipe(
                mergeMap(_ => {
                    return this.sweetAlertObservable.success({
                        type: 'success',
                        title: 'Sucesso!',
                        text: `Usuário ${usuario.nome} deletado com sucesso`,
                    });
                }),
                catchError((err: HttpErrorResponse) => {
                    if(err.status === 400) {
                        return this.sweetAlertObservable.error({
                            type: 'error',
                            title: 'Erro!',
                            text: err.error.message,
                        });
                    }
                    return empty();
                }),
                switchMap(_ => {
                    return [
                       new usuarioActions.DeleteUsuarioSuccessAction(),
                       new usuarioActions.ListUsuarioAction({
                           page: 0,
                           maxRows: 10,
                           buscaPor: ''
                       })
                    ]
                }),
            )
        })
    );


    @Effect()
    loadPerfisAction$ = this.actions$.pipe(
        ofType<usuarioActions.ListUsuarioAction>(usuarioActions.UsuarioActions.LIST_PERFIS),
        map(action => action.payload),
        debounceTime(800),
        mergeMap(payload => {
            return this.usuarioService.getPerfis(payload)
            .pipe(
                map((response: any) => {
                    const elements = response;
                    return new usuarioActions.ListPerfisSuccessAction(elements);
                }),
                catchError((err: any) => {
                    return of(new usuarioActions.ListPerfisErrorAction(err.error.message));
                })
            );
        })
    );
}