import * as produtoState from './produtos.state';
import * as actions from './produtos.actions';

export function produtoReducer(
    state: produtoState.ProdutosState = produtoState.initialState,
    action: actions.TypesActions
): produtoState.ProdutosState {

    switch (action.type) {
        case actions.ProdutoActions.LIST_PRODUTO:
            return {
                ...state,
                isLoading: true,
                list: {
                    ...state.list,
                    produtos: []
                },
                error: produtoState.initialState.error
            }
        case actions.ProdutoActions.LIST_PRODUTO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    produtos: action.payload.produtos,
                    total: action.payload.total
                }
            }
        case actions.ProdutoActions.LIST_PRODUTO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload,
                },
                list: {
                    ...state.list,
                    total: 0
                }
            }
        case actions.ProdutoActions.LOAD_PRODUTO:
            return {
                ...state,
                isLoading: true,
                produto: null
            }
        case actions.ProdutoActions.LOAD_PRODUTO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                produto: action.payload
            }
        case actions.ProdutoActions.LOAD_PRODUTO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.ProdutoActions.SAVE_PRODUTO:
            return {
                ...state,
                isLoading: true,
                error: produtoState.initialState.error
            }
        case actions.ProdutoActions.SAVE_PRODUTO_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case actions.ProdutoActions.SAVE_PRODUTO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }

        case actions.ProdutoActions.UPDATE_PRODUTO:
            return {
                ...state,
                isLoading: true,
                error: produtoState.initialState.error
            }
        case actions.ProdutoActions.UPDATE_PRODUTO_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case actions.ProdutoActions.DELETE_PRODUTO:
            return {
                ...state,
                isLoading: false,
                produto: null
            }
        default:
            return state;
    }

}