import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';

import { InfestacoesRoutingModule } from './infestacoes-routing.module';
import { InfestacoesComponent } from './infestacoes/infestacoes.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InfestacoesStoreModule } from 'src/app/store/infestacoes/infestacoes-store.module';

@NgModule({
  declarations: [InfestacoesComponent],
  imports: [
    InfestacoesRoutingModule,
    NgxDatatableModule,
    NgSelectModule,
    SharedModule,
    InfestacoesStoreModule
  ],
  providers:[],
  bootstrap: [InfestacoesComponent]
})

export class InfestacoesModule { }