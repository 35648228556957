import { Injectable } from "@angular/core";

import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../app.reducer';
import * as actions from './auth.actions';
import * as state from './auth.state';

@Injectable()
export class AuthStoreService {

  constructor(private store: Store<AppState>) { }

  private authState = createFeatureSelector<state.AuthState>(state.STATE_NAME);
  private isLoading = createSelector(this.authState, state.selectIsLoading);
  private userInfo = createSelector(this.authState, state.selectUserInfo);
  private error = createSelector(this.authState, state.selectError);


  getIsLoading() {
    return this.store.select(this.isLoading);
  }

  getUserInfo() {
    return this.store.select(this.userInfo);
  }

  getError() {
    return this.store.select(this.error);
  }

  dispatchLoadLoginAction(values: { login: string, password: string }) {
    this.store.dispatch(new actions.LoadLoginAction(values));
  }

  dispatchCheckUserLogged() {
    this.store.dispatch(new actions.CheckUserIsLogged());
  }

  dispatchLogoutAction() {
    this.store.dispatch(new actions.LogoutAction());
  }

  dispatchEsqueciSenhaAction(email: string){
    this.store.dispatch(new actions.EsqueciSenhaAction(email))
  }

  dispatchRedefinirSenhaAction(senha: string, novaSenha: string, token: string){
    this.store.dispatch(new actions.RedefinirSenhaAction({
      senha: senha,
      novaSenha: novaSenha,
      token: token
    }))
  }

  dispatchClearErrorAction() {
    this.store.dispatch(new actions.LoadClearErrors());
  }

}