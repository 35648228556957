import * as toptrintaState from './top-trinta.state';
import * as actions from './top-trinta.actions';

export function toptrintaReducer(
    state: toptrintaState.ToptrintaState = toptrintaState.initialState,
    action: actions.TypesActions
): toptrintaState.ToptrintaState {

    switch (action.type) {
        case actions.ToptrintaActions.LIST_TOPTRINTA:
            return {
                ...state,
                isLoading: true,
                list: {
                    ...state.list,
                    topTrintaList: []
                },
                error: toptrintaState.initialState.error
            }
        case actions.ToptrintaActions.LIST_TOPTRINTA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    topTrintaList: action.payload.topTrintaList,
                    total: action.payload.total
                }
            }
        case actions.ToptrintaActions.LIST_TOPTRINTA_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                },
                list: {
                    ...state.list,
                    total: 0
                }
            }
        case actions.ToptrintaActions.DELETE_TOPTRINTA:
            return {
                ...state,
                isLoading: false,
                toptrinta: null
            }
        default:
            return state;
    }

}