import { RegioesAlvo } from 'src/app/model/regioesAlvos';

export const STATE_NAME = 'regioesalvo';

export interface RegioesalvoState {
    isLoading: boolean;
    list: {
        regioesAlvoList: RegioesAlvo[],
        total: number
    };
    regioesAlvo: RegioesAlvo;
    error: {
        show: boolean,
        message: string
    }
}

export const initialState: RegioesalvoState = {
    isLoading: false,
    list: {
        regioesAlvoList: [],
        total: 0
    },
    regioesAlvo: null,
    error: {
        show: false,
        message: null
    }
}

export const selectIsLoading = (state: RegioesalvoState) => state.isLoading;
export const selectRegioesalvo = (state: RegioesalvoState) => state.regioesAlvo;
export const selectListRegioesalvo = (state: RegioesalvoState) => state.list.regioesAlvoList;
export const selectError = (state: RegioesalvoState) => state.error;
export const selectTotal = (state: RegioesalvoState) => state.list.total;