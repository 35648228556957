import { NgRxAction } from '../NgRxAction';

export enum ExportacoesActions {
    LIST_EXPORTACOES = '[EXPORTACOES] - List EXPORTACOES - ',
    LIST_EXPORTACOES_SUCCESS = '[EXPORTACOES] - List EXPORTACOES Success - ',
    LIST_EXPORTACOES_ERROR = '[EXPORTACOES] - Error List EXPORTACOES - ',
}

export class ListExportacoesAction extends NgRxAction<any> {
    readonly type = ExportacoesActions.LIST_EXPORTACOES;
}

export class ListExportacoesSuccessAction extends NgRxAction<any> {
    readonly type = ExportacoesActions.LIST_EXPORTACOES_SUCCESS;
}

export class ListExportacoesErrorAction extends NgRxAction<any> {
    readonly type = ExportacoesActions.LIST_EXPORTACOES_ERROR;
}


export type TypesActions =
    ListExportacoesAction
    | ListExportacoesSuccessAction
    | ListExportacoesErrorAction