import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

import { AuthStoreService } from './auth-store.service';
import { catchError, mergeMap, take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public authStoreService: AuthStoreService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
        return this.authStoreService.getUserInfo().pipe(
            take(1),
            mergeMap(userInfo => {
                let headers = new HttpHeaders();
                if (userInfo !== null) {
                    headers = headers.append("Authorization", userInfo.token);
                }

                return next.handle(request.clone({
                    headers
                })).pipe(
                    catchError(err => {
                        if (err.status === 403) {
                            throw err;
                        }

                        if (err.status === 400) {
                            swal('Opss...', err.error.message, 'error');
                        }
                        if (err.status === 401) {
                            this.router.navigate(['/login']);
                            localStorage.clear();
                        }
                        throw err;
                    })
                );
            })
        );
    }
}
