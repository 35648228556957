import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { RegioesalvoStoreService } from 'src/app/store/regioesalvo/regioesalvo-store.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  form: FormGroup;
  isLoading$: Observable<boolean>;
  textbreadcrumbs: string = "Nova Região do Alvo";
  id = this.route.snapshot.params['id'];
  constructor(
    private regiaoAlvoStoreService: RegioesalvoStoreService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isLoading$ = this.regiaoAlvoStoreService.getIsLoading();

    this.form = this.fb.group({
      id: [null],
      nome: ['', Validators.required]
    });

    $('#form-regioes-alvo').validate();

    this.regiaoAlvoStoreService.getError()
      .pipe(
        filter(err => err.show && err.message != '')
      ).subscribe(err => swal('Opss...', err.message, 'error'));

    if (this.id) {
      this.textbreadcrumbs = "Editar Região do Alvo"
      this.regiaoAlvoStoreService.dispatchLoadRegioesalvoAction(this.id);
      this.regiaoAlvoStoreService.getRegioesalvo()
        .pipe(
          filter(regiao_alvo => regiao_alvo !== null),
        )
        .subscribe((regiao_alvo) => {
          this.form.patchValue(regiao_alvo);
        });
    }

  }

  onClickSave() {
    if (this.form.valid) {
      if (this.id == null)
        this.regiaoAlvoStoreService.dispatchSaveAction(this.form.value);
      else {
        this.regiaoAlvoStoreService.dispatchUpdateAction(this.form.value);
      }
    }

  }
}
