import { Importacao } from 'src/app/model/importacao';

export const STATE_NAME = 'exportacoes';

export interface ExportacoesState {
    isLoading: boolean;
    list: {
        exportacoes: Importacao[],
        total: number
    }
    exportacoes: Importacao;
    error: {
        show: boolean,
        message: string
    }
}

export const initialState: ExportacoesState = {
    isLoading: false,
    list: {
        exportacoes: [],
        total: 0
    },
    exportacoes: null,
    error: {
        show: false,
        message: null
    }
}

export const selectIsLoading = (state: ExportacoesState) => state.isLoading;
export const selectExportacoes = (state: ExportacoesState) => state.exportacoes;
export const selectListExportacoes = (state: ExportacoesState) => state.list.exportacoes;
export const selectTotal = (state: ExportacoesState) => state.list.total;
export const selectError = (state: ExportacoesState) => state.error
