import { TipoAlvo } from 'src/app/model/tipoAlvo';

export const STATE_NAME = 'tipoalvo';

export interface TipoalvoState {
    isLoading: boolean;
    list: {
        tipoAlvoList: TipoAlvo[],
        total: number
    };
    tipoAlvo: TipoAlvo;
    error: {
        show: boolean,
        message: string
    }
}

export const initialState: TipoalvoState = {
    isLoading: false,
    list: {
        tipoAlvoList: [],
        total: 0
    },
    tipoAlvo: null,
    error: {
        show: false,
        message: null
    }
}

export const selectIsLoading = (state: TipoalvoState) => state.isLoading;
export const selectTipoalvo = (state: TipoalvoState) => state.tipoAlvo;
export const selectListTipoalvo = (state: TipoalvoState) => state.list.tipoAlvoList;
export const selectError = (state: TipoalvoState) => state.error;
export const selectTotal = (state: TipoalvoState) => state.list.total;