import { Revendas } from 'src/app/model/revendas';

export const STATE_NAME = 'revenda';

export interface RevendaState {
    isLoading: boolean;
    list: {
        revendas: Revendas[],
        total: number
    };
    revenda: Revendas;
    error: {
        show: boolean,
        message: string
    }
}

export const initialState: RevendaState = {
    isLoading: false,
    list: {
        revendas: [],
        total: 0
    },
    revenda: null,
    error: {
        show: false,
        message: null
    }
}

export const selectIsLoading = (state: RevendaState) => state.isLoading;
export const selectRevenda = (state: RevendaState) => state.revenda;
export const selectListRevenda = (state: RevendaState) => state.list.revendas;
export const selectTotal = (state: RevendaState) => state.list.total;
export const selectError = (state: RevendaState) => state.error;