import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Produtos } from 'src/app/model/produtos';
import { TipoAlvo } from 'src/app/model/tipoAlvo';
import { RegioesAlvo } from 'src/app/model/regioesAlvos';

export interface AlvoInterface{
  id: number;
  descricao: string;
  identificacao: string;
  nomeCientifico: string;
  nomePopular: string;
  produtos: Produtos;
  regioes: RegioesAlvo;
  tipo: TipoAlvo;
  imagens: [{
    id : number;
    url: string;
  }];
}

@Injectable()
export class AlvosService {

  private api: string = `${environment.api}/alvos`;
  constructor(
    private http: HttpClient
  ) { }



  list(payload: {
    page: number,
    maxRows: number,
    buscaPor: string
  }) {
    return this.http.get(`${this.api}/filter?page=${payload.page}&max=${payload.maxRows}&search=${payload.buscaPor}`);
  }

  get(id: number) {
    return this.http.get(`${this.api}/${id}`);
  }

  save(body: AlvoInterface) {
    return this.http.post(`${this.api}`, {
      id: body.id,
      descricao: body.descricao,
      identificacao: body.identificacao,
      nomeCientifico: body.nomeCientifico,
      nomePopular: body.nomePopular,
      produtos: body.produtos,
      regioes: body.regioes,
      tipo: body.tipo,
      imagens: body.imagens
    });
  }

  update(body: AlvoInterface) {
    return this.http.put(`${this.api}/${body.id}`, {
      descricao: body.descricao,
      identificacao: body.identificacao,
      nomeCientifico: body.nomeCientifico,
      nomePopular: body.nomePopular,
      produtos: body.produtos,
      regioes: body.regioes,
      tipo: body.tipo,
      imagens: body.imagens
    });
  }

  delete(id: number) {
    return this.http.delete(`${this.api}/${id}`);
  }

}