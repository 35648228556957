import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, filter, take, switchMap, debounceTime } from 'rxjs/operators';
import { of, empty } from 'rxjs';

import * as alvosActions from './alvos.actions';
import { AlvosService } from './alvos.service';
import { SweetAlertObservable } from 'src/app/shared/SweetAlertObservable';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AlvosEffects {

    constructor(
        private actions$: Actions,
        private alvosService: AlvosService,
        private router: Router,
        private sweetAlertObservable: SweetAlertObservable
    ) { }

    @Effect()
    loadAlvosAction$ = this.actions$.pipe(
        ofType<alvosActions.ListAlvosAction>(alvosActions.AlvosActions.LIST_ALVOS),
        map(action => action.payload),
        debounceTime(800),
        mergeMap(payload => {
            return this.alvosService.list(payload)
                .pipe(
                    map((response: any) => {
                        const { elements, total } = response;
                        return new alvosActions.ListAlvosSuccessAction({
                            alvos: elements,
                            total: total * payload.maxRows
                        });
                    }),
                    catchError((err: any) => {
                        return of(new alvosActions.ListAlvosErrorAction(err.error.message));
                    })
                );
        })
    );

    @Effect()
    loadAlvoAction$ = this.actions$.pipe(
        ofType<alvosActions.LoadAlvoAction>(alvosActions.AlvosActions.LOAD_ALVO),
        map(action => action.payload),
        mergeMap(payload => {
            return this.alvosService.get(payload)
                .pipe(
                    map((response: any) => response),
                    map(alvo => {
                        return new alvosActions.LoadAlvoSuccessAction(alvo);
                    }),
                    catchError(err => {
                        return of(new alvosActions.LoadAlvoErrorAction(err.error.message));
                    })
                );
        })
    );

    @Effect()
    saveOrUpdateAction$ = this.actions$.pipe(
        ofType<alvosActions.SaveAlvoAction>(alvosActions.AlvosActions.SAVE_ALVO),
        map(action => action.payload),
        mergeMap(payload => {
            return this.alvosService.save(payload).pipe(
                map(_ => {
                    return new alvosActions.SaveAlvoSuccessAction();
                }),
                catchError(err => {
                    return of(new alvosActions.SaveAlvoErrorAction(err));
                })
            )
        })
    );

    @Effect()
    saveOrUpdateSuccessAction$ = this.actions$.pipe(
        ofType<alvosActions.SaveAlvoAction>(alvosActions.AlvosActions.SAVE_ALVO_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ => {
                this.router.navigate(['/alvos']);
            });
            return empty();
        })
    );

    @Effect()
    updateAction$ = this.actions$.pipe(
        ofType<alvosActions.UpdateAlvoAction>(alvosActions.AlvosActions.UPDATE_ALVO),
        map(action => action.payload),
        mergeMap(payload => {
            return this.alvosService.update(payload).pipe(
                map(_ => {
                    return new alvosActions.UpdateAlvoSuccessAction();
                }),
                catchError(err => {
                    return of(new alvosActions.ErrorAlvosActions);
                })
            )
        })
    );

    @Effect()
    updateSuccessAction$ = this.actions$.pipe(
        ofType<alvosActions.UpdateAlvoAction>(alvosActions.AlvosActions.UPDATE_ALVO_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ => {
                this.router.navigate(['/alvos']);
            });
            return empty();
        })
    );

    @Effect()
    deleteAlvoAction$ = this.actions$.pipe(
        ofType<alvosActions.DeleteAlvoAction>(alvosActions.AlvosActions.DELETE_ALVO),
        map(action => action.payload),
        mergeMap(alvo => {
            const preConfirm = () => {
                return this.alvosService.delete(alvo.id).pipe(
                    take(1)
                ).toPromise();
            }
            return this.sweetAlertObservable.confirm({
                title: 'Atenção!',
                text: 'Você deseja mesmo deletar o alvo ' + alvo.nomePopular + ' ?',
                preConfirm: preConfirm
            }).pipe(
                mergeMap(_ => {
                    return this.sweetAlertObservable.success({
                        type: 'success',
                        title: 'Sucesso!',
                        text: `Alvo ${alvo.nomePopular} deletado com sucesso`,
                    });
                }),
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 400) {
                        return this.sweetAlertObservable.error({
                            type: 'error',
                            title: 'Erro!',
                            text: err.error.message,
                        });
                    }
                    return empty();
                }),
                switchMap(_ => {
                    return [
                        new alvosActions.DeleteAlvoSuccessAction(),
                        new alvosActions.ListAlvosAction({
                            page: 0,
                            maxRows: 10,
                            buscaPor: ''
                        })
                    ]
                }),
            )
        })
    );
}