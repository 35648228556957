import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  id = this.route.snapshot.params['id'];
  
  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }
}
