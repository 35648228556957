import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImportacoesRoutingModule } from './importacoes-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
import { CadastroComponent } from './cadastro/cadastro.component';
import { ImportacoesComponent } from './importacoes/importacoes.component';
import { ImportacoesStoreModule } from 'src/app/store/importacoes/importacoes-store.module';

@NgModule({
  declarations: [CadastroComponent, ImportacoesComponent],
  imports: [
    CommonModule,
    ImportacoesRoutingModule,
    NgxDatatableModule,
    SharedModule,
    ImportacoesStoreModule
  ],
  bootstrap: [ImportacoesComponent]
})
export class ImportacoesModule { }
