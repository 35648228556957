import * as cidadeState from './cidade.state';
import * as actions from './cidade.actions';

export function cidadeReducer(
    state: cidadeState.CidadeState = cidadeState.initialState,
    action: actions.TypesActions
): cidadeState.CidadeState {

    switch (action.type) {
        case actions.CidadeActions.LIST_CIDADE:
            return {
                ...state,
                isLoading: true,
                list: {
                    ...state.list,
                    cidades: []
                }
            }
        case actions.CidadeActions.LIST_CIDADE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    cidades: action.payload.cidades,
                    total: action.payload.total
                }
            }
        case actions.CidadeActions.LIST_CIDADE_ERROR:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    total: 0
                }
            }
        default:
            return state;
    }

}