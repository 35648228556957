import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';

import { TiposAlvosRoutingModule } from './tipos-alvos-routing.module';
import { TiposAlvosComponent } from './tipos-alvos/tipos-alvos.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TipoalvoStoreModule } from 'src/app/store/tipo-alvo/tipo-alvo-store.module';
import { CadastroComponent } from './cadastro/cadastro.component';

@NgModule({
  declarations: [TiposAlvosComponent, CadastroComponent],
  imports: [
    TiposAlvosRoutingModule,
    NgxDatatableModule,
    NgSelectModule,
    SharedModule,
    TipoalvoStoreModule
  ],
  providers:[],
  bootstrap: [TiposAlvosComponent]
})

export class TiposAlvosModule { }