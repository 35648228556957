import { Cidade } from 'src/app/model/cidade';

export const STATE_NAME = 'cidade';

export interface CidadeState {
    isLoading: boolean;
    list: {
        cidades: Cidade[],
        total: number
    }
    cidade: Cidade;

}

export const initialState: CidadeState = {
    isLoading: false,
    list: {
        cidades: [],
        total: 0
    },
    cidade: null
}

export const selectIsLoading = (state: CidadeState) => state.isLoading;
export const selectCidade = (state: CidadeState) => state.cidade;
export const selectListCidade = (state: CidadeState) => state.list.cidades;
export const selectTotal = (state: CidadeState) => state.list.total;
