import { Injectable } from "@angular/core";

import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../app.reducer';
import * as actions from './cidade.actions';
import * as state from './cidade.state';

@Injectable()
export class CidadeStoreService {

  constructor(private store: Store<AppState>) { }

  private cidadeState = createFeatureSelector<state.CidadeState>(state.STATE_NAME);
  private isLoading = createSelector(this.cidadeState, state.selectIsLoading);
  private cidades = createSelector(this.cidadeState, state.selectListCidade);
  private total = createSelector(this.cidadeState, state.selectTotal);


  getIsLoading() {
    return this.store.select(this.isLoading);
  }

  getCidades() {
    return this.store.select(this.cidades);
  }

  getTotal() {
    return this.store.select(this.total);
  }

  dispatchListCidadeAction(page: number, maxRows: number, buscaPor: string) {
    this.store.dispatch(new actions.ListCidadeAction({
      maxRows: maxRows,
      page: page,
      buscaPor: buscaPor
    }));
  }

}