import { NgRxAction } from '../NgRxAction';
import { ProdutoInterface } from './produtos.service';

export enum ProdutoActions {
    LIST_PRODUTO = '[PRODUTO] - List PRODUTO - ',
    LIST_PRODUTO_SUCCESS = '[PRODUTO] - List PRODUTO Success - ',
    LIST_PRODUTO_ERROR = '[PRODUTO] - Error List PRODUTO - ',
    LOAD_PRODUTO = '[PRODUTO] - Load Produto',
    LOAD_PRODUTO_SUCCESS = '[PRODUTO] - Load Produto Success',
    LOAD_PRODUTO_ERROR = '[PRODUTO] - Load Produto Error',
    SAVE_PRODUTO = '[PRODUTO] - Save Produto',
    SAVE_PRODUTO_SUCCESS = '[PRODUTO] - Save Produto Success',
    SAVE_PRODUTO_ERROR = '[PRODUTO] - Save Produto Error',
    UPDATE_PRODUTO = '[PRODUTO] - Update Produto',
    UPDATE_PRODUTO_SUCCESS = '[PRODUTO] - Update Produto Success',
    DELETE_PRODUTO = '[PRODUTO] Delete Produto',
    DELETE_PRODUTO_ERROR = '[PRODUTO] Delete Produto Error',
    DELETE_PRODUTO_SUCCESS = '[PRODUTO] Delete Produto Success',
    PRODUTO_ERROR = '[PRODUTO] Error Produto'
}

export class ListProdutoAction extends NgRxAction<any> {
    readonly type = ProdutoActions.LIST_PRODUTO;
}

export class ListProdutoSuccessAction extends NgRxAction<any> {
    readonly type = ProdutoActions.LIST_PRODUTO_SUCCESS;
}

export class ListProdutoErrorAction extends NgRxAction<any> {
    readonly type = ProdutoActions.LIST_PRODUTO_ERROR;
}

export class LoadProdutoAction extends NgRxAction<any>{
    readonly type = ProdutoActions.LOAD_PRODUTO;
}

export class LoadProdutoSuccessAction extends NgRxAction<any>{
    readonly type = ProdutoActions.LOAD_PRODUTO_SUCCESS;
}

export class LoadProdutoErrorAction extends NgRxAction<any>{
    readonly type = ProdutoActions.LOAD_PRODUTO_ERROR;
}

export class SaveProdutoAction extends NgRxAction<ProdutoInterface>{
    readonly type = ProdutoActions.SAVE_PRODUTO;
}

export class SaveProdutoSuccessAction extends NgRxAction<any>{
    readonly type = ProdutoActions.SAVE_PRODUTO_SUCCESS;
}

export class SaveProdutoErrorAction extends NgRxAction<any>{
    readonly type = ProdutoActions.SAVE_PRODUTO_ERROR;
}
export class UpdateProdutoAction extends NgRxAction<ProdutoInterface>{
    readonly type = ProdutoActions.UPDATE_PRODUTO;
}

export class UpdateProdutoSuccessAction extends NgRxAction<any>{
    readonly type = ProdutoActions.UPDATE_PRODUTO_SUCCESS;
}

export class ErrorProdutoActions extends NgRxAction<any>{
    readonly type = ProdutoActions.PRODUTO_ERROR;
}

export class DeleteProdutoAction extends NgRxAction<any>{
    readonly type = ProdutoActions.DELETE_PRODUTO;
}

export class DeleteProdutoSuccessAction extends NgRxAction<any>{
    readonly type = ProdutoActions.DELETE_PRODUTO_SUCCESS;
}

export class DeleteProdutoErrorAction extends NgRxAction<any>{
    readonly type = ProdutoActions.DELETE_PRODUTO_ERROR;
}


export type TypesActions =
    ListProdutoAction
    | ListProdutoSuccessAction
    | ListProdutoErrorAction
    | LoadProdutoAction
    | LoadProdutoSuccessAction
    | LoadProdutoErrorAction
    | SaveProdutoAction
    | SaveProdutoSuccessAction
    | SaveProdutoErrorAction
    | UpdateProdutoAction
    | UpdateProdutoSuccessAction
    | ErrorProdutoActions
    | DeleteProdutoAction
    | DeleteProdutoSuccessAction
    | DeleteProdutoErrorAction