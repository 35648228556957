import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_NAME } from './infestacoes.state';
import { infestacoesReducer } from './infestacoes.reducer';
import { InfestacoesEffects } from './infestacoes.effects';
import { InfestacoesStoreService } from './infestacoes-store.service';
import { InfestacoesService } from './infestacoes.service';

@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(STATE_NAME, infestacoesReducer),
      EffectsModule.forFeature([InfestacoesEffects]),
  ],
  exports: [
      StoreModule,
      EffectsModule,
      HttpClientModule
  ],
  providers: [
      InfestacoesStoreService,
      InfestacoesService
  ]
})
export class InfestacoesStoreModule { }