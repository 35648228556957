import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';

import { AlvosRoutingModule } from './alvos-routing.module';
import { AlvosComponent } from './alvos/alvos.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AlvosStoreModule } from 'src/app/store/alvos/alvos-store.module';
import { CadastroComponent } from './cadastro/cadastro.component';
import { RegioesalvoStoreModule } from 'src/app/store/regioesalvo/regioesalvo-store.module';
import { ProdutosStoreModule } from 'src/app/store/produtos/produtos-store.module';
import { TipoalvoStoreModule } from 'src/app/store/tipo-alvo/tipo-alvo-store.module';

@NgModule({
  declarations: [AlvosComponent, CadastroComponent],
  imports: [
    AlvosRoutingModule,
    NgxDatatableModule,
    NgSelectModule,
    SharedModule,
    AlvosStoreModule,
    RegioesalvoStoreModule,
    ProdutosStoreModule,
    TipoalvoStoreModule
  ],
  providers:[],
  bootstrap: [AlvosComponent]
})

export class AlvosModule { }