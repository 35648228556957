import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';

import { UsuariosRoutingModule } from './usuarios-routing.module';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UsuarioStoreModule } from 'src/app/store/usuario/usuario-store.module';
import { CadastroComponent } from './cadastro/cadastro.component';
import { AdminStoreModule } from 'src/app/store/admin/admin-store.module';
import { CidadeStoreModule } from 'src/app/store/cidade/cidade-store.module';

@NgModule({
  declarations: [UsuariosComponent, CadastroComponent],
  imports: [
    UsuariosRoutingModule,
    NgxDatatableModule,
    NgSelectModule,
    SharedModule,
    UsuarioStoreModule,
    CidadeStoreModule
  ],
  providers:[],
  bootstrap: [UsuariosComponent]
})

export class UsuariosModule { }