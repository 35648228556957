import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EsqueciSenhaRoutingModule } from './esqueci-senha-routing.module';
import { EsqueciSenhaComponent } from './esqueci-senha.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [EsqueciSenhaComponent],
  imports: [
    EsqueciSenhaRoutingModule,
    SweetAlert2Module,
    CommonModule,
    SharedModule
  ]
})

export class EsqueciSenhaModule { }
