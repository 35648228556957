import swal, { SweetAlertType } from 'sweetalert2';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';

type SyncOrAsync<T> = T | Promise<T>;

interface IOptions {
    title: string,
    text: string,
    type?: SweetAlertType
}

interface IOptionsConfirm extends IOptions {
    preConfirm: (inputValue: any) => SyncOrAsync<any | void>
}

@Injectable()

export class SweetAlertObservable {

    confirm(options: IOptionsConfirm): Observable<any> {
        return Observable.create(observer => {
            swal({
                title: options.title || 'Atenção!',
                text: options.text,
                type: options.type || 'warning',
                cancelButtonText: "Cancelar",
                showCancelButton: true,
                confirmButtonColor: '#E01F16',
                confirmButtonText: 'Deletar',
                preConfirm: options.preConfirm,
                reverseButtons: true
            }).then((response) => {
                if(response.dismiss) {
                    return observer.complete();
                }
                observer.next(response.value);
                observer.complete();
            }).catch(function(err){
                observer.error(err);
                observer.complete();
            });
        })
    }

    success(options: IOptions): Observable<void>  {
        return Observable.create(observe => {
            swal(options.title, options.text, options.type)
            .then(_ => {
                observe.next(null),
                observe.complete();
            })
        })
    }

    error(options: IOptions): Observable<void>  {
        return Observable.create(observe => {
            swal(options.title, options.text, options.type)
            .then(_ => {
                observe.next(null),
                observe.complete();
            })
        })
    }
}