import { Importacao } from 'src/app/model/importacao';

export const STATE_NAME = 'importacoes';

export interface ImportacoesState {
    isLoading: boolean;
    list: {
        importacoes: Importacao[],
        total: number
    }
    importacoes: Importacao;
    error: {
        show: boolean,
        message: string
    }
}

export const initialState: ImportacoesState = {
    isLoading: false,
    list: {
        importacoes: [],
        total: 0
    },
    importacoes: null,
    error: {
        show: false,
        message: null
    }
}

export const selectIsLoading = (state: ImportacoesState) => state.isLoading;
export const selectImportacoes = (state: ImportacoesState) => state.importacoes;
export const selectListImportacoes = (state: ImportacoesState) => state.list.importacoes;
export const selectTotal = (state: ImportacoesState) => state.list.total;
export const selectError = (state: ImportacoesState) => state.error
