import * as regioesAlvoState from './regioesalvo.state';
import * as actions from './regioesalvo.actions';

export function regioesAlvoReducer(
    state: regioesAlvoState.RegioesalvoState = regioesAlvoState.initialState,
    action: actions.TypesActions
): regioesAlvoState.RegioesalvoState {

    switch (action.type) {
        case actions.RegioesAlvoActions.LIST_REGIOESALVO:
            return {
                ...state,
                isLoading: true,
                list: {
                    ...state.list,
                    regioesAlvoList: []
                },
                error: regioesAlvoState.initialState.error
            }
        case actions.RegioesAlvoActions.LIST_REGIOESALVO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    regioesAlvoList: action.payload.regioesAlvoList,
                    total: action.payload.total
                }
            }
        case actions.RegioesAlvoActions.LIST_REGIOESALVO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload,
                },
                list: {
                    ...state.list,
                    total: 0
                }
            }
        case actions.RegioesAlvoActions.LOAD_REGIOESALVO:
            return {
                ...state,
                isLoading: true,
                regioesAlvo: null
            }
        case actions.RegioesAlvoActions.LOAD_REGIOESALVO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                regioesAlvo: action.payload
            }
        case actions.RegioesAlvoActions.LOAD_REGIOESALVO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.RegioesAlvoActions.SAVE_REGIOESALVO:
            return {
                ...state,
                isLoading: true,
                error: regioesAlvoState.initialState.error
            }
        case actions.RegioesAlvoActions.SAVE_REGIOESALVO_SUCCESS:
            return {
                ...state,
                isLoading: false
            }

        case actions.RegioesAlvoActions.SAVE_REGIOESALVO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }

        case actions.RegioesAlvoActions.UPDATE_REGIOESALVO:
            return {
                ...state,
                isLoading: true,
                error: regioesAlvoState.initialState.error
            }
        case actions.RegioesAlvoActions.UPDATE_REGIOESALVO_SUCCESS:
            return {
                ...state,
                isLoading: false
            }

        case actions.RegioesAlvoActions.UPDATE_REGIOESALVO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }

        case actions.RegioesAlvoActions.DELETE_REGIOESALVO:
            return {
                ...state,
                isLoading: false,
                regioesAlvo: null
            }
        default:
            return state;
    }

}