import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';

import { SweetAlertObservable } from './SweetAlertObservable';
import { AuthInterceptor } from '../store/auth/auth.interceptor';

@NgModule({
  declarations: [],
  exports: [
    ReactiveFormsModule,
    NgxDatatableModule,
    HttpClientModule,
    CommonModule,
    NgxMaskModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    SweetAlertObservable
  ]
})

export class SharedModule {}