import * as alvosState from './alvos.state';
import * as actions from './alvos.actions';

export function alvosReducer(
    state: alvosState.AlvosState = alvosState.initialState,
    action: actions.TypesActions
): alvosState.AlvosState {

    switch (action.type) {
        case actions.AlvosActions.LIST_ALVOS:
            return {
                ...state,
                isLoading: true,
                list: {
                    ...state.list,
                    alvos: []
                },
                error: alvosState.initialState.error
            }
        case actions.AlvosActions.LIST_ALVOS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    alvos: action.payload.alvos,
                    total: action.payload.total
                }
            }
        case actions.AlvosActions.LIST_ALVOS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload,
                },
                list: {
                    ...state.list,
                    total: 0
                }
            }
        case actions.AlvosActions.LOAD_ALVO:
            return {
                ...state,
                isLoading: true,
                alvo: null
            }
        case actions.AlvosActions.LOAD_ALVO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                alvo: action.payload
            }
        case actions.AlvosActions.LOAD_ALVO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.AlvosActions.SAVE_ALVO:
            return {
                ...state,
                isLoading: true,
                error: alvosState.initialState.error
            }
        case actions.AlvosActions.SAVE_ALVO_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case actions.AlvosActions.SAVE_ALVO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload,
                }
            }    
        case actions.AlvosActions.UPDATE_ALVO:
            return {
                ...state,
                isLoading: true,
                error: alvosState.initialState.error
            }
        case actions.AlvosActions.UPDATE_ALVO_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case actions.AlvosActions.DELETE_ALVO:
            return {
                ...state,
                isLoading: false,
                alvo: null
            }

        default:
            return state;
    }

}