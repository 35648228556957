import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';

import { ProdutosRoutingModule } from './produtos-routing.module';
import { ProdutosComponent } from './produtos/produtos.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProdutosStoreModule } from 'src/app/store/produtos/produtos-store.module';
import { CadastroComponent } from './cadastro/cadastro.component';

@NgModule({
  declarations: [ProdutosComponent, CadastroComponent],
  imports: [
    ProdutosRoutingModule,
    NgxDatatableModule,
    NgSelectModule,
    SharedModule,
    ProdutosStoreModule
  ],
  providers:[],
  bootstrap: [ProdutosComponent]
})

export class ProdutosModule { }