import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

export interface RevendaInterface{
  id: number;
  nome: string;
  razaoSocial: string;
  proprietarioConta: string;
  latitude: string;
  longitude: string;
  cep: number;
  endereco: string;
  numero: number;
  bairro: string;
  complemento: string;
  municipio: string;
  uf: string;
  telefone: number;
}

@Injectable()
export class RevendaService {

  private api: string = `${environment.api}/revendas`;

  constructor(
    private http: HttpClient
  ) { }



  list(payload:{
    page: number,
    maxRows: number,
    buscaPor: string
  }) {
    return this.http.get(`${this.api}/filter?page=${payload.page}&max=${payload.maxRows}&search=${payload.buscaPor}`);
  }

  get(id: number) {
    return this.http.get(`${this.api}/${id}`);
  }

  save(body: RevendaInterface){
    return this.http.post(`${this.api}`, {
      nome: body.nome,
      razaoSocial: body.razaoSocial,
      proprietarioConta: body.proprietarioConta,
      latitude: Number(body.latitude),
      longitude: Number(body.longitude),
      cep: Number(body.cep),
      endereco: body.endereco,
      numero: Number(body.numero),
      bairro: body.bairro,
      complemento: body.complemento,
      municipio: body.municipio,
      uf: body.uf,
      telefone: Number(body.telefone)
    });
  }

  update(body: RevendaInterface) {
    return this.http.put(`${this.api}/${body.id}`, {
      nome: body.nome,
      razaoSocial: body.razaoSocial,
      proprietarioConta: body.proprietarioConta,
      latitude: body.latitude,
      longitude: body.longitude,
      cep: (body.cep),
      endereco: body.endereco,
      numero: (body.numero),
      bairro: body.bairro,
      complemento: body.complemento,
      municipio: body.municipio,
      uf: body.uf,
      telefone: (body.telefone)
    });
  }

  delete(id: number) {
    return this.http.delete(`${this.api}/${id}`);
  }

}