import { Injectable } from "@angular/core";

import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../app.reducer';
import * as actions from './top-trinta.actions';
import * as state from './top-trinta.state';
import { TopTrinta } from 'src/app/model/top-trinta';

@Injectable()
export class ToptrintaStoreService {

  constructor(private store: Store<AppState>) { }

  private toptrintaState = createFeatureSelector<state.ToptrintaState>(state.STATE_NAME);
  private isLoading = createSelector(this.toptrintaState, state.selectIsLoading);
  private toptrinta = createSelector(this.toptrintaState, state.selectToptrinta);
  private topTrintaList = createSelector(this.toptrintaState, state.selectListToptrinta);
  private total = createSelector(this.toptrintaState, state.selectTotal);


  getIsLoading() {
    return this.store.select(this.isLoading);
  }

  getToptrinta() {
    return this.store.select(this.toptrinta);
  }

  getTopTrintaList(){
    return this.store.select(this.topTrintaList);
  }

  getTotal() {
    return this.store.select(this.total);
  }

  dispatchListToptrintaAction(page: number, maxRows: number, buscaPor: string) {
    this.store.dispatch(new actions.ListToptrintaAction({
      maxRows: maxRows,
      page: page,
      buscaPor: buscaPor
    }));
  }

  dispatchDeleteTopTrintaAction(topTrinta: TopTrinta){
    this.store.dispatch(new actions.DeleteToptrintaAction(topTrinta));
  }

}