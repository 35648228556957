import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { TopTrintaComponent } from './top-trinta/top-trinta.component';

const routes: Routes = [
  {path: '', component: TopTrintaComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes), 
    NgxDatatableModule
  ],
  exports: [
    RouterModule
  ]
})

export class TopTrintaRoutingModule { }