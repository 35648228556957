import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Infestacao } from 'src/app/model/infestacao';
import { InfestacoesStoreService } from 'src/app/store/infestacoes/infestacoes-store.service';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-infestacoes',
  templateUrl: './infestacoes.component.html',
  styleUrls: ['./infestacoes.component.scss']
})

export class InfestacoesComponent implements OnInit {

  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  reorderable: boolean = true;
  error$: Observable<any>;
  rows$: Observable<Infestacao[]>;
  maxRows: number = 50;
  total$: Observable<number>;
  isLoading = false;
  link = '';
  rowsLoading = [];

  private api: string = `${environment.api}/relatorios/export`;
  private apiPDF: string = `${environment.api}/relatorios/pdf`;

  constructor(
    private infestacaoStoreService: InfestacoesStoreService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.isLoading$ = this.infestacaoStoreService.getIsLoading();
    this.dispatchLoadInfestacoesAction();
    this.rows$ = this.infestacaoStoreService.getInfestacoes();
    this.total$ = this.infestacaoStoreService.getTotal();
  }

  onChangeMax(event){
    this.maxRows = parseInt(event.target.value);
    this.dispatchLoadInfestacoesAction();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.buscaPor = val;
    this.dispatchLoadInfestacoesAction();
  }

  private dispatchLoadInfestacoesAction(){
    this.infestacaoStoreService.dispatchListInfestacoesAction(this.page, this.maxRows, this.buscaPor);
  }

  onClickDelete(alvo) {
    this.infestacaoStoreService.dispatchDeleteInfestacaoAction(alvo);
  }

  onClickGeraCSVInfestacoes() {
    return this.http.get<any>(this.api).subscribe(
      res => {
        swal('', res.message, 'info');
      },
      err => {
        swal('Opss...,', 'Ocorreu um erro ao exportar arquivo, tente novamente mais tarde!', 'error');
      }
    );
  }

  onChangePage(pageInfo) {
    this.page = pageInfo.offset;
    this.dispatchLoadInfestacoesAction();
  }

  getLink(value){
    return value;
  }

  getRowClass = (row) => {
    if (row.deleted) {
      return {
        'row-color': true
      };
    }
 }

 gerarPDF(row) {
  this.rowsLoading.push(row.id);
  this.http.get<any>(`${this.apiPDF}/${row.id}`).pipe(
    finalize(() => this.rowsLoading.splice(this.rowsLoading.indexOf(row.id), 1))
  ).subscribe(
    (res) => {
      Swal.fire({
        title: 'Arquivo',
        html:
        `<a class="text-primary" target="_blank" href="${res.message}">Clique aqui para abrir seu pdf.</a>`,
        type: 'success',
      });
    },
    (err) => {
      swal('', err.message, 'error');
    }
  );
 }

 isLoadingRow(id) {
    return this.rowsLoading.indexOf(id) >= 0;
 }
}
