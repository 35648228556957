import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_NAME } from './revenda.state';
import { revendaReducer } from './revenda.reducer';
import { RevendaEffects } from './revenda.effects';
import { RevendaStoreService } from './revenda-store.service';
import { RevendaService } from './revenda.service';

@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(STATE_NAME, revendaReducer),
      EffectsModule.forFeature([RevendaEffects]),
  ],
  exports: [
      StoreModule,
      EffectsModule,
      HttpClientModule
  ],
  providers: [
      RevendaStoreService,
      RevendaService
  ]
})
export class RevendaStoreModule { }