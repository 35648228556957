import { NgRxAction } from '../NgRxAction';
import { AdminInterface } from './admin.service';

export enum AdminActions {
    LIST_ADMIN = '[ADMIN] - List ADMIN - ',
    LIST_ADMIN_SUCCESS = '[ADMIN] - List ADMIN Success - ',
    LIST_ADMIN_ERROR = '[ADMIN] - Error List ADMIN - ',
    LOAD_ADMIN = '[ADMIN] - Load Admin',
    LOAD_ADMIN_SUCCESS = '[ADMIN] - Load Admin Success',
    LOAD_ADMIN_ERROR = '[ADMIN] - Load Admin Error',
    SAVE_ADMIN = '[ADMIN] - Save Admin',
    SAVE_ADMIN_SUCCESS = '[ADMIN] - Save Admin Success',
    SAVE_ADMIN_ERROR = '[ADMIN] - Save Admin Error',
    UPDATE_ADMIN = '[ADMIN] - Update Admin',
    UPDATE_ADMIN_SUCCESS = '[ADMIN] - Update Admin Success',
    UPDATE_ADMIN_ERROR = '[ADMIN] - Update Admin Error',
    DELETE_ADMIN = '[ADMIN] Delete Admin',
    DELETE_ADMIN_ERROR = '[ADMIN] Delete Admin Error',
    DELETE_ADMIN_SUCCESS = '[ADMIN] Delete Admin Success',
    ADMIN_ERROR = '[ADMIN] Error Admin',
    LOAD_ALTERAR_SENHA = '[ADMIN] - Load Alterar Senha',
    LOAD_ALTERAR_SENHA_SUCCESS = '[ADMIN] - Load Alterar Senha Success',
    LOAD_ALTERAR_SENHA_ERROR = '[ADMIN] - Load Alterar Senha Error',

}

export class ListAdminAction extends NgRxAction<any> {
    readonly type = AdminActions.LIST_ADMIN;
}

export class ListAdminSuccessAction extends NgRxAction<any> {
    readonly type = AdminActions.LIST_ADMIN_SUCCESS;
}

export class ListAdminErrorAction extends NgRxAction<any> {
    readonly type = AdminActions.LIST_ADMIN_ERROR;
}

export class LoadAdminAction extends NgRxAction<any>{
    readonly type = AdminActions.LOAD_ADMIN;
}

export class LoadAdminSuccessAction extends NgRxAction<any>{
    readonly type = AdminActions.LOAD_ADMIN_SUCCESS;
}

export class LoadAdminErrorAction extends NgRxAction<any>{
    readonly type = AdminActions.LOAD_ADMIN_ERROR;
}

export class SaveAdminAction extends NgRxAction<AdminInterface>{
    readonly type = AdminActions.SAVE_ADMIN;
}

export class SaveAdminSuccessAction extends NgRxAction<any>{
    readonly type = AdminActions.SAVE_ADMIN_SUCCESS;
}

export class SaveAdminErrorAction extends NgRxAction<any>{
    readonly type = AdminActions.SAVE_ADMIN_ERROR;
}

export class UpdateAdminAction extends NgRxAction<AdminInterface>{
    readonly type = AdminActions.UPDATE_ADMIN;
}

export class UpdateAdminSuccessAction extends NgRxAction<any>{
    readonly type = AdminActions.UPDATE_ADMIN_SUCCESS;
}

export class UpdateAdminErrorAction extends NgRxAction<any>{
    readonly type = AdminActions.UPDATE_ADMIN_ERROR;
}

export class ErrorAdminActions extends NgRxAction<any>{
    readonly type = AdminActions.ADMIN_ERROR;
}

export class DeleteAdminAction extends NgRxAction<any>{
    readonly type = AdminActions.DELETE_ADMIN;
}

export class DeleteAdminSuccessAction extends NgRxAction<any>{
    readonly type = AdminActions.DELETE_ADMIN_SUCCESS;
}

export class DeleteAdminErrorAction extends NgRxAction<any>{
    readonly type = AdminActions.DELETE_ADMIN_ERROR;
}
export class LoadAlterarSenhaAction extends NgRxAction<any>{
    readonly type = AdminActions.LOAD_ALTERAR_SENHA;
}

export class LoadAlterarSenhaSuccessAction extends NgRxAction<any>{
    readonly type = AdminActions.LOAD_ALTERAR_SENHA_SUCCESS;
}

export class LoadAlterarSenhaErrorAction extends NgRxAction<any>{
    readonly type = AdminActions.LOAD_ALTERAR_SENHA_ERROR;
}


export type TypesActions =
    ListAdminAction
    | ListAdminSuccessAction
    | ListAdminErrorAction
    | LoadAdminAction
    | LoadAdminSuccessAction
    | LoadAdminErrorAction
    | SaveAdminAction
    | SaveAdminSuccessAction
    | SaveAdminErrorAction
    | UpdateAdminAction
    | UpdateAdminSuccessAction
    | UpdateAdminErrorAction
    | ErrorAdminActions
    | DeleteAdminAction
    | DeleteAdminSuccessAction
    | DeleteAdminErrorAction
    | ListAdminErrorAction
    | LoadAlterarSenhaAction
    | LoadAlterarSenhaSuccessAction
    | LoadAlterarSenhaErrorAction
