import { TopTrinta } from 'src/app/model/top-trinta';

export const STATE_NAME = 'toptrinta';

export interface ToptrintaState {
    isLoading: boolean;
    list: {
        topTrintaList: TopTrinta[],
        total: number
    };
    toptrinta: TopTrinta;
    error: {
        show: boolean,
        message: string
    }

}

export const initialState: ToptrintaState = {
    isLoading: false,
    list: {
        topTrintaList: [],
        total: 0
    },
    toptrinta: null,
    error: {
        show: false,
        message: null
    }
}

export const selectIsLoading = (state: ToptrintaState) => state.isLoading;
export const selectToptrinta = (state: ToptrintaState) => state.toptrinta;
export const selectListToptrinta = (state: ToptrintaState) => state.list.topTrintaList;
export const selectError = (state: ToptrintaState) => state.error;
export const selectTotal = (state: ToptrintaState) => state.list.total;
