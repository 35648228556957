import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import swal from 'sweetalert2';
import { AuthStoreService } from 'src/app/store/auth/auth-store.service';

declare var $: any;

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.scss']
})
export class EsqueciSenhaComponent implements OnInit, OnDestroy {

  form: FormGroup

  isLoading$: Observable<boolean>;
 
  constructor(
    private fb: FormBuilder,
    private authStoreService: AuthStoreService
  ) { }

  ngOnInit() {
    this.createForm();
    
    $('#form-esqueci-senha').validate({
      submitHandler: () => {
        this.redefinirSenha();
      }      
    });
  }

  ngOnDestroy(): void {
    this.authStoreService.dispatchClearErrorAction();
  }

  redefinirSenha(){
    const values = this.form.value;
    const email = values.email;
    this.authStoreService.dispatchEsqueciSenhaAction(email)
    localStorage.clear();
  }

  private createForm() {
    const email = localStorage.getItem('email');  
    this.form = this.fb.group({
      'email': [email, [Validators.required,Validators.email]],
      'remember': [false]
    });
  }
}
