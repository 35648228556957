import { Usuario } from './../../model/usuario';
import { Perfil } from 'src/app/model/perfil';

export const STATE_NAME = 'usuario';

export interface UsuarioState {
    isLoading: boolean;
    list: {
        usuarios: Usuario[],
        total: number
    };
    usuario: Usuario;
    error: {
        show: boolean,
        message: string
    },
    perfis: Perfil[]
}

export const initialState: UsuarioState = {
    isLoading: false,
    list: {
        usuarios: [],
        total: 0
    },
    usuario: null,
    error: {
        show: false,
        message: null
    },
    perfis: []
}

export const selectUsuarios = (state: UsuarioState) => state.list.usuarios;
export const selectTotal = (state: UsuarioState) => state.list.total;
export const selectIsLoading = (state: UsuarioState) => state.isLoading;
export const selectUsuario = (state: UsuarioState) => state.usuario;
export const selectError = (state: UsuarioState) => state.error;
export const selectPerfis = (state: UsuarioState) => state.perfis