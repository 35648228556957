import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';


export interface AdminInterface{
  id: number;
  nome: string;
  username: string;
  perfil: {
      codigo: string;
  };
  password: string;
}

@Injectable()
export class AdminService {

  private api: string = `${environment.api}/usuarios`;

  constructor(
    private http: HttpClient
  ) { }

  list(payload : {
    page: number,
    maxRows: number,
    buscaPor: string
  }) {
      return this.http.get(`${this.api}/filter/admin?page=${payload.page}&max=${payload.maxRows}&search=${payload.buscaPor}`);
  }

  get(id: number) {
    return this.http.get(`${this.api}/${id}`);
  }

  save(body: AdminInterface) {
    return this.http.post(this.api, {
      id: body.id,
      nome: body.nome,
      username: body.username,
      perfil: {
        codigo: 'admin' // FIXO PQ SO SERA CADASTRADO ADM PELA WEB
      },
      password: body.password,
    });
  }

  update(body: AdminInterface) {
    return this.http.put(`${this.api}/${body.id}`, {
      nome: body.nome,
      username: body.username,
      perfil: {
        id: 1 // 1 = ADMIN
      },
      password: body.password,
    });
  }
  
  delete(id: number) {
    return this.http.delete(`${this.api}/${id}`);
  }

  alterarSenha(senha: string, novaSenha: string){
    return this.http.patch(`${this.api}/senha/alterar`, {
      senha: senha,
      novaSenha: novaSenha
    })
  }

}