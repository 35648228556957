import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_NAME } from './regioesalvo.state';
import { regioesAlvoReducer } from './regioesalvo.reducer';
import { RegioesAlvoEffects } from './regioesalvo.effects';
import { RegioesalvoStoreService } from './regioesalvo-store.service';
import { RegioesalvoService } from './regioesalvo.service';

@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(STATE_NAME, regioesAlvoReducer),
      EffectsModule.forFeature([RegioesAlvoEffects]),
  ],
  exports: [
      StoreModule,
      EffectsModule,
      HttpClientModule
  ],
  providers: [
      RegioesalvoStoreService,
      RegioesalvoService
  ]
})
export class RegioesalvoStoreModule { }