import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Usuario } from 'src/app/model/usuario';
import { UsuarioStoreService } from 'src/app/store/usuario/usuario-store.service';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})

export class UsuariosComponent implements OnInit {

  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  reorderable: boolean = true;
  error$: Observable<any>;
  rows$: Observable<Usuario[]>;
  maxRows: number = 50;
  total$: Observable<number>;

  private api: string = `${environment.api}/usuarios/export`;

  constructor(
    private usuarioStoreService: UsuarioStoreService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.isLoading$ = this.usuarioStoreService.getIsLoading();
    this.dispatchLoadUsuariosAction();
    this.rows$ = this.usuarioStoreService.getUsuarios();
    this.total$ = this.usuarioStoreService.getTotal();
  }

  private dispatchLoadUsuariosAction() {
    this.usuarioStoreService.dispatchListUsuarioAction(this.page, this.maxRows, this.buscaPor);
  }

  onClickDelete(usuario) {
    this.usuarioStoreService.dispatchDeleteUsuarioAction(usuario);
  }

  onClickGeraCSVUsuario() {
    return this.http.get<any>(this.api).subscribe(
      res => {
        swal("", res.message, "info");
      },
      err => {
        swal("Opss...", "Ocorreu um erro ao exportar arquivo, tente novamente mais tarde!", "error");
      }
    );
  }

  onChangeMax(event){
    this.maxRows = parseInt(event.target.value);
    this.dispatchLoadUsuariosAction();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.buscaPor = val;
    this.page = 0;
    this.dispatchLoadUsuariosAction();
  }

  onChangePage(pageInfo) {
    this.page = pageInfo.offset;
    this.dispatchLoadUsuariosAction();
  }
}