import * as authState from './auth.state';
import * as actions from './auth.actions';

export function authReducer(
    state: authState.AuthState = authState.initialState,
    action: actions.TypesActions
): authState.AuthState {

    switch (action.type) {
        case actions.AuthActions.LOAD_LOGIN:
            return {
                ...state,
                userInfo: null,
                isLoading: true,
                error: {
                    show: false,
                    message: null
                }
            }
        case actions.AuthActions.LOAD_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userInfo: action.payload
            }
        case actions.AuthActions.LOAD_LOGIN_ERROR:
            return {
                ...state,
                error: {
                    show: true,
                    message: action.payload
                },
                isLoading: false,
                userInfo: null
            }
        case actions.AuthActions.ESQUECI_SENHA:
            return {
                ...state,
                isLoading: true,
                userInfo: null
            }
        case actions.AuthActions.ESQUECI_SENHA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userInfo: null
            }
        case actions.AuthActions.ESQUECI_SENHA_ERROR:
            return {
                ...state,
                error: {
                    show: true,
                    message: action.payload
                },
                isLoading: false,
                userInfo: null
            }
        case actions.AuthActions.REDEFINIR_SENHA:
            return {
                ...state,
                isLoading: true,
                userInfo: null
            }
        case actions.AuthActions.REDEFINIR_SENHA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userInfo: null
            }
        case actions.AuthActions.REDEFINIR_SENHA_ERROR:
            return {
                ...state,
                error: {
                    show: true,
                    message: action.payload
                },
                isLoading: false,
                userInfo: null
            }
        case actions.AuthActions.LOAD_CLEAR_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: false,
                    message: null
                }
            }
        default:
            return state;
    }

}