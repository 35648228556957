import { Infestacao } from 'src/app/model/infestacao';

export const STATE_NAME = 'infestacoes';

export interface InfestacoesState {
    isLoading: boolean;
    list: {
        infestacoes: Infestacao[],
        total: number
    };
    infestacao: Infestacao;
    error: {
        show: boolean,
        message: string
    }
}

export const initialState: InfestacoesState = {
    isLoading: false,
    list: {
        infestacoes: [],
        total: 0
    },
    infestacao: null,
    error: {
        show: false,
        message: null
    }
}

export const selectIsLoading = (state: InfestacoesState) => state.isLoading;
export const selectInfestacoes = (state: InfestacoesState) => state.infestacao;
export const selectListInfestacoes = (state: InfestacoesState) => state.list.infestacoes;
export const selectError = (state: InfestacoesState) => state.error;
export const selectTotal = (state: InfestacoesState) => state.list.total;