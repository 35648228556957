import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter } from 'rxjs/operators';
import swal from 'sweetalert2';
import { AdminStoreService } from 'src/app/store/admin/admin-store.service';

declare var $: any;

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})

export class AlterarSenhaComponent implements OnInit, OnDestroy {

  form: FormGroup

  constructor(
    private fb: FormBuilder,
    private adminStoreService: AdminStoreService
  ) {}

  ngOnInit() {
    this.createForm();
    $('#form-alterar-senha').validate({
      rules: {
        confirmNewPassword: {
          equalTo: "#novaSenha"
        },
        novaSenha:{
          minlength: 5
        }
      },
      submitHandler: () => {
        this.onSubmit();
      }      
    });

  }

  ngOnDestroy(): void {
  }

  private createForm() {
    this.form = this.fb.group({
      senha: ['', [Validators.required,Validators.minLength(6)]],
      novaSenha: [''],
    });
  }

  onSubmit(){
    this.adminStoreService.dispatchAlterarSenhaAction(this.form.value);   
  }
}