import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, filter, take, switchMap, debounceTime } from 'rxjs/operators';
import { of, empty } from 'rxjs';

import * as regioesAlvoActions from './regioesalvo.actions';
import { RegioesalvoService } from './regioesalvo.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SweetAlertObservable } from 'src/app/shared/SweetAlertObservable';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class RegioesAlvoEffects {

    constructor(
        private actions$: Actions,
        private router: Router,
        private regioesAlvoService: RegioesalvoService,
        private sweetAlertObservable: SweetAlertObservable
    ) { }

    @Effect()
    loadRegioesAlvoListAction$ = this.actions$.pipe(
        ofType<regioesAlvoActions.ListRegioesAlvoAction>(regioesAlvoActions.RegioesAlvoActions.LIST_REGIOESALVO),
        map(action => action.payload),
        debounceTime(800),
        mergeMap(payload => {
            return this.regioesAlvoService.list(payload)
            .pipe(
                map((response: any) => {
                    const {elements, total} = response;
                    return new regioesAlvoActions.ListRegioesAlvoSuccessAction({
                        total: total * payload.maxRows,
                        regioesAlvoList: elements
                    });
                }),
                catchError((err: any) => {
                    return of(new regioesAlvoActions.ListRegioesAlvoErrorAction(err.error.message));
                })
            );
        })
    );

    @Effect()
    loadRegioesAlvoAction$ = this.actions$.pipe(
        ofType<regioesAlvoActions.LoadRegioesAlvoAction>(regioesAlvoActions.RegioesAlvoActions.LOAD_REGIOESALVO),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.regioesAlvoService.get(payload)
                .pipe(
                    map((response: any) => response),
                    map(regioesAlvo =>{
                        return new regioesAlvoActions.LoadRegioesAlvoSuccessAction(regioesAlvo);
                    }),
                    catchError(err => {
                        return of(new regioesAlvoActions.LoadRegioesAlvoErrorAction(err.error.message));
                    })
                );
        })
    );

    @Effect()
    saveOrUpdateAction$ = this.actions$.pipe(
        ofType<regioesAlvoActions.SaveRegioesAlvoAction>(regioesAlvoActions.RegioesAlvoActions.SAVE_REGIOESALVO),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.regioesAlvoService.save(payload).pipe(
                map( _ => {
                    return new regioesAlvoActions.SaveRegioesAlvoSuccessAction();
                }),
                catchError((err: any) => {
                    return of(new regioesAlvoActions.SaveRegioesAlvoErrorAction(err.error.message));
                })
            )
        })
    );

    @Effect()
    saveOrUpdateSuccessAction$ = this.actions$.pipe(
        ofType<regioesAlvoActions.SaveRegioesAlvoAction>(regioesAlvoActions.RegioesAlvoActions.SAVE_REGIOESALVO_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ =>{
                this.router.navigate(['/regioes-alvos']);
            });
            return empty();
        })
    );

    @Effect()
    updateAction$ = this.actions$.pipe(
        ofType<regioesAlvoActions.UpdateRegioesAlvoAction>(regioesAlvoActions.RegioesAlvoActions.UPDATE_REGIOESALVO),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.regioesAlvoService.update(payload).pipe(
                map( _ => {
                    return new regioesAlvoActions.UpdateRegioesAlvoSuccessAction();
                }),
                catchError((err: any) => {
                    return of(new regioesAlvoActions.UpdateRegioesAlvoErrorAction(err.error.message));
                })
            )
        })
    );

    @Effect()
    updateSuccessAction$ = this.actions$.pipe(
        ofType<regioesAlvoActions.UpdateRegioesAlvoAction>(regioesAlvoActions.RegioesAlvoActions.UPDATE_REGIOESALVO_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ =>{
                this.router.navigate(['/regioes-alvos']);
            });
            return empty();
        })
    );

    @Effect()
    deleteRegioesAlvoAction$ = this.actions$.pipe(
        ofType<regioesAlvoActions.DeleteRegioesAlvoAction>(regioesAlvoActions.RegioesAlvoActions.DELETE_REGIOESALVO),
        map(action => action.payload),
        mergeMap(regioesAlvo => {
            const preConfirm = () => {
                return this.regioesAlvoService.delete(regioesAlvo.id).pipe(
                    take(1)
                ).toPromise();
            }
            return this.sweetAlertObservable.confirm({
                title: 'Atenção!',
                text: 'Você deseja mesmo deletar a Região ' + regioesAlvo.nome + ' ?',
                preConfirm: preConfirm
            }).pipe(
                mergeMap(_ => {
                    return this.sweetAlertObservable.success({
                        type: 'success',
                        title: 'Sucesso!',
                        text: `Região ${regioesAlvo.nome} deletada com sucesso`,
                    });
                }),
                catchError((err: HttpErrorResponse) => {
                    if(err.status === 400) {
                        return this.sweetAlertObservable.error({
                            type: 'error',
                            title: 'Erro!',
                            text: err.error.message,
                        });
                    }
                    return empty();
                }),
                switchMap(_ => {
                    return [
                       new regioesAlvoActions.DeleteRegioesAlvoSuccessAction(),
                       new regioesAlvoActions.ListRegioesAlvoAction({
                           page: 0,
                           maxRows: 10,
                           buscaPor: ''
                       })
                    ]
                }),
            )
        })
    );

}