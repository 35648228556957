import { Injectable } from "@angular/core";

import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../app.reducer';
import * as actions from './alvos.actions';
import * as state from './alvos.state';
import { Alvo } from 'src/app/model/alvo';
import { AlvoInterface } from './alvos.service';

@Injectable()
export class AlvosStoreService {

  constructor(private store: Store<AppState>) { }

  private alvosState = createFeatureSelector<state.AlvosState>(state.STATE_NAME);
  private isLoading = createSelector(this.alvosState, state.selectIsLoading);
  private alvo = createSelector(this.alvosState, state.selectAlvos);
  private alvos = createSelector(this.alvosState, state.selectListAlvos);
  private error = createSelector(this.alvosState, state.selectError);
  private total = createSelector(this.alvosState, state.selectTotal);


  getIsLoading() {
    return this.store.select(this.isLoading);
  }

  getAlvo() {
    return this.store.select(this.alvo);
  }

  getAlvos() {
    return this.store.select(this.alvos);
  }

  getError() {
    return this.store.select(this.error);
  }

  getTotal() {
    return this.store.select(this.total);
  }

  dispatchLoadAlvoAction(id: number) {
    this.store.dispatch(new actions.LoadAlvoAction(id));
  }

  dispatchListAlvosAction(page: number, maxRows: number, buscaPor: string) {
    this.store.dispatch(new actions.ListAlvosAction({
      page: page,
      maxRows: maxRows,
      buscaPor: buscaPor
    }));
  }

  dispatchSaveAction(payload: AlvoInterface) {
    if (payload.produtos != null && !payload.produtos[0]) {
      payload.produtos = null;
      this.store.dispatch(new actions.SaveAlvoAction(payload));
    } else {
      this.store.dispatch(new actions.SaveAlvoAction(payload));
    }
  }

  dispatchUpdateAction(payload: AlvoInterface) {
    if (payload.produtos != null && !payload.produtos[0]) {
      payload.produtos = null;
      this.store.dispatch(new actions.UpdateAlvoAction(payload));
    } else {
      this.store.dispatch(new actions.UpdateAlvoAction(payload));
    }
  }

  dispatchDeleteAlvosAction(alvo: Alvo) {
    this.store.dispatch(new actions.DeleteAlvoAction(alvo));
  }

}
