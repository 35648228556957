import { Component, OnInit } from '@angular/core';
import { AuthStoreService } from 'src/app/store/auth/auth-store.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  nomeUsuario$: string = '';

  constructor(
    private authStoreService: AuthStoreService
  ) {}

  ngOnInit() {
    this.authStoreService.getUserInfo().subscribe(userInfo => {
      if(userInfo != null) {
        this.nomeUsuario$ = userInfo.nome;
      }
    })
  }

  onClickLogout() {
    this.authStoreService.dispatchLogoutAction();
  }
}
