import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { RevendasComponent } from './revendas/revendas.component';
import { CadastroComponent } from './cadastro/cadastro.component';

const routes: Routes = [
  {path : '', component: RevendasComponent},
  {path : 'cadastro', component: CadastroComponent},
  {path : 'cadastro/:id', component: CadastroComponent}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule
  ]
})

export class RevendasRoutingModule { }