import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})

export class CadastroComponent implements OnInit {

  form: FormGroup;
  maxRowns: number = 10;
  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  textbreadcrumbs:string  = "Novo FAQ";

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loadSecoes();

    this.form = this.fb.group({
      id: [null],
      pergunta: ['', Validators.required],
      resposta: ['', Validators.required],
      sessao: {
        descricao: [],
        id: [],
      },
      sessaoId: [],
    });

    $('#form-faq').validate({
      submitHandler: () => {
        this.onClickSave();
      }      
    });

    const id = this.route.snapshot.params['id']

    if(id) {
      this.textbreadcrumbs = "Editar FAQ"
    }
  }
  
  loadSecoes() {
  }

  onClickSave() {
    const descricaoSessao = this.form.value.sessao.descricao;
    const idSessao = this.form.value.sessao.id;
    const status = this.form.status;

    if(descricaoSessao.length === 0 || idSessao.length === 0) {
      $( ".ng-select-container-sessao .ng-select-container" ).css("border-color", "#dc3545");
      $( ".label-obrigatorio" ).css("display", "block");
    }
  }
}
