import { messages } from 'src/environments/messages';

declare var $: any;

export class Init {
    static setValidatorDefaults() {
        /**
         * https://jqueryvalidation.org/documentation/
         */
        $.validator.setDefaults({
            messages: {
              required: messages.required,
                  remote: "Please fix this field.",
                  email: messages.email,
                  url: "Please enter a valid URL.",
                  date: "Please enter a valid date.",
                  dateISO: "Please enter a valid date (ISO).",
                  number: "Please enter a valid number.",
                  digits: "Please enter only digits.",
                  equalTo: messages.equalTo,
                  maxlength: $.validator.format( "Please enter no more than {0} characters." ),
                  minlength: messages.minlength,
                  rangelength: $.validator.format( "Please enter a value between {0} and {1} characters long." ),
                  range: $.validator.format( "Please enter a value between {0} and {1}." ),
                  max: $.validator.format( "Please enter a value less than or equal to {0}." ),
                  min: $.validator.format( "Please enter a value greater than or equal to {0}." ),
                  step: $.validator.format( "Please enter a multiple of {0}." )
            },
            errorClass: "invalid-feedback",
            highlight: function(e: any) {
                $(e).addClass("is-invalid");
            },
            unhighlight: function(e: any) {
              $(e).removeClass("is-invalid");
            },
          });
          
          $.validator.messages.required = messages.required;
          $.validator.messages.equalTo = messages.equalTo;
          $.validator.messages.minlength = messages.minlength;
    }
}