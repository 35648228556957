import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class ExportacoesService {

  private api: string = `${environment.api}/importacoes`;

  constructor(
    private http: HttpClient
  ) { }



  list(payload: {
    page: number,
    maxRows: number,
    buscaPor: string
  }) {
    return this.http.get(`${this.api}/filter/exportacao?page=${payload.page}&max=${payload.maxRows}&search=${payload.buscaPor}`);
  }

  get(id: number) {
    return this.http.get(`${this.api}/${id}`);
  }

  saveOrUpdate(payload) {
    return this.http.post(this.api, payload);
  }

  delete(id: number) {
    return this.http.delete(`${this.api}/${id}`);
  }

}