import { NgRxAction } from '../NgRxAction';
import { AlvoInterface } from './alvos.service';

export enum AlvosActions {
    LIST_ALVOS = '[ALVOS] - List ALVOS - ',
    LIST_ALVOS_SUCCESS = '[ALVOS] - List ALVOS Success - ',
    LIST_ALVOS_ERROR = '[ALVOS] - Error List ALVOS - ',
    LOAD_ALVO = '[ALVO] - Load Alvo',
    LOAD_ALVO_SUCCESS = '[ALVO] - Load Alvo Success',
    LOAD_ALVO_ERROR = '[ALVO] - Load Alvo Error',
    SAVE_ALVO = '[ALVO] - Save Alvo',
    SAVE_ALVO_SUCCESS = '[ALVO] - Save Alvo Success',
    SAVE_ALVO_ERROR = '[ALVO] - Save Alvo Error',
    UPDATE_ALVO = '[ALVO] - Update Alvo',
    UPDATE_ALVO_SUCCESS = '[ALVO] - Update Alvo Success',
    DELETE_ALVO = '[ALVO] Delete Alvo',
    DELETE_ALVO_ERROR = '[ALVO] Delete Alvo Error',
    DELETE_ALVO_SUCCESS = '[ALVO] Delete Alvo Success',
    ALVO_ERROR = '[ALVO] Error Alvo'
}

export class ListAlvosAction extends NgRxAction<any> {
    readonly type = AlvosActions.LIST_ALVOS;
}

export class ListAlvosSuccessAction extends NgRxAction<any> {
    readonly type = AlvosActions.LIST_ALVOS_SUCCESS;
}

export class ListAlvosErrorAction extends NgRxAction<any> {
    readonly type = AlvosActions.LIST_ALVOS_ERROR;
}

export class LoadAlvoAction extends NgRxAction<any>{
    readonly type = AlvosActions.LOAD_ALVO;
}

export class LoadAlvoSuccessAction extends NgRxAction<any>{
    readonly type = AlvosActions.LOAD_ALVO_SUCCESS;
}

export class LoadAlvoErrorAction extends NgRxAction<any>{
    readonly type = AlvosActions.LOAD_ALVO_ERROR;
}

export class SaveAlvoAction extends NgRxAction<AlvoInterface>{
    readonly type = AlvosActions.SAVE_ALVO;
}

export class SaveAlvoSuccessAction extends NgRxAction<any>{
    readonly type = AlvosActions.SAVE_ALVO_SUCCESS;
}

export class SaveAlvoErrorAction extends NgRxAction<any>{
    readonly type = AlvosActions.SAVE_ALVO_ERROR;
}


export class UpdateAlvoAction extends NgRxAction<any>{
    readonly type = AlvosActions.UPDATE_ALVO;
}

export class UpdateAlvoSuccessAction extends NgRxAction<any>{
    readonly type = AlvosActions.UPDATE_ALVO_SUCCESS;
}

export class ErrorAlvosActions extends NgRxAction<any>{
    readonly type = AlvosActions.ALVO_ERROR;
}

export class DeleteAlvoAction extends NgRxAction<any>{
    readonly type = AlvosActions.DELETE_ALVO;
}

export class DeleteAlvoSuccessAction extends NgRxAction<any>{
    readonly type = AlvosActions.DELETE_ALVO_SUCCESS;
}

export class DeleteAlvoErrorAction extends NgRxAction<any>{
    readonly type = AlvosActions.DELETE_ALVO_ERROR;
}



export type TypesActions =
    ListAlvosAction
    | ListAlvosSuccessAction
    | ListAlvosErrorAction
    | LoadAlvoAction
    | LoadAlvoSuccessAction
    | LoadAlvoErrorAction
    | SaveAlvoAction
    | SaveAlvoSuccessAction
    | SaveAlvoErrorAction
    | UpdateAlvoAction
    | UpdateAlvoSuccessAction
    | ErrorAlvosActions
    | DeleteAlvoAction
    | DeleteAlvoSuccessAction
    | DeleteAlvoErrorAction