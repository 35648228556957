import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_NAME } from './importacoes.state';
import { importacoesReducer } from './importacoes.reducer';
import { ImportacoesEffects } from './importacoes.effects';
import { ImportacoesStoreService } from './importacoes-store.service';
import { ImportacoesService } from './importacoes.service';

@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(STATE_NAME, importacoesReducer),
      EffectsModule.forFeature([ImportacoesEffects]),
  ],
  exports: [
      StoreModule,
      EffectsModule,
      HttpClientModule
  ],
  providers: [
      ImportacoesStoreService,
      ImportacoesService
  ]
})
export class ImportacoesStoreModule { }