import { Component, OnInit } from '@angular/core';

import { Init } from './init';

Init.setValidatorDefaults();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit{
  title = 'adama-pasto';
  
  constructor() {}

  ngOnInit() {}
}