import { NgRxAction } from '../NgRxAction';
import { InfestacaoInterface } from './infestacoes.service';

export enum InfestacoesActions {
    LIST_INFESTACOES = '[INFESTACOES] - List INFESTACOES - ',
    LIST_INFESTACOES_SUCCESS = '[INFESTACOES] - List INFESTACOES Success - ',
    LIST_INFESTACOES_ERROR = '[INFESTACOES] - Error List INFESTACOES - ',
    LOAD_INFESTACAO = '[INFESTACAO] - Load Infestacao',
    LOAD_INFESTACAO_SUCCESS = '[INFESTACAO] - Load Infestacao Success',
    LOAD_INFESTACAO_ERROR = '[INFESTACAO] - Load Infestacao Error',
    SAVE_OR_UPDATE_INFESTACAO = '[INFESTACAO] - Save or Update Infestacao',
    SAVE_OR_UPDATE_INFESTACAO_SUCCESS = '[INFESTACAO] - Save or Update Infestacao Success',
    DELETE_INFESTACAO = '[INFESTACAO] Delete Infestacao',
    DELETE_INFESTACAO_ERROR = '[INFESTACAO] Delete Infestacao Error',
    DELETE_INFESTACAO_SUCCESS = '[INFESTACAO] Delete Infestacao Success',
    INFESTACAO_ERROR = '[INFESTACAO] Error Infestacao'
}

export class ListInfestacoesAction extends NgRxAction<any> {
    readonly type = InfestacoesActions.LIST_INFESTACOES;
}

export class ListInfestacoesSuccessAction extends NgRxAction<any> {
    readonly type = InfestacoesActions.LIST_INFESTACOES_SUCCESS;
}

export class ListInfestacoesErrorAction extends NgRxAction<any> {
    readonly type = InfestacoesActions.LIST_INFESTACOES_ERROR;
}
export class LoadInfestacaoAction extends NgRxAction<any>{
    readonly type = InfestacoesActions.LOAD_INFESTACAO;
}

export class LoadInfestacaoSuccessAction extends NgRxAction<any>{
    readonly type = InfestacoesActions.LOAD_INFESTACAO_SUCCESS;
}

export class LoadInfestacaoErrorAction extends NgRxAction<any>{
    readonly type = InfestacoesActions.LOAD_INFESTACAO_ERROR;
}

export class SaveOrUpdateInfestacaoAction extends NgRxAction<InfestacaoInterface>{
    readonly type = InfestacoesActions.SAVE_OR_UPDATE_INFESTACAO;
}

export class SaveOrUpdateInfestacaoSuccessAction extends NgRxAction<any>{
    readonly type = InfestacoesActions.SAVE_OR_UPDATE_INFESTACAO_SUCCESS;
}

export class ErrorInfestacoesActions extends NgRxAction<any>{
    readonly type = InfestacoesActions.INFESTACAO_ERROR;
}

export class DeleteInfestacaoAction extends NgRxAction<any>{
    readonly type = InfestacoesActions.DELETE_INFESTACAO;
}

export class DeleteInfestacaoSuccessAction extends NgRxAction<any>{
    readonly type = InfestacoesActions.DELETE_INFESTACAO_SUCCESS;
}

export class DeleteInfestacaoErrorAction extends NgRxAction<any>{
    readonly type = InfestacoesActions.DELETE_INFESTACAO_ERROR;
}


export type TypesActions =
    ListInfestacoesAction
    | ListInfestacoesSuccessAction
    | ListInfestacoesErrorAction
    | LoadInfestacaoAction
    | LoadInfestacaoSuccessAction
    | LoadInfestacaoErrorAction
    | SaveOrUpdateInfestacaoAction
    | SaveOrUpdateInfestacaoSuccessAction
    | ErrorInfestacoesActions
    | DeleteInfestacaoAction
    | DeleteInfestacaoSuccessAction
    | DeleteInfestacaoErrorAction