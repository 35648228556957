import * as importacoesState from './importacoes.state';
import * as actions from './importacoes.actions';

export function importacoesReducer(
    state: importacoesState.ImportacoesState = importacoesState.initialState,
    action: actions.TypesActions
): importacoesState.ImportacoesState {

    switch (action.type) {
        case actions.ImportacoesActions.LIST_IMPORTACOES:
            return {
                ...state,
                isLoading: true,
                list: {
                    ...state.list,
                    importacoes: []
                },
                error: importacoesState.initialState.error
            }
        case actions.ImportacoesActions.LIST_IMPORTACOES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    importacoes: action.payload.importacoes,
                    total: action.payload.total
                }
            }
        case actions.ImportacoesActions.LIST_IMPORTACOES_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                },
                list: {
                    ...state.list,
                    total: 0
                }
            }
        default:
            return state;
    }

}