import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthStoreService } from '../store/auth/auth-store.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(
    private router: Router,
    private authServiceStore: AuthStoreService,
  ) {}

  ngOnInit() {
    this.authServiceStore.dispatchCheckUserLogged();
    let routeAccess = location.pathname;
    this.authServiceStore.getUserInfo().subscribe(userInfo => {
      if(userInfo === null) {
        return this.router.navigate(['/login']);
      }

      if(routeAccess.includes('/login')) {
        return this.router.navigate(['/top-trinta']);
      }
    })

  }
}
