import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})

export class FaqComponent implements OnInit {

  pagination: Observable<any>;
  maxRows: number = 10;
  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  reorderable: boolean = true;
  error$: Observable<any>;

  messageEmpty: string = 'Nenhum faq cadastrado';
  messageLoading: string = 'Carregando...';
  messageNotFound: string = 'Faq não encontrado';

  private subscribePagination: Subscription;

  constructor(
  ) {}

  ngOnInit() {
    this.dispatchLoadFaqsAction();
  }

  onClickDelete(faq) {
  }

  onChangeMax(event) {
    this.maxRows = parseInt(event.target.value);
    this.dispatchLoadFaqsAction();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.buscaPor = val;
    this.page = 0;
    this.dispatchLoadFaqsAction();
  }

  onChangePage(pageInfo) {
    this.page = pageInfo.offset;
    this.dispatchLoadFaqsAction();
  }

  private dispatchLoadFaqsAction() {
  }
}
