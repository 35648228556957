import { Injectable } from "@angular/core";

import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../app.reducer';
import * as actions from './revenda.actions';
import * as state from './revenda.state';
import { RevendaInterface } from './revenda.service';
import { Revenda } from 'src/app/model/revenda';

@Injectable()
export class RevendaStoreService {

  constructor(private store: Store<AppState>) { }

  private revendaState = createFeatureSelector<state.RevendaState>(state.STATE_NAME);
  private isLoading = createSelector(this.revendaState, state.selectIsLoading);
  private revenda = createSelector(this.revendaState, state.selectRevenda);
  private revendas = createSelector(this.revendaState, state.selectListRevenda);
  private total = createSelector(this.revendaState, state.selectTotal);
  private error = createSelector(this.revendaState,state.selectError);

  getIsLoading() {
    return this.store.select(this.isLoading);
  }

  getRevenda() {
    return this.store.select(this.revenda);
  }

  getRevendas(){
    return this.store.select(this.revendas);
  }

  getTotal() {
    return this.store.select(this.total);
  }
  
  getError(){
    return this.store.select(this.error);
  }

  dispatchListRevendaAction(page: number, maxRows: number, buscaPor: string) {
    this.store.dispatch(new actions.ListRevendaAction({
      maxRows: maxRows,
      page: page,
      buscaPor: buscaPor
    }));
  }

  dispatchLoadRevendaAction(id: number){
    this.store.dispatch(new actions.LoadRevendaAction(id))
  }

  dispatchSaveAction(payload: RevendaInterface){
    this.store.dispatch(new actions.SaveRevendaAction(payload));
  }

  dispatchUpdateAction(payload: RevendaInterface){
    this.store.dispatch(new actions.UpdateRevendaAction(payload));
  }

  dispatchDeleteRevendaAction(revenda: Revenda){
    this.store.dispatch(new actions.DeleteRevendaAction(revenda));
  }

}