import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { UsuariosComponent } from './usuarios/usuarios.component';
import { CadastroComponent } from './cadastro/cadastro.component';

const routes: Routes = [
  {path: '', component: UsuariosComponent},
  {path: 'cadastro', component: CadastroComponent},
  {path: 'cadastro/:id', component: CadastroComponent}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes), 
    NgxDatatableModule
  ],
  exports: [
    RouterModule
  ]
})

export class UsuariosRoutingModule { }