import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CidadesRoutingModule } from './cidades-routing.module';
import { CidadesComponent } from './cidades/cidades.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
import { CidadeStoreModule } from 'src/app/store/cidade/cidade-store.module';

@NgModule({
  declarations: [CidadesComponent],
  imports: [
    CommonModule,
    CidadesRoutingModule,
    NgxDatatableModule,
    SharedModule,
    CidadeStoreModule
  ],
  bootstrap: [CidadesComponent]
})
export class CidadesModule { }
