import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, filter, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';

import * as importacoesActions from './importacoes.actions';
import { ImportacoesService } from './importacoes.service';

@Injectable()
export class ImportacoesEffects {

    constructor(
        private actions$: Actions,
        private importacoesService: ImportacoesService,
    ) { }

    @Effect()
    loadImportacoesAction$ = this.actions$.pipe(
        ofType<importacoesActions.ListImportacoesAction>(importacoesActions.ImportacoesActions.LIST_IMPORTACOES),
        map(action => action.payload),
        debounceTime(800),
        mergeMap(payload => {
            return this.importacoesService.list(payload).pipe(
                map((response: any) => {
                    const {elements, total} = response;                    
                    return new importacoesActions.ListImportacoesSuccessAction({
                        total : total * payload.maxRows,
                        importacoes: elements
                    });
                }),
                catchError((err: any) => {
                    return of(new importacoesActions.ListImportacoesErrorAction(err.error.message));
                })
            );
        })
    );
}