import * as infestacoesState from './infestacoes.state';
import * as actions from './infestacoes.actions';

export function infestacoesReducer(
    state: infestacoesState.InfestacoesState = infestacoesState.initialState,
    action: actions.TypesActions
): infestacoesState.InfestacoesState {

    switch (action.type) {
        case actions.InfestacoesActions.LIST_INFESTACOES:
            return {
                ...state,
                isLoading: true,
                list: {
                    ...state.list,
                    infestacoes: []
                },
                error: infestacoesState.initialState.error
            }
        case actions.InfestacoesActions.LIST_INFESTACOES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    infestacoes: action.payload.infestacoes,
                    total: action.payload.total
                }
            }
        case actions.InfestacoesActions.LIST_INFESTACOES_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload,
                },
                list: {
                    ...state.list,
                    total: 0
                }
            }
            case actions.InfestacoesActions.LOAD_INFESTACAO:
                return {
                    ...state,
                    isLoading: true,
                    infestacao: null
                }
            case actions.InfestacoesActions.LOAD_INFESTACAO_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    infestacao: action.payload
                }
            case actions.InfestacoesActions.LOAD_INFESTACAO_ERROR:
                return {
                    ...state,
                    isLoading: false,
                    error: {
                        show: true,
                        message: action.payload
                    }
                }
            case actions.InfestacoesActions.SAVE_OR_UPDATE_INFESTACAO:
                return {
                    ...state,
                    isLoading: true,
                    error: infestacoesState.initialState.error
                }
            case actions.InfestacoesActions.SAVE_OR_UPDATE_INFESTACAO_SUCCESS:
                return {
                    ...state,
                    isLoading: false
                }
            case actions.InfestacoesActions.DELETE_INFESTACAO:
                return {
                    ...state,
                    isLoading: false,
                    infestacao: null
                }
    
            default:
                return state;
    }

}