import { NgModule } from '@angular/core';
import { AlterarDadosModule } from './pages/alterar-dados/alterar-dados.module';
import { EsqueciSenhaModule } from './login/esqueci-senha/esqueci-senha.module';
import { RegioesModule } from './pages/regioes/regioes.module';
import { LayoutComponent } from './layout/layout.component';
import { RevendasModule } from './pages/revendas/revendas.module';
import { Routes, RouterModule } from '@angular/router';
import { FaqModule } from './pages/faq/faq.module';
import { LoginModule } from './login/login.module';

import { RegioesAlvosModule } from './pages/regioes-alvos/regioes-alvos.module';
import { TiposAlvosModule } from './pages/tipos-alvos/tipos-alvos.module';
import { UsuariosModule } from './pages/usuarios/usuarios.module';
import { ProdutosModule } from './pages/produtos/produtos.module';
import { AlvosModule } from './pages/alvos/alvos.module';
import { InfestacoesModule } from './pages/infestacoes/infestacoes.module';
import { TopTrintaModule } from './pages/top-trinta/top-trinta.module';
import { AdminsModule } from './pages/admins/admins.module';
import { AuthGuard } from './guards/auth.guard';
import { RedefinirSenhaModule } from './login/redefinirSenha/redefinirSenha.module';
import { ExportacoesModule } from './pages/exportacoes/exportacoes.module';
import { CidadesModule } from './pages/cidades/cidades.module';
import { AlterarSenhaModule } from './pages/alterar-senha/alterar-senha.module';
import { ImportacoesModule } from './pages/importacoes/importacoes.module';
import { BaseCelularModule } from './pages/base-celular/base-celular.module';

const routes: Routes = [
  {
    path: '', 
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '', loadChildren: () => TopTrintaModule
      },
      {
        path: 'revendas', loadChildren: () => RevendasModule
      },
      {
        path: 'top-trinta', loadChildren: () => TopTrintaModule
      },
      // {
      //   path: 'regioes', loadChildren: () => RegioesModule
      // },
      {
        path: 'cidades', loadChildren: () => CidadesModule
      },
      {
        path: 'regioes-alvos', loadChildren: () => RegioesAlvosModule
      },
      {
        path: 'tipos-alvos', loadChildren: () => TiposAlvosModule
      },
      {
        path: 'usuarios', loadChildren: () => UsuariosModule
      },
      {
        path: 'admins', loadChildren: () => AdminsModule
      },
      {
        path: 'produtos', loadChildren: () => ProdutosModule
      },
      {
        path: 'alvos', loadChildren: () => AlvosModule
      },
      {
        path: 'relatorios', loadChildren: () => InfestacoesModule
      },
      {
        path: 'faq', loadChildren: () => FaqModule
      },
      {
        path: 'alterar-senha', loadChildren: () => AlterarSenhaModule
      },
      {
        path: 'alterar-dados', loadChildren: () => AlterarDadosModule
      },
      {
        path: 'importacoes', loadChildren: () => ImportacoesModule
      },
      {
        path: 'exportacoes', loadChildren: () => ExportacoesModule
      },
      {
        path: 'base', loadChildren: () => BaseCelularModule
      }
    ]
  },
  {
    path: 'login', loadChildren: () => LoginModule
  },
  {
    path: 'esqueci-senha', loadChildren: () => EsqueciSenhaModule
  },
  {
    path: 'redefinirSenha', loadChildren: () => RedefinirSenhaModule
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})

export class AppRoutingModule { }