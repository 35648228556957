import { NgRxAction } from '../NgRxAction';
import { UsuarioInterface } from './usuario.service';

export enum UsuarioActions {
    LIST_USUARIO = '[USUARIO] - List USUARIO - ',
    LIST_USUARIO_SUCCESS = '[USUARIO] - List USUARIO Success - ',
    LIST_USUARIO_ERROR = '[USUARIO] - Error List USUARIO - ',
    LOAD_USUARIO = '[USUARIO] - Load USUARIO',
    LOAD_USUARIO_SUCCESS = '[USUARIO] - Load USUARIO Success',
    LOAD_USUARIO_ERROR = '[USUARIO] - Load USUARIO Error',
    SAVE_USUARIO = '[USUARIO] - Save USUARIO',
    SAVE_USUARIO_SUCCESS = '[USUARIO] - Save USUARIO Success',
    SAVE_USUARIO_ERROR = '[USUARIO] - Save USUARIO Error',
    UPDATE_USUARIO = '[USUARIO] - Update USUARIO',
    UPDATE_USUARIO_SUCCESS = '[USUARIO] - Update USUARIO Success',
    UPDATE_USUARIO_ERROR = '[USUARIO] - Update USUARIO Error',
    DELETE_USUARIO = '[USUARIO] Delete USUARIO',
    DELETE_USUARIO_ERROR = '[USUARIO] Delete USUARIO Error',
    DELETE_USUARIO_SUCCESS = '[USUARIO] Delete USUARIO Success',
    USUARIO_ERROR = '[USUARIO] Error USUARIO',
    LIST_PERFIS = '[USUARIO] - List PERFIS - ',
    LIST_PERFIS_SUCCESS = '[USUARIO] - List PERFIS Success- ',
    LIST_PERFIS_ERROR = '[USUARIO] - List PERFIS Error- ',
}

export class ListUsuarioAction extends NgRxAction<any> {
    readonly type = UsuarioActions.LIST_USUARIO;
}

export class ListUsuarioSuccessAction extends NgRxAction<any> {
    readonly type = UsuarioActions.LIST_USUARIO_SUCCESS;
}

export class ListUsuarioErrorAction extends NgRxAction<any> {
    readonly type = UsuarioActions.LIST_USUARIO_ERROR;
}

export class LoadUsuarioAction extends NgRxAction<any>{
    readonly type = UsuarioActions.LOAD_USUARIO;
}

export class LoadUsuarioSuccessAction extends NgRxAction<any>{
    readonly type = UsuarioActions.LOAD_USUARIO_SUCCESS;
}

export class LoadUsuarioErrorAction extends NgRxAction<any>{
    readonly type = UsuarioActions.LOAD_USUARIO_ERROR;
}

export class SaveUsuarioAction extends NgRxAction<UsuarioInterface>{
    readonly type = UsuarioActions.SAVE_USUARIO;
}

export class SaveUsuarioSuccessAction extends NgRxAction<any>{
    readonly type = UsuarioActions.SAVE_USUARIO_SUCCESS;
}

export class SaveUsuarioErrorAction extends NgRxAction<any>{
    readonly type = UsuarioActions.SAVE_USUARIO_ERROR;
}

export class UpdateUsuarioAction extends NgRxAction<UsuarioInterface>{
    readonly type = UsuarioActions.UPDATE_USUARIO;
}

export class UpdateUsuarioSuccessAction extends NgRxAction<any>{
    readonly type = UsuarioActions.UPDATE_USUARIO_SUCCESS;
}

export class UpdateUsuarioErrorAction extends NgRxAction<any>{
    readonly type = UsuarioActions.UPDATE_USUARIO_ERROR;
}

export class ErrorUsuarioActions extends NgRxAction<any>{
    readonly type = UsuarioActions.USUARIO_ERROR;
}

export class DeleteUsuarioAction extends NgRxAction<any>{
    readonly type = UsuarioActions.DELETE_USUARIO;
}

export class DeleteUsuarioSuccessAction extends NgRxAction<any>{
    readonly type = UsuarioActions.DELETE_USUARIO_SUCCESS;
}

export class DeleteUsuarioErrorAction extends NgRxAction<any>{
    readonly type = UsuarioActions.DELETE_USUARIO_ERROR;
}

export class ListPerfisAction extends NgRxAction<any> {
    readonly type = UsuarioActions.LIST_PERFIS;
}

export class ListPerfisSuccessAction extends NgRxAction<any> {
    readonly type = UsuarioActions.LIST_PERFIS_SUCCESS;
}

export class ListPerfisErrorAction extends NgRxAction<any> {
    readonly type = UsuarioActions.LIST_PERFIS_ERROR;
}


export type TypesActions =
    ListUsuarioAction
    | ListUsuarioSuccessAction
    | ListUsuarioErrorAction
    | LoadUsuarioAction
    | LoadUsuarioSuccessAction
    | LoadUsuarioErrorAction
    | SaveUsuarioAction
    | SaveUsuarioSuccessAction
    | SaveUsuarioErrorAction
    | UpdateUsuarioAction
    | UpdateUsuarioSuccessAction
    | UpdateUsuarioErrorAction
    | ErrorUsuarioActions
    | DeleteUsuarioAction
    | DeleteUsuarioSuccessAction
    | DeleteUsuarioErrorAction
    | ListPerfisAction
    | ListPerfisSuccessAction
    | ListPerfisErrorAction
