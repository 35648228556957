import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';

@Component({
  selector: 'app-base-celular',
  templateUrl: './base-celular.component.html',
  styleUrls: ['./base-celular.component.scss']
})
export class BaseCelularComponent implements OnInit {

  private api: string = `${environment.api}/v1/sqlite/generate`;
  urlBase: string;
  isLoading: boolean = false;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {

  }

  onClickGerar() {
    this.isLoading = true;
    return this.http.get<any>(this.api).subscribe(
      res => {
        this.urlBase = res.url;
        this.isLoading = false;
      },
      err => {
        swal("Opss...", "Ocorreu um erro ao gerar a base, tente novamente mais tarde!", "error");
        this.isLoading = false;
      }
    );
  }
}
