import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AdminStoreService } from 'src/app/store/admin/admin-store.service';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  form: FormGroup;
  isLoading$: Observable<boolean>;
  textbreadcrumbs: string = "Novo Administrador";
  id = this.route.snapshot.params['id'];
  constructor(
    private adminStoreService: AdminStoreService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isLoading$ = this.adminStoreService.getIsLoading();

    this.form = this.fb.group({
      id: [null],
      nome: ['', Validators.required],
      username: ['', Validators.required],
      password: ['']
    });

    $('#form-admin').validate();

    this.adminStoreService.getError()
      .pipe(
        filter(err => err.show && err.message != ''),
      ).subscribe(err => swal('Opss...', err.message, 'error'));

    if (this.id) {
      $(document).ready(function () {
        $("#password").prop('required', false);
        $("#campoSenha").css("display", "none");
      });
      this.textbreadcrumbs = "Editar Administrador"
      this.adminStoreService.dispatchLoadAdminAction(this.id);
      this.adminStoreService.getAdmin()
        .pipe(
          filter(admin => admin !== null),
        )
        .subscribe((admin) => {
          this.form.patchValue(admin);
        });
    }else{
      $(document).ready(function () {
        $("#password").prop('required', true);
        $("#campoSenha").css("display", "block");
      });
    }
  }

  onClickSave() {
    if (this.form.valid) {
      if (this.id == null)
        this.adminStoreService.dispatchSaveAction(this.form.value);
      else
        this.adminStoreService.dispatchUpdateAction(this.form.value)
    }
  }
}
