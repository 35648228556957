import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { RegioesAlvo } from 'src/app/model/regioesAlvos';
import { RegioesalvoStoreService } from 'src/app/store/regioesalvo/regioesalvo-store.service';
import { filter } from 'rxjs/operators';
import swal from 'sweetalert2';

@Component({
  selector: 'app-regioes-alvos',
  templateUrl: './regioes-alvos.component.html',
  styleUrls: ['./regioes-alvos.component.scss']
})

export class RegioesAlvosComponent implements OnInit {

  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  reorderable: boolean = true;
  error$: Observable<any>;
  rows$: Observable<RegioesAlvo[]>;
  maxRows: number = 50;
  total$: Observable<number>;

  constructor(
    private regioesAlvoStoreService: RegioesalvoStoreService
  ) {}

  ngOnInit() {
    this.isLoading$ = this.regioesAlvoStoreService.getIsLoading();
    this.dispatchLoadRegioesAlvoAction();
    this.rows$ = this.regioesAlvoStoreService.getRegioesAlvoList();
    this.total$ = this.regioesAlvoStoreService.getTotal();

    this.regioesAlvoStoreService.getError()
      .pipe(
        filter(err => err.show && err.message != '')
      ).subscribe(err =>{
        swal('Opss...', 'Esta região já existe', 'error');
      });
  }

  onChangeMax(event){
    this.maxRows = parseInt(event.target.value);
    this.dispatchLoadRegioesAlvoAction();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.buscaPor = val;
    this.page = 0;
    this.dispatchLoadRegioesAlvoAction();
  }

  private dispatchLoadRegioesAlvoAction(){
    this.regioesAlvoStoreService.dispatchListRegioesalvoAction(this.page, this.maxRows, this.buscaPor);
  }

  onClickDelete(regioes_alvo) {
    this.regioesAlvoStoreService.dispatchDeleteRegioesAlvoAction(regioes_alvo);
  }

  onChangePage(pageInfo) {
    this.page = pageInfo.offset;
    this.dispatchLoadRegioesAlvoAction();
  }

}