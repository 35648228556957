import { Injectable } from "@angular/core";

import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../app.reducer';
import * as actions from './infestacoes.actions';
import * as state from './infestacoes.state';
import { Infestacao } from 'src/app/model/infestacao';
import { InfestacaoInterface } from './infestacoes.service';

@Injectable()
export class InfestacoesStoreService {

  constructor(private store: Store<AppState>) { }

  private infestacoesState = createFeatureSelector<state.InfestacoesState>(state.STATE_NAME);
  private isLoading = createSelector(this.infestacoesState, state.selectIsLoading);
  private infestacao = createSelector(this.infestacoesState, state.selectInfestacoes);
  private infestacoes = createSelector(this.infestacoesState, state.selectListInfestacoes);
  private error = createSelector(this.infestacoesState, state.selectError);
  private total = createSelector(this.infestacoesState, state.selectTotal);

  getIsLoading() {
    return this.store.select(this.isLoading);
  }

  getInfestacao() {
    return this.store.select(this.infestacao);
  }

  getInfestacoes(){
    return this.store.select(this.infestacoes);
  }

  getError(){
    return this.store.select(this.error);
  }

  getTotal() {
    return this.store.select(this.total);
  }

  dispatchLoadInfestacaoAction(id: number){
    this.store.dispatch(new actions.LoadInfestacaoAction(id));
  }

  dispatchListInfestacoesAction(page: number, maxRows: number, buscaPor: string) {
    this.store.dispatch(new actions.ListInfestacoesAction({
      page: page,
      maxRows: maxRows,
      buscaPor: buscaPor
    }));
  }

  dispatchSaveOrUpdateAction(payload: InfestacaoInterface){
    this.store.dispatch(new actions.SaveOrUpdateInfestacaoAction(payload));
  }

  dispatchDeleteInfestacaoAction(infestacao: Infestacao){
    this.store.dispatch(new actions.DeleteInfestacaoAction(infestacao));
  }

}