import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AdminStoreService } from 'src/app/store/admin/admin-store.service';
import { ActivatedRoute } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { AuthStoreService } from 'src/app/store/auth/auth-store.service';
import swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-redefinirSenha',
  templateUrl: './redefinirSenha.component.html',
  styleUrls: ['./redefinirSenha.component.scss']
})
export class RedefinirSenhaComponent implements OnInit {

  form: FormGroup

  isLoading$: Observable<boolean>;
  constructor(
    private fb: FormBuilder,
    private authStoreService: AuthStoreService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {         
    this.createForm();    
    $('#form-redefinir-senha').validate({
      rules: {
        confirmarSenha: {
          equalTo: "#senha"
        },
        senha:{
          minlength: 5
        }
      },
      submitHandler: () => {
        this.redefinirSenha();
      }      
    });

    this.authStoreService.getError()
    .pipe(
      filter(err => err.show && err.message != '')
    ).subscribe(err =>{
      swal('Opss...', err.message, 'error');
    });
  }

  ngOnDestroy(): void {
    this.authStoreService.dispatchClearErrorAction();
  }

  redefinirSenha(){
    const token = this.route.snapshot.params['token'];
    const values = this.form.value;
    const senha = values.senha;
    const novaSenha = values.confirmarSenha;
    this.authStoreService.dispatchRedefinirSenhaAction(senha, novaSenha, token)
    localStorage.clear();
  }

  private createForm() {;  
    this.form = this.fb.group({
      'senha': ['', Validators.required],
      'confirmarSenha': ['', Validators.required]
    });
  }
}
