import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_NAME } from './admin.state';
import { adminReducer } from './admin.reducer';
import { AdminEffects } from './admin.effects';
import { AdminStoreService } from './admin-store.service';
import { AdminService } from './admin.service';

@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(STATE_NAME, adminReducer),
      EffectsModule.forFeature([AdminEffects]),
  ],
  exports: [
      StoreModule,
      EffectsModule,
      HttpClientModule
  ],
  providers: [
      AdminStoreService,
      AdminService
  ]
})
export class AdminStoreModule { }