import { Injectable } from "@angular/core";

import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../app.reducer';
import * as actions from './usuario.actions';
import * as state from './usuario.state';
import { UsuarioInterface } from './usuario.service';
import { Usuario } from 'src/app/model/usuario';

@Injectable()
export class UsuarioStoreService {

  constructor(private store: Store<AppState>) { }

  private usuarioState = createFeatureSelector<state.UsuarioState>(state.STATE_NAME);
  private isLoading = createSelector(this.usuarioState, state.selectIsLoading);
  private usuario = createSelector(this.usuarioState, state.selectUsuario);
  private usuarios = createSelector(this.usuarioState, state.selectUsuarios);
  private error = createSelector(this.usuarioState, state.selectError);
  private total = createSelector(this.usuarioState, state.selectTotal);
  private perfis = createSelector(this.usuarioState, state.selectPerfis);

  getIsLoading() {
    return this.store.select(this.isLoading);
  }

  getUsuario() {
    return this.store.select(this.usuario);
  }

  getUsuarios(){
    return this.store.select(this.usuarios)
  }

  getError(){
    return this.store.select(this.error);
  }

  getTotal() {
    return this.store.select(this.total);
  }

  getPerfis(){
    return this.store.select(this.perfis);
  }

  dispatchLoadUsuarioAction(id: number){
    this.store.dispatch(new actions.LoadUsuarioAction(id));
  }

  dispatchListUsuarioAction(page: number, maxRows: number, buscaPor: string) {
    this.store.dispatch(new actions.ListUsuarioAction({
      maxRows: maxRows,
      page: page,
      buscaPor: buscaPor
    }));
  }

  dispatchSaveAction(payload: UsuarioInterface){
    this.store.dispatch(new actions.SaveUsuarioAction(payload));
  }

  dispatchUpdateAction(payload: UsuarioInterface){
    this.store.dispatch(new actions.UpdateUsuarioAction(payload));
  }

  dispatchDeleteUsuarioAction(usuario: Usuario){
    this.store.dispatch(new actions.DeleteUsuarioAction(usuario));
  }

  dispatchListPerfisAction(page: number, maxRows: number, buscaPor: string) {
    this.store.dispatch(new actions.ListPerfisAction({
      maxRows: maxRows,
      page: page,
      buscaPor: buscaPor
    }));
  }
}