import { NgRxAction } from '../NgRxAction';

export enum ImportacoesActions {
    LIST_IMPORTACOES = '[IMPORTACOES] - List IMPORTACOES - ',
    LIST_IMPORTACOES_SUCCESS = '[IMPORTACOES] - List IMPORTACOES Success - ',
    LIST_IMPORTACOES_ERROR = '[IMPORTACOES] - Error List IMPORTACOES - ',
}

export class ListImportacoesAction extends NgRxAction<any> {
    readonly type = ImportacoesActions.LIST_IMPORTACOES;
}

export class ListImportacoesSuccessAction extends NgRxAction<any> {
    readonly type = ImportacoesActions.LIST_IMPORTACOES_SUCCESS;
}

export class ListImportacoesErrorAction extends NgRxAction<any> {
    readonly type = ImportacoesActions.LIST_IMPORTACOES_ERROR;
}


export type TypesActions =
    ListImportacoesAction
    | ListImportacoesSuccessAction
    | ListImportacoesErrorAction