import { Injectable } from "@angular/core";

import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../app.reducer';
import * as actions from './produtos.actions';
import * as state from './produtos.state';
import { ProdutoInterface } from './produtos.service';
import { Produtos } from 'src/app/model/produtos';

@Injectable()
export class ProdutoStoreService {

  constructor(private store: Store<AppState>) { }

  private produtoState = createFeatureSelector<state.ProdutosState>(state.STATE_NAME);
  private isLoading = createSelector(this.produtoState, state.selectIsLoading);
  private produto = createSelector(this.produtoState, state.selectProduto);
  private produtoList = createSelector(this.produtoState, state.selectListProdutos);
  private error = createSelector(this.produtoState, state.selectError);
  private total = createSelector(this.produtoState, state.selectTotal);

  getIsLoading() {
    return this.store.select(this.isLoading);
  }

  getProduto() {
    return this.store.select(this.produto);
  }

  getProdutoList(){
    return this.store.select(this.produtoList);
  }

  getError(){
    return this.store.select(this.error);
  }

  getTotal() {
    return this.store.select(this.total);
  }

  dispatchListProdutoAction(page: number, maxRows: number, buscaPor: string) {
    this.store.dispatch(new actions.ListProdutoAction({
      page: page,
      maxRows: maxRows,
      buscaPor: buscaPor
    }));
  }

  dispatchLoadProdutoAction(id: number){
    this.store.dispatch(new actions.LoadProdutoAction(id));
  }

  dispatchSaveAction(payload: ProdutoInterface){
    this.store.dispatch(new actions.SaveProdutoAction(payload));
  }

  dispatchUpdateAction(payload: ProdutoInterface){
    this.store.dispatch(new actions.UpdateProdutoAction(payload));
  }

  dispatchDeleteProdutoAction(produto: Produtos){
    this.store.dispatch(new actions.DeleteProdutoAction(produto));
  }

}