import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_NAME } from './exportacoes.state';
import { exportacoesReducer } from './exportacoes.reducer';
import { ExportacoesEffects } from './exportacoes.effects';
import { ExportacoesStoreService } from './exportacoes-store.service';
import { ExportacoesService } from './exportacoes.service';

@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(STATE_NAME, exportacoesReducer),
      EffectsModule.forFeature([ExportacoesEffects]),
  ],
  exports: [
      StoreModule,
      EffectsModule,
      HttpClientModule
  ],
  providers: [
      ExportacoesStoreService,
      ExportacoesService
  ]
})
export class ExportacoesStoreModule { }