import { Injectable } from "@angular/core";

import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, filter, take, switchMap } from 'rxjs/operators';
import { of, empty } from 'rxjs';

import * as infestacoesActions from './infestacoes.actions';
import { InfestacoesService } from './infestacoes.service';
import { SweetAlertObservable } from 'src/app/shared/SweetAlertObservable';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class InfestacoesEffects {

    constructor(
        private actions$: Actions,
        private infestacoesService: InfestacoesService,
        private router: Router,
        private sweetAlertObservable: SweetAlertObservable
    ) { }

    @Effect()
    loadInfestacoesAction$ = this.actions$.pipe(
        ofType<infestacoesActions.ListInfestacoesAction>(infestacoesActions.InfestacoesActions.LIST_INFESTACOES),
        map(actions => actions.payload),
        mergeMap(payload => {
            return this.infestacoesService.list(payload)
            .pipe(
                map((response: any) => {
                    const {elements, total} = response;
                    return new infestacoesActions.ListInfestacoesSuccessAction({
                        total: total * payload.maxRows,
                        infestacoes: elements
                    });
                }),
                catchError((err: any) => {
                    return of(new infestacoesActions.ListInfestacoesErrorAction(err.error.message));
                })
            );
        })
    );

    @Effect()
    loadInfestacaoAction$ = this.actions$.pipe(
        ofType<infestacoesActions.LoadInfestacaoAction>(infestacoesActions.InfestacoesActions.LOAD_INFESTACAO),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.infestacoesService.get(payload)
                .pipe(
                    map((response: any) => response),
                    map(infestacao =>{
                        return new infestacoesActions.LoadInfestacaoSuccessAction(infestacao);
                    }),
                    catchError(err => {
                        return of(new infestacoesActions.LoadInfestacaoErrorAction(err.error.message));
                    })
                );
        })
    );

    @Effect()
    saveOrUpdateAction$ = this.actions$.pipe(
        ofType<infestacoesActions.SaveOrUpdateInfestacaoAction>(infestacoesActions.InfestacoesActions.SAVE_OR_UPDATE_INFESTACAO),
        map(action => action.payload),
        mergeMap(payload =>{
            return this.infestacoesService.saveOrUpdate(payload).pipe(
                map( _ => {
                    return new infestacoesActions.SaveOrUpdateInfestacaoSuccessAction();
                }),
                catchError(err =>{
                    return of(new infestacoesActions.ErrorInfestacoesActions);
                })
            )
        })
    );

    @Effect()
    saveOrUpdateSuccessAction$ = this.actions$.pipe(
        ofType<infestacoesActions.SaveOrUpdateInfestacaoAction>(infestacoesActions.InfestacoesActions.SAVE_OR_UPDATE_INFESTACAO_SUCCESS),
        mergeMap(_ => {
            swal('Salvo!', 'Salvo com sucesso!', 'success').then(_ =>{
                this.router.navigate(['/infestacoes']);
            });
            return empty();
        })
    );

    @Effect()
    deleteInfestacaoAction$ = this.actions$.pipe(
        ofType<infestacoesActions.DeleteInfestacaoAction>(infestacoesActions.InfestacoesActions.DELETE_INFESTACAO),
        map(action => action.payload),
        mergeMap(infestacao => {
            const preConfirm = () => {
                return this.infestacoesService.delete(infestacao.id).pipe(
                    take(1)
                ).toPromise();
            }
            return this.sweetAlertObservable.confirm({
                title: 'Atenção!',
                text: 'Você deseja mesmo deletar a infestacao ' + infestacao.id + ' ?',
                preConfirm: preConfirm
            }).pipe(
                mergeMap(_ => {
                    return this.sweetAlertObservable.success({
                        type: 'success',
                        title: 'Sucesso!',
                        text: `Infestacao ${infestacao.id} deletada com sucesso`,
                    });
                }),
                catchError((err: HttpErrorResponse) => {
                    if(err.status === 400) {
                        return this.sweetAlertObservable.error({
                            type: 'error',
                            title: 'Erro!',
                            text: err.error.message,
                        });
                    }
                    return empty();
                }),
                switchMap(_ => {
                    return [
                       new infestacoesActions.DeleteInfestacaoSuccessAction(),
                       new infestacoesActions.ListInfestacoesAction({
                           page: 0,
                           maxRows: 10,
                           buscaPor: ''
                       })
                    ]
                }),
            )
        })
    );

}