import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_NAME } from './usuario.state';
import { usuarioReducer } from './usuario.reducer';
import { UsuarioEffects } from './usuario.effects';
import { UsuarioStoreService } from './usuario-store.service';
import { UsuarioService } from './usuario.service';

@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(STATE_NAME, usuarioReducer),
      EffectsModule.forFeature([UsuarioEffects]),
  ],
  exports: [
      StoreModule,
      EffectsModule,
      HttpClientModule
  ],
  providers: [
      UsuarioStoreService,
      UsuarioService
  ]
})
export class UsuarioStoreModule { }