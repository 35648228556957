import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import { filter } from 'rxjs/operators';
import swal from 'sweetalert2';
import { AuthStoreService } from 'src/app/store/auth/auth-store.service';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  isLoading$: Observable<boolean>;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authStoreService: AuthStoreService,
  ) { }

  ngOnInit() {
    this.createForm();
    this.isLoading$ = this.authStoreService.getIsLoading();

    $('#form_login').validate({
      submitHandler: () => {
        this.login();
      }      
    });
    
    this.authStoreService.getError()
    .pipe(
      filter(err => err.show && err.message != '')
    ).subscribe(err =>{
      swal('Opss...', 'Email ou senha inválidos, por favor verifique e tente novamente', 'error');
    });
  }

  ngOnDestroy(): void {
    this.authStoreService.dispatchClearErrorAction();
  }


  login() {
    const values = this.form.value;
    this.authStoreService.dispatchLoadLoginAction({
      login: values.username,
      password: values.password
    });
    localStorage.setItem('email', values.username);
  }

  private createForm() {
    this.form = this.fb.group({
      'username': ['', [Validators.required, Validators.email]],
      'password': ['', [Validators.minLength(6), Validators.required]],
      'remember': [false]
    });
  }

  isValid(field: string) {
    if(this.form.get(field).invalid && this.form.get(field).dirty){
      return true;
    }
  }

}
