import { NgRxAction } from '../NgRxAction';

export enum AuthActions {
    LOAD_LOGIN = '[AUTH] - Load Login - ',
    LOAD_LOGIN_SUCCESS = '[AUTH] - Load Login Success - ',
    LOAD_LOGIN_ERROR = '[AUTH] - Error AUTH - ',
    CHECK_USER_ISLOGGED = '[AUTH] - Check user is logged',
    LOGOUT = '[AUTH] - Logout -',
    ESQUECI_SENHA =  '[AUTH] - Esqueci senha',
    ESQUECI_SENHA_SUCCESS = '[AUTH] - Esqueci senha Success',
    ESQUECI_SENHA_ERROR = '[AUTH] - Esqueci senha Error',
    REDEFINIR_SENHA =  '[AUTH] - Redefinir senha',
    REDEFINIR_SENHA_SUCCESS = '[AUTH] - Redefinir senha Success',
    REDEFINIR_SENHA_ERROR = '[AUTH] - Redefinir senha Error',
    LOAD_CLEAR_ERROR = '[AUTH] - Load Clear Error'  
}

export class LoadLoginAction extends NgRxAction<any> {
    readonly type = AuthActions.LOAD_LOGIN;
}

export class LoadLoginSuccessAction extends NgRxAction<any> {
    readonly type = AuthActions.LOAD_LOGIN_SUCCESS;
}

export class LoadLoginErrorAction extends NgRxAction<any> {
    readonly type = AuthActions.LOAD_LOGIN_ERROR;
}

export class CheckUserIsLogged extends NgRxAction<any> {
    readonly type = AuthActions.CHECK_USER_ISLOGGED;
}

export class LogoutAction extends NgRxAction<any> {
    readonly type = AuthActions.LOGOUT;
}

export class EsqueciSenhaAction extends NgRxAction<any>{
    readonly type = AuthActions.ESQUECI_SENHA;
}

export class EsqueciSenhaSuccessAction extends NgRxAction<any>{
    readonly type = AuthActions.ESQUECI_SENHA_SUCCESS;
}

export class EsqueciSenhaErrorAction extends NgRxAction<any>{
    readonly type = AuthActions.ESQUECI_SENHA_ERROR;
}

export class RedefinirSenhaAction extends NgRxAction<any>{
    readonly type = AuthActions.REDEFINIR_SENHA;
}

export class RedefinirSenhaSuccessAction extends NgRxAction<any>{
    readonly type = AuthActions.REDEFINIR_SENHA_SUCCESS;
}

export class RedefinirSenhaErrorAction extends NgRxAction<any>{
    readonly type = AuthActions.REDEFINIR_SENHA_ERROR;
}

export class LoadClearErrors extends NgRxAction<any>{
    readonly type = AuthActions.LOAD_CLEAR_ERROR;
}

export type TypesActions =
    LoadLoginAction
    | LoadLoginSuccessAction
    | LoadLoginErrorAction
    | CheckUserIsLogged
    | LogoutAction
    | EsqueciSenhaAction
    | EsqueciSenhaSuccessAction
    | EsqueciSenhaErrorAction
    | RedefinirSenhaAction
    | RedefinirSenhaSuccessAction
    | RedefinirSenhaErrorAction
    | LoadClearErrors