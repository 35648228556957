import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';

import { AdminsRoutingModule } from './admins-routing.module';
import { AdminsComponent } from './admins/admins.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CadastroComponent } from './cadastro/cadastro.component';
import { AdminStoreModule } from 'src/app/store/admin/admin-store.module';

@NgModule({
  declarations: [AdminsComponent, CadastroComponent],
  imports: [
    AdminsRoutingModule,
    NgxDatatableModule,
    NgSelectModule,
    SharedModule,
    AdminStoreModule
  ],
  providers:[],
  bootstrap: [AdminsComponent]
})

export class AdminsModule { }