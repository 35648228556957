import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlterarSenhaRoutingModule } from './alterar-senha-routing.module';
import { AlterarSenhaComponent } from './alterar-senha.component';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { SharedModule } from 'src/app/shared/shared.module';
import { AdminStoreModule } from 'src/app/store/admin/admin-store.module';

@NgModule({
  declarations: [AlterarSenhaComponent],
  imports: [
    CommonModule,
    AlterarSenhaRoutingModule,
    SweetAlert2Module,
    SharedModule,
    AdminStoreModule
  ]
})

export class AlterarSenhaModule { }