import * as adminState from './admin.state';
import * as actions from './admin.actions';

export function adminReducer(
    state: adminState.AdminState = adminState.initialState,
    action: actions.TypesActions
): adminState.AdminState {

    switch (action.type) {
        case actions.AdminActions.LIST_ADMIN:
            return {
                ...state,
                isLoading: true,
                list: {
                    ...state.list,
                    admins: []
                },
                error: adminState.initialState.error
            }
        case actions.AdminActions.LIST_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    admins: action.payload.admins,
                    total: action.payload.total
                }
            }
        case actions.AdminActions.LIST_ADMIN_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload,
                },
                list: {
                    ...state.list,
                    total: 0
                }
            }
        case actions.AdminActions.LOAD_ADMIN:
            return {
                ...state,
                isLoading: true,
                admin: null
            }
        case actions.AdminActions.LOAD_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                admin: action.payload
            }
        case actions.AdminActions.LOAD_ADMIN_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.AdminActions.SAVE_ADMIN:
            return {
                ...state,
                isLoading: true,
                error: adminState.initialState.error
            }
        case actions.AdminActions.SAVE_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case actions.AdminActions.SAVE_ADMIN_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.AdminActions.UPDATE_ADMIN:
            return {
                ...state,
                isLoading: true,
                error: adminState.initialState.error
            }
        case actions.AdminActions.UPDATE_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case actions.AdminActions.UPDATE_ADMIN_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.AdminActions.DELETE_ADMIN:
            return {
                ...state,
                isLoading: false,
                admin: null
            }
        case actions.AdminActions.LOAD_ALTERAR_SENHA:
            return {
                ...state,
                isLoading: true
            }
        case actions.AdminActions.LOAD_ALTERAR_SENHA_ERROR:
            return {
                ...state,
                error: {
                    show: true,
                    message: action.payload
                },
                isLoading: false
            }
        case actions.AdminActions.LOAD_ALTERAR_SENHA_SUCCESS:
            return {
                ...state,
                isLoading: false
            }

        default:
            return state;
    }

}