import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_NAME } from './tipo-alvo.state';
import { tipoalvoReducer } from './tipo-alvo.reducer';
import { TipoalvoEffects } from './tipo-alvo.effects';
import { TipoalvoStoreService } from './tipo-alvo-store.service';
import { TipoalvoService } from './tipo-alvo.service';

@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(STATE_NAME, tipoalvoReducer),
      EffectsModule.forFeature([TipoalvoEffects]),
  ],
  exports: [
      StoreModule,
      EffectsModule,
      HttpClientModule
  ],
  providers: [
      TipoalvoStoreService,
      TipoalvoService
  ]
})
export class TipoalvoStoreModule { }