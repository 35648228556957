import * as tipoalvoState from './tipo-alvo.state';
import * as actions from './tipo-alvo.actions';

export function tipoalvoReducer(
    state: tipoalvoState.TipoalvoState = tipoalvoState.initialState,
    action: actions.TypesActions
): tipoalvoState.TipoalvoState {

    switch (action.type) {
        case actions.TipoalvoActions.LIST_TIPOALVO:
            return {
                ...state,
                isLoading: true,
                list: {
                    ...state.list,
                    tipoAlvoList: []
                },
                error: tipoalvoState.initialState.error
            }
        case actions.TipoalvoActions.LIST_TIPOALVO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: {
                    ...state.list,
                    tipoAlvoList: action.payload.tipoAlvoList,
                    total: action.payload.total
                }
            }
        case actions.TipoalvoActions.LIST_TIPOALVO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload,
                },
                list: {
                    ...state.list,
                    total: 0
                }
            }
        case actions.TipoalvoActions.LOAD_TIPOALVO:
            return {
                ...state,
                isLoading: true,
                tipoAlvo: null
            }
        case actions.TipoalvoActions.LOAD_TIPOALVO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tipoAlvo: action.payload
            }
        case actions.TipoalvoActions.LOAD_TIPOALVO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.TipoalvoActions.SAVE_TIPOALVO:
            return {
                ...state,
                isLoading: true,
                error: tipoalvoState.initialState.error
            }
        case actions.TipoalvoActions.SAVE_TIPOALVO_SUCCESS:
            return {
                ...state,
                isLoading: false
            }

        case actions.TipoalvoActions.SAVE_TIPOALVO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }
        case actions.TipoalvoActions.UPDATE_TIPOALVO:
            return {
                ...state,
                isLoading: true,
                error: tipoalvoState.initialState.error
            }
        case actions.TipoalvoActions.UPDATE_TIPOALVO_SUCCESS:
            return {
                ...state,
                isLoading: false
            }

        case actions.TipoalvoActions.UPDATE_TIPOALVO_ERROR:
            return {
                ...state,
                isLoading: false,
                error: {
                    show: true,
                    message: action.payload
                }
            }

        case actions.TipoalvoActions.DELETE_TIPOALVO:
            return {
                ...state,
                isLoading: false,
                tipoAlvo: null
            }
        default:
            return state;
    }

}