import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { STATE_NAME } from './auth.state';
import { authReducer } from './auth.reducer';
import { AuthEffects } from './auth.effects';
import { AuthStoreService } from './auth-store.service';
import { AuthService } from './auth.service';

@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(STATE_NAME, authReducer),
      EffectsModule.forFeature([AuthEffects]),
  ],
  exports: [
      StoreModule,
      EffectsModule,
      HttpClientModule
  ],
  providers: [
      AuthStoreService,
      AuthService
  ]
})
export class AuthStoreModule { }