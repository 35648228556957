import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Alvo } from 'src/app/model/alvo';
import { AlvosStoreService } from 'src/app/store/alvos/alvos-store.service';

@Component({
  selector: 'app-alvos',
  templateUrl: './alvos.component.html',
  styleUrls: ['./alvos.component.scss']
})

export class AlvosComponent implements OnInit {

  page: number = 0;
  buscaPor: string = '';
  isLoading$: Observable<boolean>;
  reorderable: boolean = true;
  error$: Observable<any>; 
  rows$: Observable<Alvo[]>;
  maxRows: number = 50;
  total$: Observable<number>;

  constructor(
    private alvoStoreService: AlvosStoreService
  ) {}

  ngOnInit() {
    this.isLoading$ = this.alvoStoreService.getIsLoading();
    this.dispacthLoadAlvosAction();
    this.rows$ = this.alvoStoreService.getAlvos(); 
    this.total$ = this.alvoStoreService.getTotal();
  }

  onChangeMax(event){
    this.maxRows = parseInt(event.target.value);
    this.dispacthLoadAlvosAction();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.page = 0;
    this.buscaPor = val;
    this.dispacthLoadAlvosAction();
  }

  private dispacthLoadAlvosAction(){
    this.alvoStoreService.dispatchListAlvosAction(this.page, this.maxRows, this.buscaPor);
  }

  onClickDelete(alvo) {
    this.alvoStoreService.dispatchDeleteAlvosAction(alvo);
  }

  onChangePage(pageInfo) {
    this.page = pageInfo.offset;
    this.dispacthLoadAlvosAction();
  }
  
}