import { Alvo } from 'src/app/model/alvo';

export const STATE_NAME = 'alvos';

export interface AlvosState {
    isLoading: boolean;
    list: {
        alvos: Alvo[],
        total: number
    };
    alvo: Alvo;
    error: {
        show: boolean,
        message: string
    }
}

export const initialState: AlvosState = {
    isLoading: false,
    list: {
        alvos: [],
        total: 0,
    },
    alvo: null,
    error: {
        show: false,
        message: null
    }
}

export const selectIsLoading = (state: AlvosState) => state.isLoading;
export const selectAlvos = (state: AlvosState) => state.alvo;
export const selectListAlvos = (state: AlvosState) => state.list.alvos;
export const selectError = (state: AlvosState) => state.error;
export const selectTotal = (state: AlvosState) => state.list.total;
