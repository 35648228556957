import { NgRxAction } from '../NgRxAction';

export enum CidadeActions {
    LIST_CIDADE = '[CIDADE] - List CIDADE - ',
    LIST_CIDADE_SUCCESS = '[CIDADE] - List CIDADE Success - ',
    LIST_CIDADE_ERROR = '[CIDADE] - Error List CIDADE - ',
}

export class ListCidadeAction extends NgRxAction<any> {
    readonly type = CidadeActions.LIST_CIDADE;
}

export class ListCidadeSuccessAction extends NgRxAction<any> {
    readonly type = CidadeActions.LIST_CIDADE_SUCCESS;
}

export class ListCidadeErrorAction extends NgRxAction<any> {
    readonly type = CidadeActions.LIST_CIDADE_ERROR;
}


export type TypesActions =
    ListCidadeAction
    | ListCidadeSuccessAction
    | ListCidadeErrorAction