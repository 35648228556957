import { Injectable } from "@angular/core";

import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../app.reducer';
import * as actions from './admin.actions';
import * as state from './admin.state';
import { AdminInterface } from './admin.service';
import { Admin } from 'src/app/model/admin';

@Injectable()
export class AdminStoreService {

  constructor(private store: Store<AppState>) { }

  private adminState = createFeatureSelector<state.AdminState>(state.STATE_NAME);
  private isLoading = createSelector(this.adminState, state.selectIsLoading);
  private admin = createSelector(this.adminState, state.selectAdmin);
  private admins = createSelector(this.adminState, state.selectAdmins);
  private error = createSelector(this.adminState, state.selectError);
  private total = createSelector(this.adminState, state.selectTotal);

  getIsLoading() {
    return this.store.select(this.isLoading);
  }

  getAdmin() {
    return this.store.select(this.admin);
  }

  getAdmins(){
    return this.store.select(this.admins)
  }

  getError(){
    return this.store.select(this.error);
  }

  getTotal() {
    return this.store.select(this.total);
  }

  dispatchLoadAdminAction(id: number){
    this.store.dispatch(new actions.LoadAdminAction(id));
  }

  dispatchListAdminAction(page: number, maxRows: number, buscaPor: string) {
    this.store.dispatch(new actions.ListAdminAction({
      maxRows: maxRows,
      page: page,
      buscaPor: buscaPor
    }));
  }

  dispatchSaveAction(payload: AdminInterface){
    this.store.dispatch(new actions.SaveAdminAction(payload));
  }

  dispatchUpdateAction(payload: AdminInterface){
    this.store.dispatch(new actions.UpdateAdminAction(payload));
  }

  dispatchDeleteAdminAction(admin: Admin){
    this.store.dispatch(new actions.DeleteAdminAction(admin));
  }

  dispatchAlterarSenhaAction(values: {newPassword: string, confirmNewPassword: string, password: string}){
    this.store.dispatch(new actions.LoadAlterarSenhaAction(values));
  }

}