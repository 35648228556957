import { Admin } from '../../model/admin';

export const STATE_NAME = 'admin';

export interface AdminState {
    isLoading: boolean;
    list: {
        admins: Admin[],
        total: number
    };
    admin: Admin;
    error: {
        show: boolean,
        message: string
    }

}

export const initialState: AdminState = {
    isLoading: false,
    list: {
        admins: [],
        total: 0
    },
    admin: null,
    error: {
        show: false,
        message: null
    }
}

export const selectAdmins = (state: AdminState) => state.list.admins;
export const selectTotal = (state: AdminState) => state.list.total;
export const selectIsLoading = (state: AdminState) => state.isLoading;
export const selectAdmin = (state: AdminState) => state.admin;
export const selectError = (state: AdminState) => state.error;